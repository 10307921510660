.teaserlist-link {
  border-top: 5px solid $color-white-smoke-1;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.8rem;
  margin-left: -1rem;
  padding: .7rem 1.2rem .9rem 0;
  text-align: right;
  width: calc(100% + 2rem);

  @include breakpoint(desktop) {
    padding: 1.6rem 1.2rem 1.6rem 0;
  }
}