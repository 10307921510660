$ci-arrow-down: ''; 
 .ci-arrow-down:before { content: $ci-arrow-down };
$ci-arrow-left: ''; 
 .ci-arrow-left:before { content: $ci-arrow-left };
$ci-arrow-right: ''; 
 .ci-arrow-right:before { content: $ci-arrow-right };
$ci-arrow-up: ''; 
 .ci-arrow-up:before { content: $ci-arrow-up };
$ci-bookmark-active: ''; 
 .ci-bookmark-active:before { content: $ci-bookmark-active };
$ci-bookmark-default: ''; 
 .ci-bookmark-default:before { content: $ci-bookmark-default };
$ci-burger: ''; 
 .ci-burger:before { content: $ci-burger };
$ci-check: ''; 
 .ci-check:before { content: $ci-check };
$ci-close: ''; 
 .ci-close:before { content: $ci-close };
$ci-data: ''; 
 .ci-data:before { content: $ci-data };
$ci-datepicker: ''; 
 .ci-datepicker:before { content: $ci-datepicker };
$ci-dropdownclosed: ''; 
 .ci-dropdownclosed:before { content: $ci-dropdownclosed };
$ci-dropdownopened: ''; 
 .ci-dropdownopened:before { content: $ci-dropdownopened };
$ci-edit: ''; 
 .ci-edit:before { content: $ci-edit };
$ci-expand: ''; 
 .ci-expand:before { content: $ci-expand };
$ci-external-link: ''; 
 .ci-external-link:before { content: $ci-external-link };
$ci-eyeoff: ''; 
 .ci-eyeoff:before { content: $ci-eyeoff };
$ci-eyeon: ''; 
 .ci-eyeon:before { content: $ci-eyeon };
$ci-filter: ''; 
 .ci-filter:before { content: $ci-filter };
$ci-filterchecked: ''; 
 .ci-filterchecked:before { content: $ci-filterchecked };
$ci-filterreset: ''; 
 .ci-filterreset:before { content: $ci-filterreset };
$ci-filterunchecked: ''; 
 .ci-filterunchecked:before { content: $ci-filterunchecked };
$ci-gift: ''; 
 .ci-gift:before { content: $ci-gift };
$ci-home: ''; 
 .ci-home:before { content: $ci-home };
$ci-list: ''; 
 .ci-list:before { content: $ci-list };
$ci-logo-google: ''; 
 .ci-logo-google:before { content: $ci-logo-google };
$ci-pause: ''; 
 .ci-pause:before { content: $ci-pause };
$ci-plus: ''; 
 .ci-plus:before { content: $ci-plus };
$ci-quote: ''; 
 .ci-quote:before { content: $ci-quote };
$ci-scrolltotop: ''; 
 .ci-scrolltotop:before { content: $ci-scrolltotop };
$ci-search: ''; 
 .ci-search:before { content: $ci-search };
$ci-social-facebook: ''; 
 .ci-social-facebook:before { content: $ci-social-facebook };
$ci-social-linkedin: ''; 
 .ci-social-linkedin:before { content: $ci-social-linkedin };
$ci-social-twitter: ''; 
 .ci-social-twitter:before { content: $ci-social-twitter };
$ci-social-xing: ''; 
 .ci-social-xing:before { content: $ci-social-xing };
$ci-stock-buy: ''; 
 .ci-stock-buy:before { content: $ci-stock-buy };
$ci-stock-neutral: ''; 
 .ci-stock-neutral:before { content: $ci-stock-neutral };
$ci-stock-sell: ''; 
 .ci-stock-sell:before { content: $ci-stock-sell };
$ci-subscribeplus: ''; 
 .ci-subscribeplus:before { content: $ci-subscribeplus };
$ci-super: ''; 
 .ci-super:before { content: $ci-super };
$ci-toolbar-bookmark: ''; 
 .ci-toolbar-bookmark:before { content: $ci-toolbar-bookmark };
$ci-toolbar-buy: ''; 
 .ci-toolbar-buy:before { content: $ci-toolbar-buy };
$ci-toolbar-pdf: ''; 
 .ci-toolbar-pdf:before { content: $ci-toolbar-pdf };
$ci-toolbar-print: ''; 
 .ci-toolbar-print:before { content: $ci-toolbar-print };
$ci-toolbar-push: ''; 
 .ci-toolbar-push:before { content: $ci-toolbar-push };
$ci-toolbar-rss: ''; 
 .ci-toolbar-rss:before { content: $ci-toolbar-rss };
$ci-toolbar-send: ''; 
 .ci-toolbar-send:before { content: $ci-toolbar-send };
$ci-toolbar-share: ''; 
 .ci-toolbar-share:before { content: $ci-toolbar-share };
$ci-trash: ''; 
 .ci-trash:before { content: $ci-trash };
$ci-user-logged-in: ''; 
 .ci-user-logged-in:before { content: $ci-user-logged-in };
$ci-user: ''; 
 .ci-user:before { content: $ci-user };