.main-navigation {
  background-color: $color-white;
  height: 93vh;
  left: -100%;
  position: fixed;
  transition: all $mainNavTiming ease-in-out;
  width: 100%;

  @include breakpoint(maxTablet) {
    top: 5.6rem;
  }

  @include breakpoint(desktop) {
    height: 100vh;
    left: -32rem;
    width: 32rem;
  }

  &__wrapper {
    color: $color-nero;
    height: 100vh;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    top: -4.5rem;
    visibility: hidden;
    width: 100%;
    z-index: 1100;

    @include breakpoint(desktop) {
      top: -14.5rem;
    }

    .close-nav {
      opacity: 0;
      transition: opacity $mainNavTiming;
    }
  }

  & > .search,
  & > .main-nav {
    border-bottom: .5px solid $color-silver;
    border-width: thin;
    padding-bottom: 5rem;

    @for $i from 0 through $mainNavLiCount {
      & > ul > li:nth-child(#{$i}) {
        transform: translateX(calc(#{$mainNavLiOffset} * #{$i}));
      }
    }

    .childs:hover {
      &>.main-nav-link>.nav-item-childs, &>.toggle {
        background-color: $color-pacific-blue-20;
      }
    }
  }

  & > .search {
    padding: 2.5rem 1.5rem;
    position: fixed;
    z-index: 5;
    background-color: $color-white;
    width: 100%;

    @include breakpoint(desktop) {
      width: 32rem;
    }

    .search {
      width: 100%;
    }
  }

  & > .main-nav {
    margin-top: 9.4rem;
    min-height: 75%;

    @include breakpoint(desktop) {
      min-height: 80%;
    }
  }

  nav {
    padding: 2.5rem 1.5rem 5rem 1.5rem;
  }

  .fixed-links {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    padding: 3.9rem 0 4rem 1.5rem;

    @supports (-webkit-touch-callout: none) {
      margin-bottom: 8rem;
    }

    a {
      text-decoration: none;
      color: inherit;
    }
  }
}

nav {
  font-family: $typeface-font;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.8rem;

  .main-nav-link {
    color: inherit;
    cursor: pointer;
    text-decoration: none;

    &.not-clickable {
      & span {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &~span {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: 0;
        padding-left: 2rem;
      }
    }

    &.active,
    &:active {
      .nav-item,
      &.not-clickable~.toggle {
        background-color: $color-pacific-blue-20;
      }
    }

    &:hover~.toggle {
      background-color: $color-pacific-blue-20;
    }
  }

  ul,
  li {
    list-style-type: none;
    margin-block-start: 0;
    padding: 0;

    li.no-relative {
      .navigation__link--sub {
        span {
          display: inline-block;
        }
      }
    }
  }

  & > ul > li:not(.no-relative) {
    padding-left: 0;
    transition: all $mainNavTiming linear;
  }

  li > ul {
    & > li {
      //max-height: 80vh;
      opacity: 1;
      transition: max-height $mainNavTiming linear;

      @include breakpoint(maxTablet) {
        max-height: unset;
      }
    }

    &:not(.active) {
      & > li {
        max-height: 0;
        opacity: 0;
        pointer-events: none;
        transition: none;
      }
    }
  }

  .nav-item {
    display: inline-block;
    border-radius: 4px;
    margin-bottom: .5rem;
    padding: 1.3rem 1.4rem 1.3rem 1.4rem;
    width: 100%;
  }

  .nav-item-childs {
    @extend .nav-item;
    width: calc(100% - 4.8rem);
  }

  .toggle {
    padding: 1.3rem 1.4rem 1.3rem 1.5rem;
    border-radius: .4rem;
    margin-left: .5rem;
    cursor: pointer;

    svg {
      stroke-width: 1.5px;
    }
  }

  .toggle-down svg {
    transition: transform $mainNavChildTiming linear;
  }

  .nav-item,
  .toggle {
    -webkit-transition: background-color 0.1s ease-out;
    -moz-transition: background-color 0.1s ease-out;
    -ms-transition: background-color 0.1s ease-out;
    -o-transition: background-color 0.1s ease-out;

    &:hover {
      background-color: $color-pacific-blue-20;
    }
  }

  .toggle-down svg {
    transform: rotate(90deg);
  }

  .toggle-up svg {
    transform: rotate(270deg);
  }
}

.main-navigation--local {
  .nav-item-childs {
    @extend .nav-item;
    width: calc(100% - 5.2rem) !important;
  }
}

.nav-main--item__selected {
  color: #08c !important;

  &:before {
    content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4LjQ0IiBoZWlnaHQ9IjEyLjUiIHZpZXdCb3g9IjAgMCA4LjQ0IDEyLjUiPgogIDxwYXRoIGlkPSJEaWZmZXJlbnptZW5nZV8yIiBkYXRhLW5hbWU9IkRpZmZlcmVuem1lbmdlIDIiIGQ9Ik00LjIyLDEyLjVoMGMtLjA0My0uMDYtMS4wNzUtMS41LTIuMTEtMy4yMThDMS4xNDcsNy42NzgsMCw1LjUxLDAsNC4yODdBNC4yOTQsNC4yOTQsMCwwLDEsMS4yMzUsMS4yNTUsNC4xNiw0LjE2LDAsMCwxLDQuMjIsMCw0LjI1OSw0LjI1OSwwLDAsMSw4LjQ0LDQuMjg3YzAsMS4yMjItMS4xNDcsMy4zOS0yLjExLDQuOTkzQzUuMywxMSw0LjI2NCwxMi40MzksNC4yMiwxMi41Wm0wLTEwLjQ1OWEyLjE4OSwyLjE4OSwwLDEsMCwyLjIsMi4xODlBMi4yLDIuMiwwLDAsMCw0LjIyLDIuMDQxWiIgZmlsbD0iIzAwODhjYiIvPgo8L3N2Zz4K);
    padding-right: .3rem;
    position: relative;
    top: 1px;
  }
}