.gradient {

  &-light-blue-1 {
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(229,243,250,0.48) 35%, rgba(229,243,250,1) 73%);
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(229,243,250,0.48) 35%,rgba(229,243,250,1) 73%);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(229,243,250,0.48) 35%,rgba(229,243,250,1) 73%);
  }

  &-white-transparent-r_l {
    background: -moz-linear-gradient(left,  rgba(229,229,229,0) 1%, rgba(229,229,229,0.29) 30%, rgba(255,252,252,0.7) 70%, rgba(255,252,252,1) 100%);
    background: -webkit-linear-gradient(left,  rgba(229,229,229,0) 1%,rgba(229,229,229,0.29) 30%,rgba(255,252,252,0.7) 70%,rgba(255,252,252,1) 100%);
    background: linear-gradient(to right,  rgba(229,229,229,0) 1%,rgba(229,229,229,0.29) 30%,rgba(255,252,252,0.7) 70%,rgba(255,252,252,1) 100%);
  }
}