.interview {
  @include breakpoint(tablet) {
    font-size: 2rem;
    line-height: 2.8rem;
  }

  //& > p.interview__question {
  //  margin-top: 3rem !important;
  //  margin-bottom: 0 !important;
  //}
  //
  //& > p {
  //  margin: 0 !important;
  //}

  .related-contend, .content-element {
    margin: 2.5rem 0;

    @include breakpoint(tablet) {
        margin: 3rem 0;
    }
  }

  .lightbox:not(.gallery) {
    margin: 2.5rem 0;

    @include breakpoint(tablet) {
      margin: 3rem 0;
    }
  }
}