.container {
  .content {
    &:first-child {
      padding-top: 0;
    }

    @include breakpoint(tablet) {
      padding: 0 $content--group-padding;
    }

    &--group {
      background-color: $color-white;
      //content-visibility: auto;
      margin-bottom: $content--group-margin-bottom;
      padding: 0 $content--group-padding;

      .portal & {
        padding: 0 $content--group-padding;
      }

      /*
      [class^=grid-cell]:not(.no-margin) {
        margin-bottom: 3rem; auskommentiert von marco
      }
      */

      &__article {
        background-color: transparent;
        padding: 0;
        margin-bottom: .5rem;

        .content--group__section {
          padding: 2.5rem 1.5rem 0;

          > * {
            margin-bottom: 2.5rem;

            @include breakpoint(tablet) {
              margin-bottom: 3rem;
            }

            &:last-child {
              margin-bottom: 0;
            }

            &:first-child > .meta-box {
              margin-top: 1.5rem;
              // margin-bottom: 2.5rem;
            }
          }

          @include breakpoint(tablet) {
            padding: 3.5rem 6rem 0;
          }

          @include breakpoint(desktop) {
            padding: 3.5rem 10.5rem 0;
          }
        }

        .paywalled-content section.content--group__section:not(:only-child):first-of-type {
          margin-bottom: -.5rem;
          padding-bottom: 0;
        }

        h2:not(.box-name),
        h3:not(.box-name),
        h4:not(.box-name),
        h5,
        h6,
        p,
        ul,
        ol,
        .content--group > a,
        .content--group > .related-article,
        .content--group > .slider-box {
          @extend .content-element;
          display: block;
        }
      }

      &__article.content--group__advertorial {

        .content--group__section {
          @include breakpoint(desktop) {
            padding: 1.5rem 10.5rem 0;
          }

          > * {
            &:first-child > .picture__wrapper + .meta-box {
              margin-top: -1rem;

              @include breakpoint(tablet) {
                margin-top: 0;
              }
            }

            &:first-child > .meta-box {
              margin: 0 -1.5rem 0;
              width: calc(100% + 3rem);
    
              @include breakpoint(tablet) {
                margin: 0 0 1.5rem 0;
                min-width: 100%;
                padding: 1.5rem calc((100% - 72.4rem) / 2);
                width: 100%;
              }
            }
          }
        }
      }

      & > .grid:first-child {
        padding-top: $content--group-padding;
      }

      & > .grid:first-child > .grid-cell--d-12 > .grid--column > .article__teaser--opener {
        margin-top: 0;
      }

      // mobile
      & > .grid--gutter-d:not(:first-of-type) > .grid-cell--m-12:nth-of-type(-n+1) .article__teaser {
        padding-top: 1rem;
      }

      & > .grid--gutter-d ~ .grid--gutter-d > .grid-cell--m-12:nth-of-type(-n+1) .article__teaser {
        padding-top: inherit;
      }

      // tablet
      @include breakpoint(tablet) {
        & > .grid--gutter-d:not(:first-of-type) > .grid-cell--t-6:nth-of-type(-n+2) .article__teaser {
          padding-top: 1rem;
        }

        & > .grid--gutter-d ~ .grid--gutter-d > .grid-cell--t-6:nth-of-type(-n+2) .article__teaser {
          padding-top: inherit;
        }
      }

      // desktop
      @include breakpoint(desktop) {
        & > .grid--gutter-d:not(:first-of-type) > .grid-cell--d-4:nth-of-type(-n+3) .article__teaser {
          padding-top: 1rem;
        }

        & > .grid--gutter-d ~ .grid--gutter-d > .grid-cell--d-4:nth-of-type(-n+3) .article__teaser {
          padding-top: inherit;
        }

        & > .grid--gutter-d ~ .grid--gutter-d > .grid-cell--d-4:nth-of-type(-n+3) .article__teaser {
          margin-top: 0rem;
        }
      }

      &--spaceless-bottom {
        margin-bottom: 0;
      }

      &--fix {
        margin-left: -$content--group-padding;
        margin-right: -$content--group-padding;
      }

      // Start color overwritings
      &.bg-color {
        background: $color-prussian-blue;
        color: $color-white;

        .section-heading {
          justify-content: space-around;
          padding-bottom: 3rem;
          text-align: center;

          &--has-links {
            justify-content: unset;
            text-align: unset;
            padding-bottom: 0;
            border-bottom: .5rem solid $color-white-smoke-2;

            .section-heading__header {
              margin-top: 0;

              &:after {
                display: none;
              }
            }

            .section-heading__links {
              //padding-top: 1.2rem;
              margin-left: auto;
            }
          }

          &__header {
            padding-top: 2rem;

            &:after {
              background-color: $color-white;
              content: '';
              display: block;
              height: .3rem;
              margin: 0 calc(50% - 3.2rem) 0;
              position: relative;
              top: 1rem;
              width: 6.4rem;
            }
          }

          &__links {
            &-blur {
              &:before {
                background: transparent linear-gradient(90deg, $color-transparent -50%, $color-prussian-blue 100%) 0 0 no-repeat padding-box;
              }
            }
          }
        }

        .divider--content-group {
          display: none;
        }

        .teaserlist-link {
          //padding-right: 0;

          .link {
            @include breakpoint(maxTablet) {
              position: relative;
              right: -.5rem;
            }
          }
        }

        .bg-color__white {
          background-color: $color-prussian-blue;
        }

        .text-color__comet {
          color: $color-white;
        }

        .article__teaser__subline {
          color: $color-pacific-blue-60;
        }

        .article__teaser--advertorial {
          background-color: unset;

          :before {
            color: $color-white;
          }
        }

      }

      .teaserlist-link .icon--arrow {
        margin-right: 0;
      }

      // End color overwritings
    }

    &--stage {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      margin-top: -2.5rem;
      @include breakpoint(tablet) {
        margin-top: 0;
      }

      .picture__wrapper {
        margin-bottom: 1rem;
        margin-top: 2.5rem;

        @include breakpoint(tablet) {
          margin-bottom: 0;
          margin-top: unset;
        }
      }

      ._3q-video {
        margin-top: 2.5rem;

        @include breakpoint(tablet) {
          margin-top: unset;
        }
      }

      .meta-box {
        margin-top: 1.5rem;

        @include breakpoint(tablet) {
          margin-bottom: 3rem;
        }
      }
    }

    &-element {
      margin-left: auto;
      margin-right: auto;
      max-width: $content-width;
      width: 100%;

      &.size--w-max--100 {
        max-width: 100%;
      }
    }
  }
}

body.video .content--group__article section:first-of-type {
  padding-bottom: 0;
}

body.audio {
  .container {
    .content {
      &--stage {
        // desktop
        @include breakpoint(desktop) {
          & > .content-element:first-child {
            max-width: unset;
            order: 2;
          }
        }
      }
    }
  }
}

article a {
  word-wrap: break-word;
}

.newsapp {
  .container {
    .content {
      &--group {
        &__article {
          .content--group__section > :first-child > .meta-box {
            margin-top: 2.5rem
          }
        }
        &__article.content--group__advertorial {
          .content--group__section > :first-child > .meta-box {
            margin-top: unset;
          }
        }
        egy-discussion::part(egy-discussion__root) {
          border-top: thin dotted #BEBEBE;
        }
      }
      &--stage {
        .picture__wrapper {
          order: 1;
          margin-bottom: 0;
          margin-top: unset;
        }

        ._3q-video {
          margin-top: unset;
        }

        .meta-box {
          order: 2;
        }

        & > .content-element:not(.meta-box):first-child {
          max-width: unset;
          order: 1;
        }
      }
    }
  }
}