.podcast-element {
  padding: .5rem 0 3rem 0;

  @include breakpoint(tablet) {
    padding: 2rem 0 10.4rem 0;
  }

  &__title h2 {
    font-size: 2.1rem;
    line-height: 2.5rem;
    margin: 1rem 0;

    @include breakpoint(tablet) {
      font-size: 2.6rem;
      line-height: 3rem;
      margin: 1.5rem 0;
    }
  }

  &__intro {
    line-height: 2.2rem;

    @include breakpoint(tablet) {
      font-size: 1.8rem;
      line-height: 2.4rem;
      min-height: 16.9rem;
    }
  }

  &__link {
    padding: 2.5rem 0 3rem 0;
    text-align: right;

    @include breakpoint(tablet) {
      padding: 2.5rem 0 2rem 0;
      text-align: left;
    }
  }

  &__episodes-link {
    text-align: right;
  }

  div[data-role="sdn-audio-player"] {
    min-height: 33.1rem;

    .scroller {
      position: relative;
      left: 0;
      transition: left 3s ease-in-out;
    }
  }
}

@include breakpoint(desktop) {
  .js3q-audio-wrapper {
    .shrink {
      &[data-role="audio-description"] {
        max-height: 95px;
        overflow: hidden;
        display: block;
        position: relative;
        transition: max-height 4s ease-in-out;
      }

      &[data-role="audio-description"]:after {
        content: '';
        position: absolute;
        top: 50%;
        height: 50%;
        width: 100%;
        left: 0;
        background: linear-gradient(to bottom, #fff0 0%, #e9f5fba6 83%, #e5f3faa6 100%);
      }

      &[data-role="audio-description"]:hover {
        max-height: 10000px;
      }

      &[data-role="audio-description"]:hover:after {
        display: none;
      }
    }
  }
}