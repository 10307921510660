.carousel-touch {
  position: relative;
  overflow: hidden;

  &--scroll {
    padding-right: 70px;
    overflow: scroll;
    -webkit-scroll-snap-type: x mandatory;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    white-space: nowrap;

    // remove scrollbar
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include breakpoint(tablet) {
      padding-right: 0;
      overflow: initial;
      white-space: initial;
    }

    .continue-links {
      line-height: 2.8rem;
    }

    &:after {
      position: absolute;
      content: "";
      right: 0;
      top: 0;
      width: 30px;
      @include breakpoint(tablet) {
        width: 70px;
      }
      height: 100%;
      background: transparent -webkit-linear-gradient(left, $color-transparent 0%, $color-white 100%) 0 0 no-repeat padding-box;
      background: transparent linear-gradient(90deg, $color-transparent 0%, $color-white 100%) 0 0 no-repeat padding-box;

      @include breakpoint(tablet) {
        display: none;
      }
    }

    &--wrapper {
      position: relative;
    }
  }
}