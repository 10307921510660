.switch-box {
  flex-direction: column;
  font-size: 1.5rem;
  display: flex;
  margin-bottom: -2rem;
  padding-top: .5rem;
  padding-bottom: .3rem;
  text-align: center;

  &__head {
    line-height: 2.4rem;
  }

  &__switch {
    position: relative;
    line-height: 1.8rem;
    margin-top: 0.5rem;
    width: 100%;

    &__os {
      padding-bottom: .3rem;
    }

    &__line {
      height: .85rem;
      display: inline-block;
      margin: 0 .9rem .3rem .9rem;
      width: 2rem;
    }

    &__os:hover,
    &__el:hover,
    &__os.active,
    &__el.active {
      padding-bottom: .1rem;
      border-bottom: .3rem solid $color-deep-sky-blue;
    }

    .letter-spacing-switch {
      letter-spacing: .013rem;
    }
  }

  .border-decoration {
    border-bottom: .3rem solid #00afea;
    position: absolute;
    transition: all .5s ease-in-out;
  }

  &__link {
    letter-spacing: .013rem;
    text-transform: uppercase;
  }
}

main.container.content {
  .main-content__overlay {
    background-color: $color-white;
    height: 100vh;
    left: 0;
    opacity: .5;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1600;
  }
}