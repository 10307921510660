@mixin tabBox($boxName, $size) {

  .tabbed-box {

    nav {
      display: flex;

      label {
        color: $color-nobel;
      }
    }

    &__content {
      display: block;
      margin-left: 0;
      clear: both;
    }

    & > input,
    &__content > div {
      display: none;
    }

    &__content>div {
      width: 100%;
    }

    @for $i from 1 through $size {
      // In order to address the labels:
      //#tab-#{$boxName}-#{$i}:checked ~ nav .label-#{$boxName}-#{$i} {
      //  color: $color-nero;
      //  font-weight: 600;
      //}

      #tab-#{$boxName}-#{$i}:checked ~ nav .label-#{$boxName}-#{$i} {
        color: $color-nero;
      }

      #tab-#{$boxName}-#{$i}:checked ~ &__content .tab-#{$boxName}-#{$i} {
        display: block;
      }
    }
  }
}