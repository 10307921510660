.misc {
  margin-bottom: .5rem;
  border-bottom: .3rem solid $color-white-smoke-2;

  .news & {
    margin-bottom: 0;

    @include breakpoint(tablet) {
      margin-bottom: .5rem;
    }
  }

  @include breakpoint(tablet) {
    background-color: $color-white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column;

    &:not(.row)>*:nth-child(0n+2) {
      border-top: .5rem solid $color-white-smoke-2;
    }

    &.row {
      flex-flow: row;
    }
  }

  .breadcrumb {
    background-color: $color-white-smoke-1;

    @include breakpoint(tablet) {
      order: 0;
      background-color: $color-white;
    }

  }

  .advertorial-notice,
  .trademark-notice {
    @include breakpoint(tablet) {
      order: 1;
      margin-left: auto;
    }
  }

   .video & {
    margin-bottom: .5rem;
  }
}