.media {
  display: grid;
  grid-column-gap: 1rem;
  align-items: start;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "kicker kicker"
    "text image"
    "text image";

  @include breakpoint(tablet) {
    grid-template-areas:
      "kicker image"
      "text image"
      "text image";
  }

  &--kicker {
    grid-area: kicker;
  }

  &--image {
    grid-area: image;
  }

  &--text {
    grid-area: text;
  }

  &-image-text {
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;

    &--body {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
  }
}