.size {
  // 0.x rem
  &--s {
    &-8 {
      font-size: .8rem;
      line-height: 1rem;
    }
  }

  // 1.x rem
  &--m {

    &-0 {
      font-size: 1rem;
      line-height: 1.4rem;
    }

    &-2 {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }

    &-3 {
      font-size: 1.3rem;
      line-height: 2rem;
    }

    &-4 {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }

    &-5 {
      font-size: 1.5rem;
      line-height: 1.8rem;
    }

    &-6 {
      font-size: 1.6rem;
      line-height: 2rem;

      &__22 {
        line-height: 2.2rem;
      }
    }

    &-8 {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }

    &-9 {
      font-size: 1.9rem;
      line-height: 2.3rem;
    }
  }

  // 2.x rem
  &--l {
    &-0 {
      font-size: 2rem;
      line-height: 2.2rem;
    }

    &-1 {
      font-size: 2.1rem;
      line-height: 2.5rem;
    }

    &-2 {
      font-size: 2.2rem;
      line-height: 2.6rem;
    }

    &-4 {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }

    &-6 {
      font-size: 2.6rem;
      line-height: 3.2rem;
    }

    &-7 {
      font-size: 2.7rem;
      line-height: 3.3rem;
    }

    &-8 {
      font-size: 2.8rem;
      line-height: 3.2rem;
    }
  }

  // 3.x rem
  &--xl {
    &-2 {
      font-size: 3.2rem;
      line-height: 3.8rem;
    }

    &-3 {
      font-size: 3.3rem;
      line-height: 3.5rem;
    }

    &-6 {
      font-size: 3.6rem;
      line-height: 4.8rem;
    }
  }

  // 4.x rem
  &--xxl {
    &-2 {
      font-size: 4.2rem;
      line-height: 4.8rem;
    }

    &-8 {
      font-size: 4.8rem;
      line-height: 5.4rem;
    }
  }

  // 5.x rem
  &--xxxl {
    font-size: 5rem;
    line-height: 5rem;
  }

  &__blind {
    font-size: 1.6rem;
    line-height: 2.2rem;

    @include breakpoint(tablet) {
      font-size: 2rem;
      line-height: 2.8rem;
    }
  }

  &__section-headline {
    font-size: 3.4rem;
  }

  &__kicker {
    font-size: 1.5rem;
    line-height: 1.8rem;

    @include breakpoint(desktop) {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }

    &--small {
      @include breakpoint(desktop) {
        font-size: 1.5rem;
        line-height: 1.8rem;
      }
    }

  }

  &--small {
    font-size: 1.4rem;
    line-height: 1.8rem
  }

  &__headline {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}

.text {

  &--reset {
    color: inherit;
    text-decoration: none;

    &:active,
    &:visited,
    &:hover,
    &:focus {
      color: inherit;
    }
  }

  &--center {
    text-align: center;
  }

  &--l {
    text-align: left;
  }

  &--r {
    text-align: right;
  }

  &-align {

    &--middle {
      display: flex;
      align-items: center;
    }
  }
}