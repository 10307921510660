.article--type {
  &-graphic {
    .article__teaser__headline {
      padding: 0 1rem .5rem 0;
    }

    .icon--arrow {
      margin: .3rem 0 .3rem 1rem;
    }

    &.article__teaser--no-image {
      .article__teaser__wrapper {
        width: 100%;
      }
    }

    @include breakpoint(tablet) {
      .grid-cell--d-6 & {
        .article__teaser__info {
          padding: 1rem 0 0 0;
        }

        .article__teaser__separator {
          padding: 2rem 0 0 0;
        }

        &:not(.article__teaser--no-image):not(.article__teaser--small) {
          .article__teaser__headline {
            line-height: 3rem;
          }
        }
      }

      .grid-cell--d-6 &,
      .grid-cell--t-12 &,
      .grid-cell--d-8 & {
        .article__teaser__info {
          font-size: 1.4rem !important;
          line-height: 1.8rem !important;
          padding: .6rem 0 .4rem 0;
        }

        .article__teaser__separator {
          grid-row-start: 4;
          grid-row-end: 4;
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }
    }
  }
}