.error-content {
  font-family: $typeface-font;

  &__headline {
    border-bottom: .5rem solid $color-white-smoke-1;
    font-size: 1.9rem;
    line-height: 5.5rem;
    margin-bottom: 1.6rem;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    @include breakpoint(desktop) {
      font-size: 3.4rem;
      line-height: 7rem;
    }
  }

  &__text {
    max-width: 62.5rem;
  }

  .picture__wrapper img{
    width: 28.8rem;

    @include breakpoint(tablet) {
      width: 59.6rem;
    }
  }

  &__img {
    margin: 5.5rem 0 4.5rem 0;
  }



  .input {
    margin: 1.5rem 0 3.5rem 0;

    @include breakpoint(desktop) {
      width: 29rem;
    }

    .search {
      width: 100%;
    }
  }

  &__search-text,
  &__links-text {
    font-size: 1.6rem;

    @include breakpoint(tablet) {
      font-size: 1.8rem;
    }
  }

  &__links-text {
    font-size: 1.6rem;
    margin-bottom: 2rem;

    @include breakpoint(tablet) {
      font-size: 1.8rem;
    }

    @include breakpoint(desktop) {
      margin-bottom: 1rem;
    }
  }

  .service-box {
    div:first-of-type {
      a:first-of-type {
        border-top: #BEBEBE thin dotted;
        padding-top: 1.2rem;
      }

      @include breakpoint(desktop) {
        display: flex;
        flex-flow: column;
        flex-wrap: wrap;
        height: 240px;

        a {
          margin-right: 2rem;

          &:first-of-type,
          &:nth-of-type(5) {
            border-top: #BEBEBE thin dotted;
            padding-top: 1.2rem;
          }
        }
      }
    }
  }
}