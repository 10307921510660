.continue-links-box {
  background-color: $color-white;

  &__headline {
    border-bottom: .5rem solid $color-white-smoke-1;
    font-size: 1.9rem;
    line-height: 2.3rem;
    min-height: 5.5rem;
    padding: 1.6rem 1rem;

    @include breakpoint(tablet) {
      padding: 1.4rem 1rem;

      h1,
      h2 {
        font-size: 2.8rem;
        line-height: 3.2rem;

        @include breakpoint(desktop) {
          font-size: 3.4rem;
          line-height: 4.2rem;
        }
      }
    }
  } 

  .carousel-touch--scroll {
    padding-left: 1rem;

    @include breakpoint(tablet) {
      padding-left: 1rem;
    }

    &--wrapper {
      padding-top: 1rem;
    }

    .continue-links {
      color: $color-nero;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}