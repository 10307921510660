.cmp-consent {
  font-size: 1.8rem;
  line-height: 2.4rem;
  padding: 2rem;

  &__switch--wrapper {
    cursor: pointer;
    margin: 2rem 0;

    @include breakpoint(tablet) {
      margin: 1rem 0;
    }
  }

  &__switch {
    display: inline-block;
    height: 20px;
    margin-right: 1rem;
    position: relative;
    width: 40px;

    input {
      height: 0;
      opacity: 0;
      width: 0;
    }

    .slider.round {
      border-radius: 34px;
    }

    .slider {
      background-color: $color-white;
      border: solid thin $color-prussian-blue-10;
      bottom: 0;
      cursor: pointer;
      left: 0;
      position: absolute;
      right: 0;
      top: -.3rem;
      transition: .4s;
    }

    .slider.round:before {
      border-radius: 50%;
    }

    .slider:before {
      content: "";
      background-color: $color-pacific-blue;
      bottom: 1px;
      height: 18px;
      left: 4px;
      position: absolute;
      transition: .4s;
      width: 18px;
    }

    input:checked+.slider:before {
      transform: translateX(1.6rem);
    }
  }

  &__legal-text {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
}