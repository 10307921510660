button,
.button {
  color: $color-white;
}

.button {

  &--rounded {
    display: inline-block;
    border: unset;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 600;
    text-decoration: none;

    &:not(.ignore-hover):not([disabled]):hover {
      background-color: $color-endeavour;
      color: #fff;
    }

    &[disabled="disabled"],
    &[disabled] {
      background-color: $color-link-water;
    }
  }

  &--macro {
    padding: .3rem 1.15rem .4rem;
  }

  &--nano {
    padding: .8rem 2rem .8rem;
  }

  &--small {
    padding: .4rem 1.1rem .6rem 1.1rem;
  }

  &--normal {
    padding: 1.3rem 1rem;
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  &--big {
    padding: 1rem 4.6rem 1.2rem;
  }

  &--wide {
    padding: 1.3rem 2rem;
    width: 100%;

    @include breakpoint(desktop) {
      width: auto;
    }
  }

  &--full-width {
    padding: 1.1rem;
    width: 100%;
  }

  .loader--ellipsis-item {
    background-color: $color-white;
  }

  &--secondary {
    border: thin solid $color-pacific-blue;
    background-color: transparent;

    &:hover {
      border: thin solid $color-endeavour;
      background-color: transparent;
      color: $color-endeavour;
    }

    &[disabled="disabled"] {
      background-color: inherit;
      border: thin solid $color-link-water;
      color: $color-link-water;
    }

    .loader--ellipsis-item {
      background-color: $color-pacific-blue;
    }

    &__light {
      border: thin solid $color-pacific-blue-40;
    }
  }

  &--grey {
    border: thin solid $color-nobel;
    background-color: transparent;

    &:hover {
      border: thin solid $color-endeavour;
      background-color: transparent;
      color: $color-endeavour;
    }

    &[disabled="disabled"] {
      background-color: inherit;
      border: thin solid $color-link-water;
      color: $color-link-water;
    }
  }

  &--arrow-right {
    font-size: 1.2rem;
    padding-right: 0;
  }

  &--disabled,
  &[disabled] {
    cursor: not-allowed;
  }

  &--inline {
    width: auto;
  }
}

.button-hover-color {
  color: $color-endeavour;
}