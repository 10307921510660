// stylelint-disable declaration-no-important
$positions: static, relative, absolute, fixed, sticky !default;

$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;


// Common values
@each $position in $positions {
  .position-#{$position} { position: $position; }
}

// Shorthand

.fixed {
  &--top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-fixed;
  }

  &--bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-fixed;
  }
}

.sticky-top {
  @supports (position: sticky) {
    position: sticky !important;
    top: 0;
    z-index: $zindex-sticky;
  }
}

.right {
  &-0 {
    right: 0;
  }
}

.bottom {
  &-0 {
    bottom: 0;
  }
}

.top {
  &-49 {
    top: 4.9rem;
  }
}