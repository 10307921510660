.divider {

  &--small {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      bottom: -15px;
      left: 50%;
      margin-left: -35px;
      width: 70px;
      height: 1px;
      background-color: $color-nero;
    }
  }
}