.article--type {
    &-advertorial {
    position: relative;

    @include breakpoint(tablet) {
      height: 100%;
    }

    &.article__teaser--opener {
      &:before {
        top: -1.7rem;
      }
    }

    &.article__teaser--no-image {
      &:before {
        order: 2;
      }

      .article__teaser__image {
        background-color: $color-white;
      }
    }

    .article__teaser__info {
      display: none;
    }

    .article__teaser__subline {
      color: $color-nero;
      line-height: 1.8rem;
      margin-bottom: 0;
      padding: 0;

      @include breakpoint(tablet) {
        margin-left: 0;
      }

      &:before {
        content: 'ANZEIGE';
        color: $color-white;
        background-color: $color-sonic-silver;
        font-family: $typeface-font;
        font-size: .9rem;
        font-weight: 600;
        letter-spacing: 0.022rem;
        line-height: 1.4rem;
        margin-right: .5rem;
        padding: .1rem .5rem .1rem;
        border-radius: .3rem;
        display: inline-block;
        transform: translateY(-2px);

        @include breakpoint(tablet) {
          font-size: 1.1rem;
        }
      }
    }

    &.article__teaser--small {
      &:before {
        order: 1;
      }

      .article__teaser__subline {
        order: 2;
      }

      .article__teaser__headline {
        order: 3;
        position: relative;
      }

      .article__teaser__wrapper {
        margin-top: .5rem;
        order: 4;
        padding-right: .4rem;
      }

      &.article__teaser--no-image {
        .article__teaser__wrapper {
          width: calc(100% - 1rem);
        }
      }

      .article__teaser__image {
        height: auto;
        margin: -.3rem 0 1rem 0;
        order: 4;
        width: 9rem;
        @include breakpoint(maxTablet) {
          margin-top: .5rem;
        }
      }

      @include breakpoint(tablet) {
        .article__teaser__image {
          background-color: $color-white;
          display: block;
          height: 100%;
          order: 4;
          width: 100%;

          @include breakpoint(tablet) {
            height: unset;
            width: 12rem;
          }
        }

        .article__teaser__subline {
          order: 2;
        }
      }
    }

    &.article__teaser--no-image {
      .article__teaser__subline {
        padding-top: .5rem;
      }

      .article__teaser__headline {
        line-height: 2rem;
        padding-top: .5rem;

        @include breakpoint(tablet) {
          padding-left: 0;
        }
      }
    }

    &.article__teaser--no-image,
    &.article__teaser--small {
      .article__teaser__headline,
      .article__teaser__info,
      .article__teaser__separator,
      .article__teaser__wrapper {
        //width: 100%;
      }

      @include breakpoint(tablet) {
        .article__teaser__wrapper {
          top: -.5rem;
          position: relative;
          //width: calc(100% - 13rem);
        }
      }
    }
  }
}