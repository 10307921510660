.free-content {
  svg {
    margin-right: 1rem;
    vertical-align: bottom;
  }
}

.article__teaser--small,
.article__teaser--no-image {
  .free-content {
    svg {
      margin-right: .5rem;
      position: relative;
      top: .2rem;
      width: 1rem;
    }
  }
}

.article__teaser--small.article__teaser--column {
  .free-content {
    svg {
      position: relative;
      top: .2rem;
    }
  }
}

.article__teaser--no-image.article__teaser--column {
  .free-content {
    svg {
      margin: 0 .5rem 0 0;
      position: relative;
      top: .2rem;
    }
  }
}

.article--type-column {
  @include breakpoint(desktop) {
    .grid-cell--d-6 &,
    .grid-cell--d-8 & {
      .article__teaser__info--col-2-row--show {
        width: calc(100% - 7.4rem);
      }
    }
  }
}