.ad-sticky-footer {
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
  box-shadow: 0 -1px 0 rgb(190, 190, 190);
  min-height: 55px;
  max-height: 105px;
  background-color: rgb(242, 242, 242);
  z-index: 10000;
  bottom: 0px;
  position: fixed;
  padding: 2.5px 0px;
  width: 100%;

  @media print {
    display: none;
  }

  &__label {
    color: #69696c;
    font-family: Source Sans Pro;
    font-size: 1rem;
    left: 5px;
    line-height: 1.2rem;
    position: absolute;
    text-transform: uppercase;
    top: -12px;
  }

  &__button {
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 5px;
    border-radius: 100%;
    border: thin solid rgb(190, 190, 190);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
      cursor: pointer;
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.2);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
      transform: scale(0.95);
    }
  }
}

#msn-ads-sticky-footer iframe {
  display: block;
}

#msn-ads-sticky-footer {
  display: flex;
  justify-content: center;
}