.content--group__search {
  display: grid;

  .search-result-text {
    margin-bottom: 2.5rem;
  }

  .article--type-search-result {
    position: relative;
    @include breakpoint(tablet) {
      position: unset;
    }

    .article__teaser__image {
      position: absolute;
      top: 0;
      right: 0;
      @include breakpoint(tablet) {
        position: relative;
        top: auto;
        right: auto;
      }
      width: 12rem;

      picture {
        padding-top: $aspect-4-3;
        @include breakpoint(tablet) {
          padding-top: $aspect-16-9;
        }
      }

      .author-overlay__image {
        height: 30%;
        @include breakpoint(tablet) {
          height: 40%;
        }
      }

      .interferer {
        height: 2.5rem;
        width: 2.5rem;
        min-width: auto;
        @include breakpoint(tablet) {
          width: auto;
          height: auto;
        }

        &__text {
          display: none;
          @include breakpoint(tablet) {
            display: initial;
          }
        }

        &__icon {
          left: 0.5rem;
          top: 0.5rem;
          width: 1.5rem;
          height: 1.5rem;
          @include breakpoint(tablet) {
            height: 1.607rem;
            left: 0.6rem;
            top: 0.6rem;
            width: 1.837rem;
          }
        }
      }
    }

    &.article--type-liveticker .interferer__icon {
      top: 0;
      left: -0.5rem;
      @include breakpoint(tablet) {
        left: 0;
        top: 0.4rem;
      }
    }

    @include breakpoint(maxTablet) {
      flex-direction: row;
      flex-wrap: wrap;

      .article__teaser__subline {
        order: 1;
        width: 100%;
      }

      .article__teaser__wrapper {
        width: calc(100% - 9rem);
        padding-right: 1rem;
      }

      .article__teaser__headline {
        font-size: 1.6rem;
        line-height: 2rem;
        order: 2;
      }

      .article__teaser__image {
        min-height: unset;
        order: 3;
        width: 9rem;
        height: auto;
      }
    }

    @include breakpoint(maxTablet) {
      &.article__teaser--video.article--type-video  {

        .interferer {
          display: block;
          height: 2rem;
          min-width: 2rem;
          order: 3;
          position: absolute;
          right: .5rem;
          width: 2rem;

          .interferer__icon {
            height: 1.2rem;
            left: .6rem;
            position: absolute;
            top: .35rem;
            width: .9rem;
          }
        }
      }

      &.article--type-column {
        .article__teaser__wrapper {
          width: calc(100% - 9rem);
        }

        .article__teaser__image {
          width: 9rem;
        }
      }

      &.article__teaser--comment.article--type-comment {
        .article__teaser__headline {
          width: 100%;
        }
        .article__teaser__author-img {
          height: 3rem;
          margin-top: .4rem;
          width: 3rem;
        }
        .article__teaser__info {
          width: calc(100% - 3rem);
        }
        .article__teaser__image {
          order: 2;
          width: 9rem;
        }
      }
    }
  }

  @include breakpoint(desktop) {
    .article__teaser__separator {
      margin-bottom: 2rem;
    }
  }

  .hide-mobile {
    display: none;
    @include breakpoint(desktop) {
      display: unset;
    }
  }

  .outer-wrapper {
    min-height: 35.7rem;
    margin: 0;
    padding-top: 3.8rem;

    .inner-wrapper {
      width: 30rem;

      .msn-ads {
        height: 28rem;
      }
    }
  }

  .article__teaser--advertorial {
    height: auto;
    min-height: auto;

    .article__teaser__wrapper {
      padding: 0 1.1rem;
      max-width: 95%;
    }
  }

}