.summary-element {
    font-size: 1.7rem;
    line-height: 2.5rem;

    &__title {
        width: 100%;
        padding-bottom: 1.15rem; 
        border-bottom: .1rem solid $color-nero;
        margin-bottom: 1.75rem;
        margin-left: auto;
        margin-right: auto;
        max-width: 72.4rem;
    }

    &__summary {
        padding-left: 2rem;

        @include breakpoint(tablet) {
            font-size: 2rem;
            line-height: 2.8rem;
        }

        &__element {
            margin-bottom: 1.2rem;
        }
    }
}