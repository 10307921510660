body, html {
  overflow-x: unset;
}

article.content--group__article {
  & div.outer-wrapper {
    background-color: $color-white-smoke-2;
    @include breakpoint(desktop) {
      margin: 3rem -10.5rem 3rem -10.5rem;
    }
  }
}

.msn-ads {
  position: relative;
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
  background-color: #ededed;
  z-index: 1;
}

#msn-ads-top {
  height: 14rem;
  padding: 2rem 0;

  &.ad_label {
    &:before {
      top: 5px;
    }
  }
} 

@media (min-width: 770px) {
  #msn-ads-top {
    height: 29rem;
  }
}

#msn-ads-in-content-1,
#msn-ads-in-content-3,
#msn-ads-in-content-5 {
  height: 28rem;
}

#msn-ads-in-content-2,
#msn-ads-in-content-4 {
  height: 63rem;
}

#msn-ads-in-content-1,
#msn-ads-in-content-2,
#msn-ads-in-content-3,
#msn-ads-in-content-4,
#msn-ads-in-content-5 {
  background: $color-white-smoke-2;
}

#msn-ads-top,
#msn-ads-in-content-1,
#msn-ads-in-content-2,
#msn-ads-in-content-3,
#msn-ads-in-content-4,
#msn-ads-in-content-5 {
  text-align: center;

  > div {
    position: sticky;
    top: 0;
  }
}

#msn-ads-in-content-1,
#msn-ads-in-content-2,
#msn-ads-in-content-3,
#msn-ads-in-content-4,
#msn-ads-in-content-5 {
  padding: 1.5rem 0;
}

body.section {
  #msn-ads-in-content-2,
  #msn-ads-in-content-3,
  #msn-ads-in-content-4,
  #msn-ads-in-content-5 {
    margin: 1.5rem -1rem;
    width: calc(100% + 2rem);
  }
}

@media (min-width: 770px) {

  body.section {
    #msn-ads-in-content-2,
    #msn-ads-in-content-3,
    #msn-ads-in-content-4,
    #msn-ads-in-content-5 {
      margin: 0 -1rem;
      width: calc(100% + 2rem);
    }
  }
}

@media (min-width: 1140px) {
  body.section {
    #msn-ads-in-content-2,
    #msn-ads-in-content-3,
    #msn-ads-in-content-4,
    #msn-ads-in-content-5 {
      margin: 0 -2rem 0 -1rem;
      width: calc(100% + 2rem);
    }
  }

  #msn-ads-top {
    width: 114rem;
  }
}

.ad__teaser {
  height: 100%;
  
  .article__teaser__subline,
  .article__teaser__wrapper {
    padding: 0 1rem;
  }

  .article__teaser__subline {
    color: $color-nero;
  }

  .article__teaser__wrapper {
    padding-bottom: 1rem;
  }
}

.ad_label {
  position: relative;

  &:before {
    content: "Anzeige";
    position: absolute;
    top: -12px;
    left: 10px;
    color: $color-comet;
    font-size: 1rem;
    line-height: 1.2rem;
    text-transform: uppercase;
    font-family: Source Sans Pro;
    z-index: 1;
  }
}

.msn-ads-frame_outer {
  display: none;

  @media (min-width: 1460px) {
    position: absolute;
    height: 50%;
    width: 16rem;
    min-height: 60rem;
    display: block;
    z-index: 1;

    &[class*="-right-"] {
      right: -16rem;
    }

    &[class$="-1"] {
      top: 0;
    }

    &[class$="-2"] {
      top: 50%;
    }
  }

  @media (min-width: 1740px) {
    width: 30rem;

    &[class*="-right-"] {
      right: -30rem;
    }
  }
}

.msn-ads-frame_inner {
  position: absolute;
  height: 100%;
  width: 16rem;
  top: 0;
  z-index: 1;

  @media (min-width: 1740px) {
    width: 30rem;
  }
}

[id^="msn-ads-off-content"] {
  position: sticky;
  width: 16rem;
  min-height: 105rem;
  top: 15px;

  @media (min-width: 1740px) {
    width: 30rem;
  }
}