.yt_container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  @include breakpoint(maxTablet) {
    width: 100vw;
    margin-left: -1.5rem;
  }
}

.yt_video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}