/**
 * Media Queries
 */
@mixin breakpoint($point) {

    @if $point == tablet {
        @media (min-width: $width_tablet) { @content; }
    }

    @else if $point == desktop {
        @media (min-width: $width_desktop) { @content; }
    }

    @else if $point == maxTablet {
        @media (max-width: $width_desktop) { @content; }
    }
}

/**
 * gradient mixins
 */
@mixin gradient-white-transparent-r_l {
    background: -moz-linear-gradient(left,  rgba(229,229,229,0) 1%, rgba(229,229,229,0.29) 30%, rgba(255,252,252,0.7) 70%, rgba(255,252,252,1) 100%);
    background: -webkit-linear-gradient(left,  rgba(229,229,229,0) 1%,rgba(229,229,229,0.29) 30%,rgba(255,252,252,0.7) 70%,rgba(255,252,252,1) 100%);
    background: linear-gradient(to right,  rgba(229,229,229,0) 1%,rgba(229,229,229,0.29) 30%,rgba(255,252,252,0.7) 70%,rgba(255,252,252,1) 100%);
}

@mixin gradient-white-transparent-b-t {
    background: -moz-linear-gradient(top,  rgba(229,229,229,0) 1%, rgba(229,229,229,0) 28%, rgba(255,252,252,0.49) 50%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(229,229,229,0) 1%,rgba(229,229,229,0) 28%,rgba(255,252,252,0.49) 50%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(229,229,229,0) 1%,rgba(229,229,229,0) 28%,rgba(255,252,252,0.49) 50%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin gradient-prussian-blue-transparent-r_l {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#86909e+85,00386c+100&0+60,1+99 */
    background: -moz-linear-gradient(left,  rgba(134,144,158,0) 60%, rgba(134,144,158,0.64) 85%, rgba(9,62,111,1) 99%, rgba(0,56,108,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(134,144,158,0) 60%,rgba(134,144,158,0.64) 85%,rgba(9,62,111,1) 99%,rgba(0,56,108,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(134,144,158,0) 60%,rgba(134,144,158,0.64) 85%,rgba(9,62,111,1) 99%,rgba(0,56,108,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0086909e', endColorstr='#00386c',GradientType=1 ); /* IE6-9 */
}


@mixin gradient-nero-transparent-r-l {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1e5799+0,2a2a2a+100&0+0,1+100 */
    background: -moz-linear-gradient(left,  rgba(30,87,153,0) 0%, rgba(42,42,42,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(30,87,153,0) 0%,rgba(42,42,42,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(30,87,153,0) 0%,rgba(42,42,42,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e5799', endColorstr='#2a2a2a',GradientType=1 ); /* IE6-9 */

}

/**
 * Transform
 */
// generic transform
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

// rotate
@mixin rotate($deg) {
    @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
    @include transform(scale($scale));
}

// translate
@mixin translate($x, $y) {
    @include transform(translate($x, $y));
}

// skew
@mixin skew($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}

// transform Origin
@mixin transform-origin($origin) {
    -webkit-transform-origin: $origin;
    -moz-transform-origin: $origin;
    -ms-transform-origin: $origin;
    transform-origin: $origin;
}

/**
 * Transition
 */
// generic $transition
@mixin transition($transition) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin transition-property($property) {
    -moz-transition-property: -moz-$property;
    -o-transition-property: -o-$property;
    -webkit-transition-property: -webkit-$property;
    transition-property: $property;
}

@mixin transition-duration($duration) {
    -moz-transition-property: $duration;
    -o-transition-property: $duration;
    -webkit-transition-property: $duration;
    transition-property: $duration;
}

@mixin transition-timing-function($timing) {
    -moz-transition-timing-function: $timing;
    -o-transition-timing-function: $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function: $timing;
}

@mixin transition-delay($delay) {
    -moz-transition-delay: $delay;
    -o-transition-delay: $delay;
    -webkit-transition-delay: $delay;
    transition-delay: $delay;
}