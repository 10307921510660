.chat {
  &__author {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-top: 2.5rem;
    padding: 0;

    @include breakpoint(tablet) {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }

    &:first-child {
      margin-top: 0;
    }

    &.text--r {
      .chat__text {
        order: 1;
        margin-right: 1rem;
        margin-left: auto;
      }

      .chat__image {
        order: 2;
      }
    }

    &.text--l {
      .chat__text {
        margin-left: 1rem;
      }
    }
  }

  &__bubble {
    line-height: 2.2rem;
    padding: 1rem;
    margin-top: 3rem;

    @include breakpoint(tablet) {
      font-size: 1.9rem;
      line-height: 2.8rem;
      max-width: 55rem;
      padding: 1rem 1.5rem;
    }

    @include breakpoint(desktop) {
      max-width: 62rem;
    }

    .chat__author + & {
      margin-top: .5rem;

      @include breakpoint(tablet) {
        margin-top: 1rem;
      }
    }
  }

  @include breakpoint(maxTablet) {
    &__question + .chat__question {
      margin-top: 1rem;
    }

    &__answer + .chat__answer {
      margin-top: 1rem;
    }
  }

  &__answer {
    @include breakpoint(tablet) {
      margin-left: auto;
    }
  }

  &__image {
    width: 30px;
    height: 30px;
    @include breakpoint(tablet) {
      width: 45px;
      height: 45px;
    }
  }
}