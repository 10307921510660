.related-article {

  margin: 1.6rem 0 1.148rem;

  &__headline {
    font-size: 1.4rem;
    letter-spacing: .028rem;
    line-height: 1.8rem;
    margin-bottom: .8rem;
    text-transform: uppercase;

    @include breakpoint(tablet) {
      font-size: 1.5rem;
      letter-spacing: .03rem;
      margin-bottom: .7rem;
    }
  }

  &__link {
    a {
      text-decoration: none;
    }

    h4 {
      font-size: 1.6rem;
      line-height: 2rem;
      color: $color-nero;

      @include breakpoint(tablet) {
        font-size: 2.6rem;
        line-height: 3rem;
      }
    }
  }

  &__link--subline {
    font-size: 1.5rem;
    line-height: 1.8rem;
    margin-bottom: .2rem;


    @include breakpoint(tablet) {
      line-height: 1.8rem;
      margin-bottom: unset;
    }
  }

  &__link--title {
    font-size: 1.6rem;
    line-height: 2rem;

    @include breakpoint(tablet) {
      font-size: 2.6rem;
      line-height: 3rem;
    }
  }
}