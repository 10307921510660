$tabCount: 3;
$boxName: "league-table";
@include tabBox($boxName, $tabCount);

.tabbed-box.league-table {

  @for $i from 1 through $tabCount {
    #tab-#{$boxName}-#{$i}:checked ~ nav .label-#{$boxName}-#{$i} {
      font-weight: 600;
    }
  }

  nav {
    border-top: 2px solid $color-white-smoke-2;
    border-bottom: 2px solid $color-white-smoke-2;
    color: $color-nobel;
    @extend .size--m-4;
    padding: .7rem 0 .9rem 1rem;

    @include breakpoint(tablet) {
      border-top: 4px solid $color-white-smoke-2;
      border-bottom: unset;
      clear: both;
      line-height: 1.6rem;
      padding: 3.4rem 1rem;
    }

    label {
      cursor: pointer;
      margin-right: 2.2rem;

      @include breakpoint(tablet) {
        font-size: 1.8rem;
        margin-right: 3rem;
      }
    }
  }
}