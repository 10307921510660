.border {
  border-width: thin;
  border-style: solid;

  // Solid borders
  &-thin {
    border-width: thin;
  }

  &-t {
    border-top-width: thin;
    border-top-style: solid;
  }

  &-r {
    border-right-width: thin;
    border-right-style: solid;
  }

  &-b {
    border-bottom-width: thin;
    border-bottom-style: solid;
  }

  &-l {
    border-left-width: thin;
    border-left-style: solid;
  }

  // dotted borders
  &-dotted {
    border-width: thin;
    border-style: dotted;
  }

  &-dotted-t {
    border-top-width: thin;
    border-top-style: dotted;
  }

  &-dotted-r {
    border-right-width: thin;
    border-right-style: dotted;
  }

  &-dotted-b {
    border-bottom-width: thin;
    border-bottom-style: dotted;
  }

  &-dotted-l {
    border-left-width: thin;
    border-left-style: dotted;
  }

  // dashed borders
  &-dashed {
    border-width: thin;
    border-style: dashed;
  }

  // Border radius definitions
  &-rad-15 {
    border-radius: 1.5rem;
  }

  &-rad-02 {
    border-radius: .2rem;
  }

  &-rad-04 {
    border-radius: .4rem;
  }

  &-table {
    content: "";
    position: absolute;
    bottom: 0;
    border-color: $color-silver;
    width: 100%;
    height: 100%;
  }

  &-none-t {
    border-right: unset;
  }

  &-none-r {
    border-right: unset;
  }

  &-none-b {
    border-bottom: unset;

    @include breakpoint(tablet) {

      &--tablet {
        border-bottom: unset;
      }
    }
  }

  &-none-l {
    border-left: unset;
  }

  &-color-silver {
    border-color: $color-silver;
  }

  &-color-white-smoke-2 {
    border-color: $color-white-smoke-2;
  }

  &-color-white-smoke-3 {
    border-color: $color-white-smoke-3;
  }

  &-round {
    border-radius: 50%;
  }

  &-bottom-width {
    border-bottom-width: .3rem;

    @include breakpoint(tablet) {
      border-bottom-width: .8rem;
    }
  }

  &--content-group {
    border-bottom: .5rem solid $color-white-smoke-2;
  }
}
