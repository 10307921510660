.event-slider-box {
  .box-name {
    font-size: 1.8rem;
    line-height: 2.3rem;
    padding: 1.7rem 1.98rem 1.5rem 1rem;
    position: relative;
    border-bottom: .5rem solid $color-white-smoke-1;

    @include breakpoint(tablet) {
      font-size: 3.4rem;
      line-height: 3.6rem;
      padding: 1.7rem  1.5rem 1.7rem 1rem;
    }

    .icon-jobs {
      position: absolute;
      right: 1.98rem;
      top: 1.183rem;

      @include breakpoint(tablet) {
        height: 4.292rem;
        right: 1.598rem;
        top: 1.4rem;
        width: 10.602rem;
      }
    }
  }

  .slider {
    @include breakpoint(tablet) {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    &:after {
      height: 88%;
    }

    .slider__elements {
      margin: .6rem 0 0 0;

      @include breakpoint(desktop) {
        margin: .9rem 0 .2rem 0;
      }
    }

    .events-wrapper {
      display: flex;
      flex-flow: column;
      margin-left: 1rem;
      scroll-margin-left: 1rem;

      @include breakpoint(tablet) {
        margin-left: 0;
      }

      &.spacer {
        margin-left: 1.2rem;
        scroll-margin-left: 1.2rem;

        @include breakpoint(tablet) {
          margin-left: 1.1rem;
          scroll-margin-left: 1.1rem;
        }
      }
    }

    .date-line  {
      display: flex;
      font-size: 1.4rem;
      line-height: 1.8rem;

      @include breakpoint(desktop) {
        font-size: 1.6rem;
      }

      &:not(.first) {
        padding-left: 1rem;
      }

      &:after {
        border-top: thin dotted $color-silver;
        bottom: .3rem;
        content: '';
        display: block;
        flex-grow: 1;
        margin: 2rem 0 0 1.2rem;
        position: relative;
        width: 88%;
      }

      &:not(.first) {
        padding-left: 0;
      }

      &:not(.first):after {
        margin-bottom: .7rem;
        margin-left: 0;
        width: 100%

      }

      @include breakpoint(desktop) {
        &:not(.first):after {
          margin-bottom: 1.3rem;
        }
      }

      &__day {
        background-color: $color-prussian-blue;
        border-radius: .5rem;
        color: $color-white;
        padding: .5rem 1rem .5rem 1rem;

        @include breakpoint(desktop) {
          padding: .7rem 1rem .9rem 1rem;
        }
      }
    }

    .day-entries {
      display: flex;
    }

    &__element {
      padding-top: .6rem;
      width: 26rem;

      &:first-of-type {
        margin-left: 0;
      }

      &:not(.first) {
        margin-left: 1rem;
      }

      @include breakpoint(tablet) {
        padding-top: .9rem;
      }

      &__img {
        min-height: 16.3rem;
        position: relative;

        &:after {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #fff;
          content: '';
        }
      }

      &__img-placeholder {
        height: 16.3rem;
        width: 26rem;
        border: thin solid silver;
      }

      &__subline {
        font-size: 1.5rem;
        line-height: 1.8rem;
        margin-top: .9rem;

        @include breakpoint(tablet) {
          margin-top: .6rem;

          @include breakpoint(desktop) {
            margin-top: 1.2rem;
          }
        }
      }

      &__headline {
        line-height: 2rem;
        margin-top: .3rem;

        @include breakpoint(desktop) {
          font-size: 1.9rem;
          line-height: 2.3rem;
          margin-top: 0;
        }
      }

      &__info {
        color: $color-comet;
        font-size: 1.4rem;
        line-height: 1.8rem;
        margin-top: .4rem;

        @include breakpoint(desktop) {
          font-size: 1.5rem;
          line-height: 2.8rem;
          margin-top: 0;
        }
      }
    }

    .three-dots {
      margin: 2rem auto;
    }

    &.loading {
      .slider__element {
        &__img-placeholder {
          background-color: #f5f5f5;
        }

        &__subline:after {
          content: '';
          display: block;
          background-color: $color-pacific-blue;
          width: 53%;
          height: 4px;
          margin-top: 2.4rem;
        }

        &__headline {
          content: '';
          display: block;
          background-color: $color-nero;
          width: 86%;
          height: 4px;
          margin-top: 2.3rem;
        }

        &__info {
          content: '';
          display: block;
          background-color: $color-comet;
          width: 49%;
          height: 4px;
          margin-top: 1.8rem;
        }
      }
    }
  }

  .slider-box__more {
    background-color: $color-white;
    bottom: .4rem;
    padding-bottom: .9rem;
    padding-right: 1rem;

    @include breakpoint(desktop) {
      bottom: .6rem;
    }
  }

  .next-bubble__arrow-right {
    fill: $color-white;
  }
}