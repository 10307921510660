.app-audio-player {
  align-items: center;
  margin: -10px 0 0;
  display: none;
  font-family: var(--typeface-font);
  font-size: 16px;

  &__status__visible {
    display: flex;
  }

  &__control {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  &__control_button {
    display: block;
    width: 30px;
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    image-rendering: pixelated;
    overflow: hidden;
    text-indent: -300px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='30px' height='30px' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Player-im-Artikel-–-Zur-Playlist-hinzugefügt' transform='translate(-15.000000, -491.000000)' fill='%23000000'%3E%3Cg id='Player-klein' transform='translate(15.000000, 491.000000)'%3E%3Cg id='Group-33'%3E%3Cpath shape-rendering='geometricPrecision' d='M12.0003605,21.750015 L21.0001802,15.0003004 L12.0003605,8.24998498 L12.0003605,21.750015 Z M15.0003004,3.55271368e-15 C6.71606817,3.55271368e-15 0,6.71606817 0,15.0003004 C0,23.2845327 6.71606817,30 15.0003004,30 C23.2845327,30 30,23.2845327 30,15.0003004 C30,6.71606817 23.2845327,3.55271368e-15 15.0003004,3.55271368e-15 L15.0003004,3.55271368e-15 Z M3.00054075,15.0003004 C3.00054075,8.38517154 8.38517154,2.99993992 15.0003004,2.99993992 C21.6154293,2.99993992 27.0000601,8.38517154 27.0000601,15.0003004 C27.0000601,21.6148285 21.6154293,27.0000601 15.0003004,27.0000601 C8.38517154,27.0000601 3.00054075,21.6148285 3.00054075,15.0003004 Z' id='Fill-7'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }

  &__status__playing .app-audio-player__control_button {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='30px' height='30px' viewBox='0 0 30 30' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Player-im-Artikel-–-Titel-abspielen' transform='translate(-15.000000, -497.000000)' fill='%23FF0000'%3E%3Cg id='Group-33' transform='translate(15.000000, 497.000000)'%3E%3Cpath d='M15,3 C8.372625,3 3,8.372625 3,15 C3,21.627375 8.372625,27 15,27 C21.627375,27 27,21.627375 27,15 C27,8.372625 21.627375,3 15,3 L15,3 Z M15,0 C23.284125,0 30,6.7155 30,15 C30,23.2845 23.284125,30 15,30 C6.715875,30 0,23.2845 0,15 C0,6.7155 6.715875,0 15,0 L15,0 Z M10.5,21 L13.5,21 L13.5,9 L10.5,9 L10.5,21 Z M16.5,21 L19.5,21 L19.5,9 L16.5,9 L16.5,21 Z' id='Fill-1'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }

  &__control_time {
    margin-left: 8px;
    white-space: nowrap;
    color: var(--color-comet);
    min-width: 52px;
  }

  &__meta {
    flex-grow: 10;
    color: var(--color-comet);
    padding-left: 30px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
    max-height: 20px;
    line-height: 20px;
    white-space: nowrap;

    &::before {
      display: block;
      content: ' ';
      width: 10px;
      height: 13px;
      position: absolute;
      bottom: 4px;
      left: 14px;
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='10px' height='13px' viewBox='0 0 10 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Player-im-Artikel-–-Zur-Playlist-hinzugefügt' transform='translate(-145.000000, -499.000000)' fill='%23777777' fill-rule='nonzero'%3E%3Cg id='Player-klein' transform='translate(15.000000, 491.000000)'%3E%3Cg id='microphone' transform='translate(130.000000, 8.000000)'%3E%3Cpath d='M4.78947368,-4.4408921e-16 C5.9231108,-4.4408921e-16 6.84210526,0.918994461 6.84210526,2.05263158 L6.84210526,6.15789474 C6.84210526,7.29153185 5.9231108,8.21052632 4.78947368,8.21052632 C3.65583657,8.21052632 2.73684211,7.29153185 2.73684211,6.15789474 L2.73684211,2.05263158 C2.73684211,0.918994461 3.65583657,-4.4408921e-16 4.78947368,-4.4408921e-16 L4.78947368,-4.4408921e-16 Z M9.57894737,6.15789474 C9.57894737,8.57315789 7.79315789,10.5642105 5.47368421,10.8994737 L5.47368421,13 L4.10526316,13 L4.10526316,10.8994737 C1.78578947,10.5642105 0,8.57315789 0,6.15789474 L1.36842105,6.15789474 C1.36842105,8.04728993 2.90007849,9.57894737 4.78947368,9.57894737 C6.67886888,9.57894737 8.21052632,8.04728993 8.21052632,6.15789474 L9.57894737,6.15789474 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
  }

  &__playlist {
    margin-left: 10px;
    display: block;
    flex-grow: 1;
    width: 28px;
    min-width: 28px;
    max-width: 28px;
    height: 20px;
    overflow: hidden;
    text-indent: -200px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='28px' height='20px' viewBox='0 0 28 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Startseite---shz---Audio' transform='translate(-331.000000, -330.000000)' fill='%23777777'%3E%3Cg id='Player-klein' transform='translate(331.000000, 330.000000)'%3E%3Cpath d='M-3.3684989e-13,8.26727273 L16.5354545,8.26727273 L16.5354545,5.51181818 L-3.3684989e-13,5.51181818 L-3.3684989e-13,8.26727273 Z M16.5354545,0 L-3.3684989e-13,0 L-3.3684989e-13,2.75636364 L16.5354545,2.75636364 L16.5354545,0 Z M22.0472727,11.0236364 L22.0472727,5.51181818 L19.2909091,5.51181818 L19.2909091,11.0236364 L13.7790909,11.0236364 L13.7790909,13.78 L19.2909091,13.78 L19.2909091,19.2918182 L22.0472727,19.2918182 L22.0472727,13.78 L27.5590909,13.78 L27.5590909,11.0236364 L22.0472727,11.0236364 Z M-3.3684989e-13,13.78 L11.0236364,13.78 L11.0236364,11.0236364 L-3.3684989e-13,11.0236364 L-3.3684989e-13,13.78 Z' id='Fill-34'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }

  &__status__onplaylist .app-audio-player__playlist {
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='23px' height='20px' viewBox='0 0 23 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Player-im-Artikel-–-Zur-Playlist-hinzugefügt' transform='translate(-337.000000, -497.000000)' fill='%23000000' fill-rule='nonzero'%3E%3Cg id='Player-klein' transform='translate(15.000000, 491.000000)'%3E%3Cg id='check_bold' transform='translate(322.000000, 6.000000)'%3E%3Cpolygon id='Path' points='22.92 2.52 7.68 17.76 0.24 10.32 2.76 7.8 7.68 12.72 20.4 0'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }
}