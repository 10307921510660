// columns count
$grid-columns: 12;

// space between columns
$grid-gap-mobile: 2rem;
$grid-gap-tablet: 2.8rem;
$grid-gap-desktop: 2rem;

$flexgrid-breakpoints: (
  m: $width_tablet,
  t: $width_tablet,
  d: $width_desktop
);

$flexgrid-gutters: (
  m: $grid-gap-mobile,
  t: $grid-gap-tablet,
  d: $grid-gap-desktop,
);

@mixin flexgrid-break($size) {
  @media (min-width: map-get($flexgrid-breakpoints, $size)) {
    @content;
  }
}

.grid {
  box-sizing: border-box; // [0]
  display: flex; // [1]
  flex-flow: row wrap; // [2]
  list-style: none; // [3]
  margin: 0; // [3]
  padding: 0; // [3]

  &--column {
    height: 100%;
    flex-direction: column; // [4]
  }

  &---align-start {
    align-items: flex-start; // [5]
  }

  &--align-end {
    align-items: flex-end; // [6]
  }

  &--align-center {
    align-items: center; // [7]
  }

  &--justify-center {
    justify-content: center; // [8]
  }

  &--justify-end {
    justify-content: flex-end; // [9]
  }

  &--space-between {
    justify-content: space-between; // [10]
  }

  &--space-around {
    justify-content: space-around; // [11]
  }

  &-cell {
    flex: 1;
  }

  &-cell--auto {
    flex: 0 1 auto;
  }

  // CSS für das Layout in der 2--3--1--3 Aufteilung

  .grid-gutter-2-3--1-3 {
    > [class^="grid-cell"] {
      padding-left: 2rem;
    }
  }
}

$flexgrid-cell-counter: 1;
@each $key, $val in $flexgrid-breakpoints {
  .grid-cell--#{$key} {
    flex: 0 0 100%;
    max-width: 100%;

    @include flexgrid-break($key) {
      flex: 1;
    }
  }

  @for $i from 1 through $grid-columns {
    @if ($flexgrid-cell-counter == 1) {
      .grid-cell--#{$key}-#{$i} {
        flex: 0 0 ($i/$grid-columns)*100%;
        max-width: ($i/$grid-columns)*100%;
      }
    }
  }

  @include flexgrid-break($key) {
    @for $i from 1 through $grid-columns {
      .grid-cell--#{$key}-#{$i} {
        flex: 0 0 ($i/$grid-columns)*100%;
        max-width: ($i/$grid-columns)*100%;
      }
    }
  }

  @include flexgrid-break($key) {
    @for $i from 0 through $grid-columns {
      .grid-cell--#{$key}-offset-#{$i} {
        margin-left: ($i/$grid-columns)*100%;
      }
    }
  }

  $flexgrid-cell-counter: $flexgrid-cell-counter + 1;
}

@each $key, $val in $flexgrid-gutters {
  .grid--gutter-#{$key} {
    //margin-top: -$val;
    margin-left: -$val;

    > [class^="grid-cell"] {
      //padding-top: $val;
      padding-left: $val;
    }

  }
}

.grid-debug {
  max-width: 1140px;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: #EDDDB2;

  @include breakpoint(tablet) {
    background-color: rgba(5, 215, 7, 0.4);
  }

  @include breakpoint(desktop) {
    background-color: rgba(235, 134, 0, 0.69);
  }

  &:before {
    position: fixed;
    content: "mobile";

    @include breakpoint(tablet) {
      content: "Breakpoint #{$width_tablet} (tablet)";
    }

    @include breakpoint(desktop) {
      content: "Breakpoint #{$width_desktop} (desktop)";
    }

    bottom: 0;
    left: 0;
    padding: 5px;
    font-family: tahoma, sans-serif;
    font-size: 12px;
    color: $color-red;
  }

  &--box {
    background-color: #0088CC;

    .grid-debug--box {
      background-color: #6bccc4;

      .grid-debug--box {
        background-color: #b9c1cc;
      }
    }
  }
}

