.author-overlay {
  position: absolute;
  width: 30%;
  height: 100%;
  bottom: 0;
  right: 0;
  z-index: 1;

  &--default:after {
    background-color: $color-pacific-blue;
  }

  &:after {
    position: absolute;
    content: '';
    right: 0;
    bottom: 0;
    left: 0;
    padding-bottom: 100%;
    -webkit-clip-path: polygon(0% 100%, 100% 100%, 100% 0);
    clip-path: polygon(0% 100%, 100% 100%, 100% 0);
    z-index: 5;
  }

  &__image {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 75%;
    height: 40%;

    .content--stage & {
      height: 30%;
      @include breakpoint(tablet) {
        height: 40%;
      }
    }
    z-index: 10;
  }
}

.article__teaser--small {
  .author-overlay__image {
    width: 100%;
  }
}