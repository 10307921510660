.audio-paywall {

  &__bg {
    background-color: #000;
    width: 100vw;
    height: 100vh;
    left: 0;
    opacity: .8;
    position: absolute;
    top: 0;
  }

  &__wrapper {
    background-color: white;
    margin: 0 auto;
    position: relative;
    text-align: center;
    width: auto;
    z-index: 10;
  }
}

div[data-role="sdn-audio-player"] {
  // min-height: 33.1rem;

  .scroller {
    position: relative;
    left: 0;
    transition: left 3s ease-in-out;
  }
}