.content--group__article {
  .underline-blue {
    color: $color-pacific-blue;
    text-decoration: underline;

    &:hover,
    &:active,
    &:visited {
      color: $color-prussian-blue-80;
    }
  }
}

.content--group__search {
  p {
    line-height: 2.2rem;
  }
}