// Border colors
.border-color {

  &__silver-100 {
    border-color: $color-silver;
  }

  &__light-grey8 {
    border-color: $color-whisper;
  }

  &__comet {
    border-color: $color-comet;
  }

  &__nobel {
    border-color: $color-nobel;
  }

  &__white-smoke-2 {
    border-color: $color-white-smoke-2;
  }

  &__very-light-grey {
    border-color: $color-very-light-grey;
  }
}