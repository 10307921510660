.league-tab-table-box {

  &__header {
    @extend .size--l-0;
    line-height: 2.6rem;
    padding: .8rem 0 1rem 1rem;

    @include breakpoint(tablet) {
      font-size: 2rem;
      line-height: 2.6rem;
      padding: 2.2rem 1rem;
    }

    &--part {
      @include breakpoint(tablet) {
        &:first-child {
          margin-right: 1rem;
        }
        
        display: inline-block;
      }
    }
  }

  &__footer {
    font-size: 1.2rem;
    line-height: 1.8rem;
    padding: 2rem;

    @include breakpoint(tablet) {
      font-size: 1.4rem;
    }
  }
}