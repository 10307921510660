.article--type {
  &-liveticker {
    .interferer {
      background-color: $color-free-speech-red;
      bottom: 1rem;
      height: 3.07rem;
      min-width: 3.07rem;
      padding: 0 .95rem;
      right: 1rem;
      text-align: center;

      &__dot {
        height: 1rem;
        // margin-right: .9rem;
        width: 1rem;
      }

      &__dot:not(.no-animation) {
        position: relative;
        display: inline-block;
        width: .9rem;
        height: .9rem;
        // margin: 0 .9rem 0 0;

        &:before,
        &:after {
          position: absolute;
          content: ' ';
          background-color: $color-white;
          width: 1.4rem;
          height: 1.4rem;
          top: -.3rem;
          left: -.3rem;
          right: 0;
          bottom: 0;
          border-radius: 9999px;

          @include breakpoint(tablet) {
            top: -.2rem;
          }
        }

        &:before {
          -webkit-animation: pulseBefore 2s infinite both;
          -moz-animation: pulseBefore 2s infinite both;
          animation: pulseBefore 2s infinite both;
        }
        &:after {
          -webkit-animation: pulseAfter 2s infinite both;
          -moz-animation: pulseAfter 2s infinite both;
          animation: pulseAfter 2s infinite both;
        }
      }

      &__text {
        margin-left: 0.9rem;
        font-size: 1.4rem;
        line-height: 3.07rem;
      }
    }

    &.article__teaser--small {
      .interferer__icon {
        width: 1.2rem;
        height: 1.2rem;
        left: .04rem;
        top: -4.9px;
      }

      .interferer__dot {
        height: .6rem;
        margin: 0 0 .75rem;
        width: .6rem;

        @include breakpoint(tablet) {
          height: .9rem;
          margin: 0 0 .6rem 0;
          width: .9rem;
        }
      }

      &:not(.article__teaser--no-image) {
        .interferer__dot {
          left: .1rem;
          top: .1rem;
        }

        .article__teaser__image {
          .interferer {
            display: inline-block;
            min-width: 2rem;
            width: 2rem;
          }
        }
      }
    }

    &--not-active {
      .article__teaser__info,
      .article__teaser__headline {
        padding-left: 0 !important;
      }
    }

    &.article__teaser--no-image {
      .article__teaser__wrapper {
        flex-flow: row;
      }

      .interferer-reduced {
        background-color: $color-free-speech-red;

        .interferer{
          &__icon {
            height: 2.3rem;
            left: .3rem;
            top: .1rem;
            width: 2.3rem;
          }

          &__dot {
            margin: calc((3rem - .9rem) / 2);
          }

          &__text {
            display: none;
          }
        }
      }
    }

    // 1 Row
    @include breakpoint(desktop) {
      .grid-cell--d-4 & {
        &.article__teaser--small {
          .article__teaser__image .interferer {
            bottom: .5rem;
            display: block;
            height: 2rem;
            min-width: 2rem;
            right: .5rem;
            width: 2rem;

            .interferer__dot:not(.no-animation) {
              height: .6rem;
              left: .5rem;
              margin-bottom: .5rem;
              position: absolute;
              top: .5rem;
              width: .6rem;
            }
          }
        }
      }

      .grid-cell--d-6 & {
        .article__teaser__info {
          padding: 1rem 0 0 0;
        }

        .article__teaser__separator {
          padding: 2rem 0 0 0;
        }
      }

      .grid-cell--d-8 & {
        .interferer {
          bottom: 1rem;
          right: 1rem;
        }

        .article__teaser__info {
          font-size: 1.6rem;
        }

        .article__teaser__separator {
          grid-row-start: 4;
          grid-row-end: 4;
        }
      }
    }

    // 1.5 Rows
    @include breakpoint(desktop) {
      .grid-cell--d-6 & {
        &.article__teaser,
        &.article__teaser--small {
          .interferer {
            bottom: 1rem;
            height: 3.07rem;
            padding: 0 .9rem;
            right: 1rem;
            text-align: center;
            width: unset;

            &__dot {
              margin-bottom: unset;
              // margin-right: .9rem;
            }
          }
        }
      }
    }

    // 2 Rows Tablet
    @include breakpoint(tablet) {
      .grid-cell--t-12 &,
      .grid-cell--d-8 & {
        .interferer {
          //bottom: .7rem;
          height: 3.07rem;
          padding: 0 .9rem;
          //right: .7rem;
          text-align: center;
          width: unset;

          &__dot {
            margin-bottom: unset;
            // margin-right: .9rem;
          }

          &__text.hidden {
            display: inline-block;
          }
        }

        .article__teaser__separator {
          grid-row-start: 4;
          grid-row-end: 4;
        }
      }

      &.article__teaser--no-image {
        .interferer,
        .interferer-reduced {
          .interferer__text {
            display: none;
          }
        }
      }
    }

    .interferer__icon {
      bottom: -.1rem;
      height: 1.7rem;
      margin-top: .15rem;
      // margin-right: .96rem;
      position: relative;
      top: .25rem;
      width: 1.9rem;
    }
  }
}