.section-heading {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;

  &.has-weather {
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @include breakpoint(desktop) {
    flex-flow: row;
    justify-content: space-between;
  }

  &__header {
    min-height: 5.5rem;
    line-height: 2.3rem;
    padding: 1.8rem 1rem;

    @include breakpoint(desktop) {
      flex-shrink: 1;
      max-width: 70%;
      font-size: 3.4rem;
      min-height: 7rem;
      line-height: 4.2rem;
      padding: 1.3rem 1rem 1.4rem 1rem;
    }
  }

  &__links {
    position: relative;
    border-top: .2rem solid $color-whisper-2;
    display: flex;
    //margin-bottom: .2rem;
    padding: .8rem 1rem .8rem 0;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    width: 100%;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;

    &__scrollbar {
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-wrapper {
      overflow-y: hidden;

      @include breakpoint(desktop) {
        overflow-y: unset;
      }
    }

    &-blur {
      &:before {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        background: transparent linear-gradient(90deg, $color-transparent -50%, $color-white 100%) 0 0 no-repeat padding-box;
        content: '';
        width: 8rem;
        z-index: 1;
        pointer-events: none;

        @include breakpoint(desktop) {
          content: none;
        }
      }
    }

    @include breakpoint(desktop) {
      align-items: center;
      border-top: unset;
      display: flex;
      flex-shrink: 2;
      margin-bottom: unset;width: unset;
      padding: 1rem;
    }

    &.weather_links {
      border-top: 0.6rem solid #F2F2F2;
      padding-bottom: 1.2rem;
      padding-top: 1.3rem;
      width: 100%;
    }
  }

  &__weather {
    height: 5.2rem;
    margin-left: 1.5rem;

    @include breakpoint(desktop) {
      height: 7rem;
    }

    &__temp-d,
    &__temp-n {
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.8rem;
      position: relative;
      top: -1.7rem;

      @include breakpoint(desktop) {
        font-size: 1.8rem;
        top: -2.5rem;
      }
    }
    &__temp-n {
      margin-left: .4rem;

      @include breakpoint(desktop) {
        margin-left: .5rem;
      }
    }

    &__icon-weather {
      height: 3.8rem;
      margin: .7rem 1rem;

      @include breakpoint(desktop) {
        height: 5rem;
        margin: 1rem 1rem 1rem 2.3rem;
      }
    }
  }

  &__link {
    padding: 0 2.1rem 0 2rem;

    &:after {
      border-right: 1px solid silver;
      content: '';
      display: inline-block;
      height: 1.8rem;
      left: 2rem;
      position: relative;
      top: 4px;
      width: .1rem;
    }

    &:first-of-type {
      padding-left: 1rem;
    }

    &:last-child {
      border: none;
      padding-right: 0;

      &:after {
        visibility: hidden;
      }
    }
  }
}