.iframe-box {
  &__container {
    width: 100%;
    height: var(--mobile-height);

    @include breakpoint(desktop) {
      height: var(--desktop-height);
    }
  }

  &__ifrm {
    height: 100%;

    @include breakpoint(maxTablet) {
      width: 100vw;
      margin-left: -1.5rem;
    }
  }
}