.shadow {
  -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
  -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);

  &--text__s {
    text-shadow: 1px 1px 4px rgba(0,0,0,.8);
  }
}

