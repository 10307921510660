.article--type {
  &-column:not(.article__teaser--opener) {
    flex-flow: row;
    flex-wrap: wrap;


    .article__teaser__info {
      position: relative;

      div:last-child:not(.article__teaser__info--col-2-row--hide) {
        @include breakpoint(tablet) {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .article__teaser__subline {
      line-height: 1.8rem;
    }

    .article__teaser__image {
      width: 100%;
    }

    .article__teaser__wrapper {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      width: 100%;
    }

    .article__teaser__headline {
      float: left;
      font-weight: normal;
      font-style: italic;
      padding-bottom: .7rem;
      .article__teaser__headline {
        @include breakpoint(maxTablet) {
          padding-right: 1rem;
        }
      }
    }

    &:not(.article__teaser--small):not(.article__teaser__no-image) {
      .article__teaser__headline {
        padding-right: .5rem;
        width: calc(100% - 6rem);

        @include breakpoint(tablet) {
          padding-right: 1rem;
        }
      }
    }

    .article__teaser__info--col-2-row--show {
      display: none;
    }

    .article__teaser__info__add {
      display: none;
    }

    .article__teaser__column-logo {
      float: right;
      border-radius: .5rem;
      flex-shrink: 0;
      height: 6rem;
      order: 4;
      width: 6rem;

      img {
        border-radius: .5rem;
      }

      @include breakpoint(maxTablet) {
        top: -.2rem;
      }
    }

    &.article__teaser--small {
      &.article__teaser--no-image {
        .article__teaser__image {
          display: none;
        }
      }

      .article__teaser__headline {
        font-size: 1.6rem;
        line-height: 2rem;
        //padding-right: 1rem;
      }

      .article__teaser__wrapper {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        width: 100%;
      }

      .article__teaser__image {
        height: 1.5rem;
        margin-bottom: unset;
        width: 9rem;

        @include breakpoint(tablet) {
          width: 12rem;
        }
      }

      &.with-logo {
        .article__teaser__image {
          height: 6.8rem;

          @include breakpoint(tablet) {
            height: 9rem;
          }
        }
      }

      .article__teaser__info {
        display: flex;
        flex-flow: row;
        line-height: 3rem;

        div {
          line-height: 1.8rem;
          order: 2;

          &:nth-of-type(2) {
            margin-top: 5px;

            @include breakpoint(tablet) {
              margin-top: 0;
              margin-left: 4rem;
            }
          }
        }

        &__add {
          display: none;
        }
      }

      &:not(.article__teaser--no-image) {
        .article__teaser__column-logo {
          height: 3rem;
          margin-right: 1rem;
          order: 1;
          width: 3rem;
        }

        .column-logo--vfl {
          flex-shrink: 0;
          margin-top: 3px;
          top: -.6rem;
          width: 3rem;

          @include breakpoint(tablet) {
            top: 0;
          }

          &:before {
            background-size: 2.1rem 2rem;
            height: 2.1rem;
            top: .5rem;
            left: .5rem;
            width: 2rem;
          }
        }

        @include breakpoint(desktop) {
          .article__teaser__sub-headline--wrapper {
            min-height: 9rem;
          }
        }
      }
    }

    &.article__teaser--no-image {
      .article__teaser__info:not(.article__teaser__info__add) {
        display: flex;
        flex-flow: row;
        line-height: 1.8rem;
        width: calc(100% - 6rem);

        div:not(.article__teaser__column-logo) {
          line-height: 1.8rem;
          order: 2;

          @include breakpoint(tablet) {
            margin-left: 4rem;
          }
        }

        .article__teaser__column-logo {
          display: none;
          height: 3rem;
          margin-right: 1rem;
          order: 1;
          width: 3rem;

          @include breakpoint(tablet) {
            display: block;
          }
        }
      }

      @include breakpoint(tablet) {
        .article__teaser__wrapper {
          .article__teaser__column-logo {
            display: none
          }
        }

        .article__teaser__info {
          .article__teaser__column-logo {
            display: block;
          }
        }
      }
    }

    @include breakpoint(maxTablet) {
      &:not(.article__teaser--small) {
        .grid-cell--t-6 &,
        .grid-cell--d-4 & {
          .article__teaser__headline {
            padding-right: 1rem;
          }
        }
      }

      &.article__teaser--no-image {
        .article__teaser__column-logo {
          margin-top: 1rem;
        }
      }

      .grid-cell--m-12 & {
        .article__teaser__headline {
          margin-top: .5rem;
          width: calc(100% - 7rem);
        }

        .article__teaser__image {
          margin-bottom: 1rem;
        }

        .article__teaser__column-logo {
          top: .6rem;
        }
      }
    }

    @include breakpoint(tablet) {
      .grid-cell--t-6 &,
      .grid-cell--d-4 & {

        .article__teaser__subline {
          //width: calc(100% - 11.1rem);
          margin-bottom: .3rem;
        }

        &.article__teaser--no-image ~ .article__teaser__separator {
          padding-top: 1.6rem;

        }
      }

      .grid-cell--t-12 & {
        position: relative;
        min-height: 21.8rem;

        .article__teaser__info {
          font-size: 1.6rem;
        }

        .article__teaser__column-logo {
          margin-right: 1rem;
        }

        .article__teaser__image {
          height: 19.8rem;
          overflow: hidden;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }

    @include breakpoint(desktop) {
      .grid-cell--d-6 &,
      .grid-cell--d-8 & {
        .article__teaser__info {
          font-size: 1.6rem;
        }
      }

      .grid-cell--d-6 & {

      }

      .grid-cell--d-6 & {
        &:not(.article__teaser--no-image) {
          .article__teaser__wrapper {
            width: 100%;
          }

          .article__teaser__headline {
            width: calc(100% - 7rem);
            margin-right: 1rem;
          }
        }
      }

      .grid-cell--d-8 & {
        position: relative;
        min-height: 21.8rem;

        .article__teaser__image {
          height: 19.8rem;
          overflow: hidden;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }

    @include breakpoint(tablet) {
      // 2 Rows
      .grid-cell--t-12 &,
      .grid-cell--d-8 & {
        &.article__teaser,
        &.article__teaser--small {
          grid-template-rows: min-content auto auto auto;
          .article__teaser__wrapper {
            grid-row-start: 2;
            grid-row-end: 3;
            width: 100%;
          }

          .article__teaser__headline {
            grid-row-start: 1;
            grid-row-end: 1;
            grid-column-start: 1;
            grid-column-end: 3;
            width: 100%
          }

          .article__teaser__column-logo {
            grid-row-start: 2;
            grid-row-end: 2;
            grid-column-start: 1;
            grid-column-end: 2;
            top: -21px;
            left: -1px;
          }

          .article__teaser__info--col-2-row--show {
            display: block;
            font-size: 1.6rem;
            height: 6rem;
            line-height: 2rem;
            margin-left: 4px;
            max-width: calc(100% - 7rem);
            order: 5;
            position: relative;

            div {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          .article__teaser__info--col-2-row--hide {
            display: none;
          }

          .article__teaser__info__add {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 3;
            grid-row-end: 4;
          }

          .article__teaser__info:not(.article__teaser__info__add) {
            grid-row-start: 4;
            grid-row-end: 5;
            grid-column-start: 1;
            grid-column-end: 2;
          }

          .article__teaser__image {
            width: 100%;
          }

          .article__teaser__separator {
            grid-row-start: 6;
            grid-row-end: 6;
            grid-column-start: 1;
            grid-column-end: 3;
          }
        }
      }
    }
  }
}