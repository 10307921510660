.quote {
  padding: 0 .1rem 1.5rem .4rem;
  margin-top: 5.8rem;

  @include breakpoint(tablet) {
    padding-bottom: 2.6rem
  }

  &:before {
    bottom: -30px;
    content: "”";
    display: block;
    font-size: 8rem;
    left: .2rem;
    line-height: 2.7rem;
    margin-bottom: 1.6rem;
    margin-top: 1rem;
    position: relative;
    @extend .base-font;
    @extend .w-900;
    @extend .button-hover-color;
  }

  .quote-content {
    font-size: 2.4rem;
    line-height: 3.3rem;

    @include breakpoint(tablet) {
      line-height: 3.6rem
    }
  }

  .quotee,
  .quotee-function {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  &.no-quotee {
    padding-bottom: 1.1rem;
  }
}