:root {
  --egy-palette-default-color: #2A2A2A;
  --egy-palette-primary: #0088CC;
  --egy-shape-border-radius: 4px;
  --egy-typography-font-family: "Source Sans Pro";
  --egy-typography-body-font-size: 15px;
  --egy-typography-button-font-size: 15px;
  --egy-typography-h1-font-size: 25px;
  --egy-typography-h2-font-size: 20px;
  --egy-typography-h3-font-size: 17px;
  --egy-typography-h4-font-size: 15px;
  --egy-typography-paragraph-font-size: 15px;
  --egy-typography-caption-font-size: 15px;
  --egy-typography-chip-font-size: 15px;
}

egy-discussion {
  &::part(commentary-author__disabled) {
    font-size: 18px;
    color: #335f89;
  }

  &::part(commentary-author__link) {
    font-size: 18px;
    color: #335f89;
  }

  &::part(egy-rich-menu-item__title) {
    font-size: 15px;
  }

  &::part(egy-span) {
    font-size: 14px;
  }

  &::part(commentary__content-text) {
    font-size: 16px;
    line-height: 20px;
  }

  &::part(commentary__content-show-more) {
    font-size: 13px;
  }

  &::part(feature-alert) {
    font-size: 15px;
  }

  &::part(commentary__featured-chip) {
    font-size: 15px;
  }

  &::part(egy-form-row__label) {
    font-size: 15px;
  }

  &::part(tombstone__text) {
    font-size: 14px;
  }

  &::part(egy-alert__content) {
    font-size: 15px;
  }

  &::part(load-more) {
    padding-bottom: 15px;
  }

  &::part(no-more-comments) {
    padding: 16px 16px 16px 0px;
  }

  &::part(state-message--log-in-to-comment) {
    display: none;
  }

  &::part(egy-dialog) {
    z-index: 7777777;
  }

  &::part(egy-alert--color-default) {
    background-color: #D4D4D4;
  }

  &::part(egy-popover__background) {
    font-size: 15px;
  } 

  &::part(view-controller__action-count) {
    font-size: 12px;
    background-color: #335f89;
  }

  &::part(preamble) {
    font-size: 17px;
  }

  &::part(no-comments-yet) {
    margin: 15px 0;
  }

  &::part(commentary-author__name) {
    color: #335f89;
  }

  &::part(discussion-navigator__action-count) {
    font-size: 1.1rem;
    background-color: #335f89;
  }

  &::part(commentary-author__current-user-name) {
    color: #335f89;
  }
}

.engagently {
  &--noAccessMessage {
    display: flex;
    flex-direction: column;
    background-color: rgba(42, 42, 42, 0.1);
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 25px;

    &__text {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }

    &__button {
      padding: 15px;
      font-weight: 600;
      color: #fff;
      text-decoration: none;
      border-radius: 5px;
      background-color: #0088CC;
      text-align: center;

      &:visited {
        color: #fff;
      }

      &:hover {
        background-color: #fff;
      }

      &:active {
        color: #fff;
      }
    }
  }

  &--preamble-link {
    color: #0088CC;

    &:visited {
      color: #0088CC;
    }

    &:hover {
      color: #0088CC;
    }

    &:active {
      color: #0088CC;
    }
  }
}

.comment-preview {
  &__count {
    border-bottom: thin dotted $color-silver;
    font-size: 1.9rem;
    line-height: 1.9rem;
    margin-bottom: 2rem;
    padding: 0 0 1.5rem;

    @include breakpoint(tablet) {
      font-size: 2.6rem;
      line-height: 3rem;
      margin-bottom: 3.5rem;
      padding: 0 0 2rem;
    }
  }

  &__button {
    display: flex;
    justify-content: space-around;
    padding-bottom: 2rem;
    width: 100%;
  }
}