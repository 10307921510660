.article__teaser {

  &.with-logo,
  &.with-logo.article__teaser--small:not(.article__teaser--no-image) {
    .interferer-logo {
      background-color: transparent;
      border-radius: 50%;
      display: block;
      height: 2rem;
      min-width: 2rem;
      padding: 0;
      text-align: center;
      width: 2rem;

      .icon {
        position: absolute;
        height: 100%;
        right: 0;
        width: 100%;
      }
    }

    &.article__teaser--no-image {
      .article__teaser__headline {
        width: calc(100% - 4rem);
      }

      .interferer-reduced {
        background-color: transparent;
        display: flex;
        margin-right: 1rem;
        justify-content: space-around;

        .icon {
          height: 3rem;
          width: 3rem;
        }
      }
    }

    .grid-cell--d-4 &:not(.article__teaser--small),
    .grid-cell--t-6 &:not(.article__teaser--small) {
      .interferer-logo {
        width: 3rem;
        min-width: 3rem;
        height: 3rem;
        left: 1rem;
        top: 1rem;

        @include breakpoint(tablet) {
          width: 4rem;
          min-width: 4rem;
          height: 4rem;
        }
      }
    }

    .grid-cell--d-4 &.article__teaser--small,
    .grid-cell--t-6 &.article__teaser--small {
      .interferer-logo {
        top: .5rem;
        left: .5rem;
        width: 3rem;
        min-width: 3rem;
        height: 3rem;
      }
    }

    .grid-cell--d-6 &:not(.article__teaser--small) {
      .interferer-logo {
        bottom: 1.5rem;
        right: 1.5rem;
        width: 3rem;
        min-width: 3rem;
        height: 3rem;

        @include breakpoint(tablet) {
          width: 4.5rem;
          min-width: 4.5rem;
          height: 4.5rem;
        }
      }
    }

    .grid-cell--d-8 & {
      .interferer-logo {
        width: 3rem;
        height: 3rem;
        top: 1rem;
        left: 1rem;
      }
    }

    .m-b-3 &.article__teaser--small {
      .interferer-logo {
        left: 0;
        height: 2rem;
        width: 2rem;
      }
    }

    @include breakpoint(desktop) {
      .grid-cell--d-8 & {
        &.article__teaser + .article__teaser__separator {
          padding-top: 1.5rem;
        }

        .interferer-logo {
          top: 1rem;
          left: 1rem;
          width: 4rem;
          min-width: 4rem;
          height: 4rem;
        }
      }
    }

    &.with-logo__special--klima {
      .interferer-logo {
        .icon {
          height: 120%;
        }
      }
    }
  }
}