.info-box {
  $self: &;

  padding-top: 2.5rem;

  [name="folding-toggle"] {
    display: none;
  }

  &__headline {
    font-size: 1.6rem;
    letter-spacing: .03rem;
    line-height: 1.8rem;
    padding-bottom: 1.1rem;
    text-transform: uppercase;

    @include breakpoint(desktop) {
      font-size: 1.5rem;
    }

    &--inner {
      border-radius: .4rem;
      display: flex;
      justify-content: space-between;
      margin-bottom: .4rem;
      padding: 1.3rem 1.5rem;

      @include breakpoint(desktop) {
        padding: 1.85rem 1.6rem;
      }

      h2 {
        font-size: 1.5rem;
        line-height: 1.8rem;

        @include breakpoint(desktop) {
          font-size: 1.8rem;
        }
      }

      svg {
        fill: $color-pacific-blue;
        position: relative;
        top: .2rem;
      }
    }
  }

  &--heading {
    font-size: 1.7rem;
    font-weight: 900;
    line-height: 2rem;

    @include breakpoint(tablet) {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }

  &__content-wrapper {
    overflow-y: hidden;
  }

  &__content {
    border-radius: .4rem;
    font-size: 1.5rem;
    line-height: 2rem;
    overflow: hidden;
    padding: 1.5rem 1.5rem 2.1rem 1.5rem;

    @include breakpoint(desktop) {
      font-size: 1.6rem;
      line-height: 2.2rem;
      padding-bottom: 3.6rem;
    }

    //p {
    //  word-break: break-all;
    //}

    ul,
    ol {
      margin-top: 0;

      li {
        margin-bottom: 0;
        margin-left: 1rem;
      }
    }

    ._3q-video {
      margin-top: 1.8rem;

      @include breakpoint(tablet) {
        margin-top: 3.8rem;
      }
    }

    figure:not(.banner) {
      margin: 0;

      .picture__caption {
        @include breakpoint(maxTablet) {
          padding-top: .3rem;
        }
      }
    }

    .lightbox,
    .lightbox:not(.gallery) {
      margin: 1.8rem 0 3rem 0;
      max-width: 100%;

      .lightbox__single-image__content__description-wrapper {
        margin-left: 0;
        margin-right: 0;
      }

      @include breakpoint(tablet) {
        margin: 3.8rem 0 3rem 0;
      }
    }

    &>p {
      margin: 1.6rem 0;
    }
  }

  #{$self}__content-wrapper {
    transition: max-height .8s ease-in-out;
  }

  input[type=checkbox]:checked {
    & ~ #{$self}__headline--inner {
      svg {
        transform: rotate(-90deg);
      }
    }

    & ~ #{$self}__content-wrapper {
      max-height: 1000rem;
    }

    //& ~ #{$self}__content-wrapper > #{$self}__content:not(.no-anim) {
    //  animation: .5s ease-in-out forwards foldDown;
    //}
  }

  input[type=checkbox]:not(:checked) {
    & ~ #{$self}__headline--inner {
      svg {
        transform: rotate(90deg);
      }
    }

    & ~ #{$self}__content-wrapper {
      max-height: 0;
    }
  }

  &.foldable {
    #{$self}__headline--inner {
      background-color: $color-pacific-blue-20;
      display: block;
      padding: 0;

      label {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
        padding: 1.3rem 1.5rem;
        width: 100%;

        @include breakpoint(desktop) {
          padding: 1.85rem 1.5rem;
        }
      }
    }
  }

  //@keyframes foldUp {
  //  from {
  //    display: block;
  //    max-height: 10000rem;
  //    transform: translateY(0);
  //  }
  //
  //  50% {
  //    max-height: 50%
  //  }
  //
  //  99% {
  //    display: none;
  //    max-height: 0;
  //  }
  //
  //  to {
  //    display: none;
  //    max-height: 0;
  //    padding-top: 0;
  //    padding-bottom: 0;
  //    transform: translateY(-100%);
  //  }
  //}

  //@keyframes foldDown {
  //  from {
  //    display: none;
  //    max-height: 0;
  //    //padding: inherit;
  //    transform: translateY(-100%);
  //  }
  //
  //  100% {
  //    display: block;
  //    max-height: 10000rem;
  //    transform: translateY(0);
  //  }
  //}

  .noz-audio-v2 {
   background-color: #fff !important;
  }
}