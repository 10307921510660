.social-share {
  align-items: center;
  border-bottom: .5rem solid #f2f2f2;
  border-top: .5rem solid #f2f2f2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding: 1.35rem 0;

  @include breakpoint(tablet) {
    margin-right: -6rem;
    margin-left: -6rem;
  }

  @include breakpoint(desktop) {
    margin: 0 -10.5rem;
  }

  .hide__mobile {
    display: none;

    @include breakpoint(desktop) {
      display: block;
    }
  }

  @include breakpoint(desktop) {
    flex-wrap: nowrap;
  }

  &__external {
    border-bottom: thin solid $color-white-smoke-1;
    display: flex;
    justify-content: space-between;
    padding: 0 1.5rem 1rem 1.5rem;
    width: 100%;

    @include breakpoint(tablet) {
      padding-left: 6rem;
      padding-right: 6rem;
    }

    @include breakpoint(desktop) {
      border: unset;
      justify-content: normal;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-bottom: 0;
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: nowrap;
  }

  &__internal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1.5rem 0;
    width: 100%;

    @include breakpoint(tablet) {
      padding: 1.5rem 6rem 0;
    }

    @include breakpoint(desktop) {
      justify-content: flex-end;
      padding: 0 1.5rem;
    }
  }

  &__text {
    font-size: 1.8rem;
    line-height: 3.27rem;
    min-width: 8.8rem;

    @include breakpoint(desktop) {
      font-size: 1.6rem;
      line-height: 4.1rem;
      margin-right: 1.5rem;
    }
  }

  &__button {
    $p: &;
    margin: 0 .69rem;
    height: 3.3rem;
    width: 3.3rem;

    @include breakpoint(desktop) {
      height: 4.1rem;
      margin: 0 .835rem;
      width: 4.1rem;
    }

    &--feedback {
      padding: .8rem 1.3rem 1rem 1.4rem;
    }

    &--svg {
      height: 3.3rem;
      width: 3.3rem;

      @include breakpoint(desktop) {
        height: 4.1rem;
        margin: 0;
        width: 4.1rem;
      }
    }

    @include a-pseudo-colors(#{$p}--fb, #{$color-fb-blue});
    @include a-pseudo-colors(#{$p}--twitter, #{$color-twitter-blue});
    @include a-pseudo-colors(#{$p}--whatsapp, #{$color-whatsapp-green});
    @include a-pseudo-colors(#{$p}--email, #{$color-white});
    @include a-pseudo-colors(#{$p}--print, #{$color-white});

    &--print {
      svg {
        width: 1.7rem;
        height: 1.27rem;

        @include breakpoint(desktop) {
          height: 2.8rem;
          width: 2.8rem;
          margin: .7rem 0 0 .7rem;
        }
      }
    }

    &--email {
      height: 3.3rem;
      width: 3.3rem;

      svg {
        width: 1.7rem;
        height: 1.27rem;
        margin-left: .8rem;

        @include breakpoint(desktop) {
          height: 2.1rem;
          width: 2.1rem;
          margin: .6rem 0 0 1rem;
        }
      }
    }
  }

  &--text-link {
    font-size: 1.6rem;
    line-height: 2.8rem;

    @include breakpoint(desktop) {
      font-size: 1.6rem;
      margin-left: 2rem;
    }

    .icon--arrow {
      margin: 1.4rem 0 0 .9rem;
    }
  }
}