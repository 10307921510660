.m {
  &-t {
    &-0 {
      margin-top: 0;
    }

    &-05 {
      margin-top: .5rem;
    }

    &-08 {
      margin-top: .8rem;
    }

    &-1 {
      margin-top: 1rem;
    }

    &-12 {
      margin-top: 1.2rem;
    }

    &-13 {
      margin-top: 1.3rem;
    }

    &-15 {
      margin-top: 1.5rem;
    }

    &-2 {
      margin-top: 2rem;
    }

    &-21 {
      margin-top: 2.1rem;
    }

    &-3 {
      margin-top: 3rem;
    }

    &-4 {
      margin-top: 4rem;
    }

    &-auto {
      margin-top: auto;
    }
  }

  &-r {
    &-0 {
      margin-right: 0;
    }

    &-05 {
      margin-right: .5rem;
    }

    &-1 {
      margin-right: 1rem;
    }

    &-2 {
      margin-right: 2rem;
    }

    &-14 {
      margin-right: 1.4rem;
    }

    &-16 {
      margin-right: 1.6rem;
    }

    &-4 {
      margin-right: 4rem;
    }
  }

  &-b {
    &-0 {
      margin-bottom: 0;
    }

    &-02 {
      margin-bottom: .2rem;
    }

    &-04 {
      margin-bottom: .4rem;
    }

    &-05 {
      margin-bottom: 0.5rem;
    }

    &-1 {
      margin-bottom: 1rem;
    }

    &-12 {
      margin-bottom: 12rem;
    }

    &-15 {
      margin-bottom: 1.5rem;
    }

    &-2 {
      margin-bottom: 2rem;
    }

    &-25 {
      margin-bottom: 2.5rem;
    }

    &-3 {
      margin-bottom: 3rem;
    }

    &-35 {
      margin-bottom: 3.5rem;
    }

    &-4 {
      margin-bottom: 4rem;
    }
  }

  &-l {
    &-0 {
      margin-left: 0rem;
    }

    &-02 {
      margin-left: 0.2rem;
    }

    &-1 {
      margin-left: 1rem;
    }

    &-2 {
      margin-left: 2rem;
    }

    &-a {
      margin-left: auto;
    }
  }

  &-x {
    &--auto {
      margin-left: auto;
      margin-right: auto;
    }

    @include breakpoint(tablet) {
      &-t--auto {
        margin-left: auto;
        margin-right: auto;
      }
    }

    @include breakpoint(desktop) {
      &-d--auto {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.p {

  &-1 {
    padding: 1rem;
  }

  &-medium {
    padding: 0.9rem 1rem;
  }

  &-t {
    &-05 {
      padding-top: .5rem;
    }

    &-1 {
      padding-top: 1rem;
    }

    &-13 {
      padding-top: 1.3rem;
    }

    &-15 {
      padding-top: 1.5rem;
    }

    &-2 {
      padding-top: 2rem;
    }

    &-25 {
      padding-top: 2.5rem;
    }

    &-35 {
      padding-top: 3.5rem;
    }

    &-49 {
      padding-top: 4.9rem;
    }
  }

  &-r {
    &-05 {
      padding-right: 0.5rem;
    }

    &-1 {
      padding-right: 1rem;
    }

    &-15 {
      padding-right: 1.5rem;
    }

    &-2 {
      padding-right: 2rem;
    }

    &-25 {
      padding-right: 2.5rem;
    }
  }

  &-b {
    &-0 {
      padding-bottom: 0;
    }

    &-04 {
      padding-bottom: .4rem;
    }

    &-05 {
      padding-bottom: .5rem;
    }

    &-1 {
      padding-bottom: 1rem;
    }

    &-12 {
      padding-bottom: 1.2rem;
    }

    &-13 {
      padding-bottom: 1.3rem;
    }

    &-2 {
      padding-bottom: 2rem;
    }

    &-24 {
      padding-bottom: 2.4rem;
    }

    &-25 {
      padding-bottom: 2.5rem;
    }

    &-4 {
      padding-bottom: 4rem;
    }

    &-5 {
      padding-bottom: 5rem;
    }
  }

  &-l {
    &-1 {
      padding-left: 1rem;
    }

    &-15 {
      padding-left: 1.5rem;
    }

    &-2 {
      padding-left: 2rem;
    }

    &-25 {
      padding-left: 2.5rem;
    }
  }
}