.lightbox {
  display: flex;
  margin-left: -1.5rem;
  margin-right: -1.5rem;

  @include breakpoint(tablet) {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  @include breakpoint(desktop) {
    margin-left: -10.5rem;
    margin-right: -10.5rem;
  }

  &:not(.gallery) {
    margin: 0 auto;
    max-width: 72.4rem;
  }

  &__single-image {
    width: 100%;

    &__content {
      display: flex;
      flex-direction: column-reverse;

      &__description-wrapper {
        line-height: 2.2rem;
        margin-top: .3rem;

        @include breakpoint(tablet) {
          line-height: 2rem;
          margin-top: 1rem;
        }

        &__description {
          font-size: 1.6rem;

          @include breakpoint(tablet) {
            font-size: 1.5rem;
          }
        }

        &__tag {
          font-size: 1.2rem;
          text-align: right;

          @include breakpoint(tablet) {
            display: inline;
            font-size: 1.4rem;
          }
        }
      }

      &__image-wrapper {
        position: relative;
        height: auto;
        text-align: center;
        width: 67vw;

        &__img {
          width: 100%;
        }

        &__icon {
          display: none;
          background-color: rgba($color: $color-nero, $alpha: 0.3);
          cursor: pointer;
          height: 2.8rem;
          bottom: .9rem;
          right: .9rem;
          position: absolute;
          z-index: 1000;
          width: 2.8rem;

          @include breakpoint(desktop) {
            display: block;
          }
        }
      }
    }
  }

  &:not(.gallery) {
    .lightbox__single-image__content__image-wrapper {
      width: inherit;
    }
  }

  &__gallery {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: 100%;

    @include breakpoint(tablet) {
      padding-left: 6rem;
      padding-right: 6rem;
    }

    @include breakpoint(desktop) {
      padding-left: 10.5rem;
      padding-right: 10.5rem;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 2rem 0 0 0;

      @include breakpoint(tablet) {
        margin-left: auto;
        margin-right: auto;
        max-width: 72.4rem;
      }

      &__description-wrapper {
        font-size: 1.4rem;
        line-height: 1.9rem;
        margin: 0 1.5rem .9rem 1.5rem;
        width: 100%;

        @include breakpoint(tablet) {
          font-size: 2rem;
          line-height: 2.8rem;
          margin: 0 0 2.3rem 0;
        }

        &__image-counter {
          font-size: 1.8rem;

          @include breakpoint(tablet) {
            font-size: 2rem;
          }
        }
      }

      &__image-wrapper {
        position: relative;
        width: 100%;

        @include breakpoint(desktop) {
          width: 120%;
        }

        &__icon {
          background-color: rgba($color: $color-nero, $alpha: 0.3);
          cursor: pointer;
          display: none;
          height: 2.8rem;
          margin-top: -3.7rem;
          right: .9rem;
          position: absolute;
          width: 2.8rem;
          z-index: 1000;

          @include breakpoint(desktop) {
            display: block;
          }
        }
  
        &__tag {
          font-size: 1rem;
          line-height: 1.9rem;
          margin: 0.2rem 1.5rem 1.95rem 0;
          text-align: right;
  
          @include breakpoint(tablet) {
            margin: 0.5rem 0 3.25rem 0;  
          }
        }

        .picture__wrapper {
          text-align: center;
        }
      }

      &__line {
        border-bottom: .1rem dotted $color-dim-gray;
        width: 90%;

        @include breakpoint(desktop) {
          width: 120%;
        }
      }
    }
  }

  &--enabled {
    position: fixed;
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1001;
    opacity: 0;
    pointer-events: none;
    display: none;
    top: 0;
    left: 0;

    @include breakpoint(desktop) {
      display: block;
    }

    &__container {
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      position: relative;
      margin: 0 2.2rem 0 2.2rem;
      
      &__close-btn-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        &__btn {
          display: flex;
          border: none;
          background-color: transparent;
          font-family: inherit;
          padding: 0;
          cursor: pointer;
          margin-top: 2.3rem;

          &__text {
            font-size: 1.4rem;
            line-height: 2rem;
            margin-right: 1.1rem;
          }

          &__icon {
            width: 1.7rem;
            height: 1.7rem;
            fill: #CCD7E1;
            margin-top: 0.2rem;
          }
        }
      }

      &__image-wrapper {
        margin-top: 2.4rem;
        font-size: 1rem;
        line-height: 1.9rem;
        height: 80vh;
        display: flex;
        flex-direction: column;
        width: fit-content;
        align-self: center;

        &__img {
          max-height: 100%;
          object-fit: contain;
        }

        &__description {
          font-size: 1.5rem;
          line-height: 2rem;
          margin-top: 1.3rem;
        }

        .single-image-lightbox-tag {
          font-size: 1.4rem;
          line-height: 2rem;
        }
      }

      &__operations {
        align-self: center;
        display: flex;
        align-items: center;
        flex-direction: row;
        max-width: 104.8rem;
        height: 10rem;
        width: 100%;
        position: absolute;
        bottom: 4rem;

        &__btn {
          cursor: pointer;
          display: block;
          height: 3.2rem;
          top: 2.6rem;
          width: 3.2rem;
        }

        &__description-wrapper {
          font-size: 2rem;
          line-height: 2.8rem;
          margin: 0 13rem 0 13rem;
        }

        .prev-bubble {
          left: 0;
        }

        .next-bubble {
          right: 0;
        }

        .prev-bubble__arrow-left,
        .next-bubble__arrow-right {
          g {
            stroke-width: 2;
          }
        }
      }
    }
  }

  &--enabled.active {
    opacity: 1;
    pointer-events: all;
  }

  .icon {
    left: .19rem;
    height: 2.5rem;
    position: absolute;
    top: .15rem;
    width: 2.5rem;
  }

  .next-bubble__arrow-right,
  .prev-bubble__arrow-left {
    fill: #FFFFFF;
    height: 1.2rem;
    left: 1.2rem;
    margin: .025rem 0 0 0;
    top: 1rem;
    width: .8rem;
  }
}