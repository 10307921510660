@mixin pinShadow {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    box-shadow: 1px 1px 13px 0 rgba(0, 0, 0, 0.07);
  }
}

.quick-menu {
  background-color: $color-white;
  color: $color-nero;
  height: 100vh;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition-property: visiblity;
  transition-duration: $mainNavTiming;
  transition-timing-function: ease-in-out;
  width: 100vw;
  z-index: 1100;

  &:after {
    content: "";
    width: 100%;
    height: 25px;
    display: block;
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    top: 512px;
    left: 0;
    position: fixed;
    z-index: 20;
    @include breakpoint(tablet) {
        display: none;
    }
  }

  @include breakpoint(tablet) {
    display: inherit;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 4rem;
    width: 32rem;
  }

  &__pin {
    background-color: $color-white;
    display: none;

    @include breakpoint(tablet) {
      display: inherit;
      width: 2rem;
      height: 20px;
      position: absolute;
      top: -10px;
      transform: rotate(45deg);

      @include pinShadow;
    }
  }

  &__content {
    background-color: $color-white;
    font-family: $typeface-font;
    font-size: 1.4rem;
    line-height: 1.8rem;
    padding: 25px 15px 25px 15px;
    position: relative;
    height: 480px;
    @include breakpoint(tablet) {
      height: auto;
    }
    overflow-y: scroll;
    overflow-x: hidden;

    @include breakpoint(tablet) {
      width: 320px;
      height: auto;
      overflow-y: unset;
      overflow-x: unset;
      
      @include pinShadow;
    }

    .pin {
      width: 20px;
      height: 20px;
      position: absolute;
      top: -10px;
      right: 30px;
      transform: rotate(45deg);
      background-color: $color-white;

      @include pinShadow;
    }

    label:nth-of-type(2) {
      display: inline-block;
      margin-top: 17px;
    }

    input {
      width: 100%;
    }

    button {
      margin-top: 25px;
      color: $color-white;
    }

    .text--center {
      margin: 6px 0 11px 0;
    }

    .user-image {
      width: 75px;
    }

    .product {
      a {
        color: $color-pacific-blue;
        text-decoration: underline;
      }
    }

    p {
      margin: 0;
    }

    &.logged-in p:after {
      content: '';
      border-bottom: 1px solid $color-silver;
      display: inline-block;
      margin: 2.4rem -1.5rem 2.4rem -1.5rem;
      width: 120%;

      @include breakpoint(tablet) {
        margin: 24px 0;
        width: 100%;
      }
    }
  }

  .main-header .lower-part & .personal-menu ul {
    display: inherit;

    li {
      text-align: left;
    }

    @for $i from 0 through $mainNavLiCount {
      & > li:nth-child(#{$i}) {
        transform: translateX(calc(#{$mainNavLiOffsetRight} * #{$i}));

        @include breakpoint(tablet) {
          transform: none;
        }
      }
    }
  }
}