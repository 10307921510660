.timeline {
  @include breakpoint(tablet) {
    .content--group__section > & {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  @include breakpoint(maxTablet) {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .box-name {
    font-size: 1.6rem;
    line-height: 1.8rem;
    margin-bottom: 3px;
    padding: 1.2rem 0 1.3rem 1rem;

    @include breakpoint(tablet) {
      padding-left: 1.7rem;
    }

    @extend .bg-color__alice-blue-100;
  }

  .slider {
    border-bottom: none;
    @extend .bg-color__alice-blue-100;

    &__elements {
      margin: 1.75rem 1.05rem 1.45rem 0;
      padding-bottom: 1.25rem;

      @include breakpoint(tablet) {
        flex-direction: inherit;
        margin-left: 1.7rem;
      }
    }

    &__element {
      margin-left: 0;
      width: 25.4rem;

      &:first-of-type {
        margin-left: 1.7rem;
        scroll-margin-left: 1.7rem;
      }

      p {
        padding-right: 2.2rem;
      }

      @include breakpoint(maxTablet) {
        scroll-margin-left: 1rem;

        &:first-of-type {
          margin-left: 1rem;
          scroll-margin-left: 1rem;
        }
      }

      &:after {
        content: "";
        height: 75%;
        width: 1px;
        right: 0;
        top: 0;
        margin: 2rem 1.15rem 2rem 1.2rem;
        @extend .d-block;
        @extend .position-absolute;
        @extend .border-dotted-r;
        @extend .border-color__silver-100;
      }

      &:last-child:after {
        border: unset;
      }

      .date {
        font-size: 1.4rem;
        line-height: 1.8rem;
        margin-top: 1.45rem;

        &:before {
          background-color: $color-prussian-blue;
          content: "";
          height: .9rem;
          width: .9rem;
          margin: .5rem .4rem 0 0;
          @extend .d-block;
          @extend .float--l;

          @extend .border-round;
        }
      }

      .kicker {
        font-size: 1.5rem;
        line-height: 1.8rem;
      }

      .headline {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }

  .slider-box__more {
    display: none;
  }

  .three-dots {
    margin: 1.6rem auto 1.9rem auto;
  }
}