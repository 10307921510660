h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
    margin: 0;
    font-weight: bold;
    font-family: $base-font;
}

h1,
.h1 {
    font-size: 2.7rem;
    line-height: 3.3rem;

    @include breakpoint(tablet) {
        font-size: 3.6rem;
        line-height: 4.8rem;
    }

    @include breakpoint(desktop) {
        font-size: 4.8rem;
        line-height: 5.4rem;
    }
}

h2,
.h2 {
    font-size: 2.4rem;
    line-height: 2.8rem;

    @include breakpoint(tablet) {
        font-size: 3.4rem;
        line-height: 4rem;
    }

    @include breakpoint(desktop) {
        font-size: 4.2rem;
        line-height: 4.8rem;
    }
}

h3,
.h3 {
    font-size: 2.1rem;
    line-height: 2.5rem;

    @include breakpoint(tablet) {
        font-size: 2.8rem;
        line-height: 3.2rem;
    }

    @include breakpoint(desktop) {
        font-size: 3.4rem;
        line-height: 4rem;
    }
}

h4,
.h4 {
    font-size: 2.3rem;
    line-height: 2.6rem;

    @include breakpoint(tablet) {
        font-size: 2.4rem;
        line-height: 2.8rem;
    }

    @include breakpoint(desktop) {
        font-size: 2.6rem;
        line-height: 3rem;
    }
}

h5,
.h5 {
    font-size: 1.6rem;
    line-height: 2rem;

    @include breakpoint(tablet) {
        font-size: 1.8rem;
        line-height: 2.2rem;
    }

    @include breakpoint(desktop) {
        font-size: 2.2rem;
        line-height: 2.6rem;
    }
}