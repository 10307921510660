.list {
  margin-bottom: 3rem;
  padding-left: 2rem;

  li {
    margin-bottom: 2rem;

    @include breakpoint(tablet) {
      margin-bottom: 2.5rem;
    }
  }

  &-item {
    position: relative;
    padding-left: 2.5rem;
    font-size: 1.6rem;
    line-height: 1.8rem;

    &:before {
      position: absolute;
      display: inline-block;
      top: 0;
      left: 0;
      width: 1.3rem;
      height: 1.3rem;
    }

    &--check:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 70.865 55.026' role='img' aria-labelledby='title desc'%3E%3Cstyle%3E.a%7Bfill:%2308c;%7D%3C/style%3E%3Cpath class='a' d='M27.136,54.372a3.616,3.616,0,0,1-5.116,0L1.59,33.939a5.425,5.425,0,0,1,0-7.674l2.558-2.559a5.426,5.426,0,0,1,7.675,0L24.578,36.462,59.042,2a5.426,5.426,0,0,1,7.675,0l2.558,2.559a5.425,5.425,0,0,1,0,7.674Zm0,0' transform='translate(0 -0.406)'%3E%3C/path%3E%3C/svg%3E");
    }
  }

  &-border-last-item--hide .border-dotted-b:last-child {
    border-bottom: none;
  }

  @include breakpoint(tablet) {
    &-border-last-item-t--hide .border-dotted-b:last-child {
      border-bottom: none;
    }
  }

  &-reset {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}

ol.list {
  margin-left: .5rem;
}
