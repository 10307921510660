.scala-payment {
  line-height: 1.7rem;

  .icon-point-to {
    position: absolute;
    width: 2.6rem;
    height: 2.6rem;
    top: 1.6rem;
    right: 0;
    color: $color-prussian-blue;

    &--tablet {
      top: -3.5rem;
      left: -2.6rem;
      right: auto;
    }
  }

  &--reminder {

    @include breakpoint(tablet) {
      text-align: left;
    }
  }

  .grid {
    flex-flow: initial;
  }

  &--row [class^="grid-cell"] {
    height: 30px;
    flex: 0 0 50vw;
    max-width: 50vw;

    @include breakpoint(tablet) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
  }

  &--divider {
    position: relative;

    &:after {
      position: absolute;
      left: -1px;
      bottom: 0;
      display: block;
      content: "";
      width: 1px;
      height: calc(100% + 10px);
      background-color: $color-prussian-blue;
    }
  }

  &--offset {
    margin-left: -3px;
    @include breakpoint(tablet) {
      margin-left: -1px;
    }
  }
}