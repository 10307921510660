.default-font {
  font-family: $base-font;
}

.blind-text {
  font-size: 1.6rem;
  line-height: 2.2rem;

  @include breakpoint(tablet) {
    font-size: 2rem;
    line-height: 2.8rem;
  }
}

// font styles
.no-decoration {
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

.w-400 {
  font-weight: 400;
}

.w-600 {
  font-weight: 600;
}

.w-900 {
  font-weight: 900;
}

.text-center {
  text-align: center;
}

.text-upper {
  text-transform: uppercase;
}

// line-heights
.lh-22 {
  line-height: 2.2rem;
}