.real-estate-box {
  margin-left: -1rem;
  margin-right: -1rem;

  .box-name {
    font-size: 1.8rem;
    line-height: 1.8rem;
    padding: 1.6rem 0 1.6rem 1.6rem;
    position: relative;

    @include breakpoint(tablet) {
      font-size: 3.4rem;
      line-height: 4.2rem;
      padding: 1.4rem 0 1.4rem 1rem;
    }

    .icon-jobs {
      max-height: 3.2rem;
      max-width: 13rem;
      position: absolute;
      right: 0.6rem;
      top: 50%;
      transform: translateY(-50%);

      @include breakpoint(tablet) {
        max-width: 18rem;
        max-height: 4.4rem;
        right: 1.5rem;
      }
    }
  }

  .slider {
    border-bottom-width: .5rem;

    &__element {
      margin: 1.4rem 1rem 0 0;
      scroll-margin-left: 2rem;
      width: 26rem;

      &:first-of-type {
        margin-left: 1.4rem;
        scroll-margin-left: 1.4rem;

        @include breakpoint(tablet) {
          margin-left: 1rem;
          scroll-margin-left: 1rem;
        }
      }

      @include breakpoint(tablet) {
        margin-top: 2rem;
      }

      img {
        min-height: 19.5rem;
      }

      picture {
        img {
          height: 13.6rem;
        }
      }
    }

    &__element__subline {
      @extend .size--m-5;
      margin-top: .7rem;
    }

    &__element__headline {
      @extend .size--m-6;
      margin-top: .2rem;

      @include breakpoint(tablet) {
        font-size: 1.9rem;
        line-height: 2.3rem;
      }
    }

    &__element__info {
      @extend .size--m-4;
      margin-top: .2rem;

      @include breakpoint(tablet) {
        font-size: 1.5rem;
        line-height: 2.8rem;
      }
    }

    .prev-bubble,
    .next-bubble {
      top: calc(50% - 35px);
    }

    &-box__more {
      right: 1.2rem;
    }
  }
}