.content-element {
  .comment {
    line-height: 2.8rem;
    padding: .9rem .9rem 1.4rem 1.1rem;
    position: relative;

    .text-size-kicker {
      font-size: 1.5rem;
      line-height: 1.8rem;
    }

    @include breakpoint(tablet) {
      font-size: 1.6rem;
      line-height: 2.2rem;
      padding: 1.5rem;
    }

    &__author {
      &:after {
        content: "";
        margin: 1rem 0 1.5rem 0;
        @extend .border-thin;
        @extend .border-dotted-b;
        @extend .border-color__silver-100;
        @extend .d-block;
      }

      font-size: 1.4rem;
      line-height: 1.8rem;
      margin-top: .6rem;

      @include breakpoint(tablet) {
        font-size: 1.5rem;
        margin-top: 1rem;
      }
    }

    &__gradient {
      max-height: 11.5rem;
      overflow: hidden;
      position: relative;

      @include breakpoint(tablet) {
        max-height: 10rem;
      }

      &:after {

        content: "";
        position: absolute;

        @extend .gradient-light-blue-1;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(229, 243, 250, 0.48) 35%, rgba(229, 243, 250, 1) 96%);

        @include breakpoint(tablet) {
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(229, 243, 250, 0.48) 35%, rgba(229, 243, 250, 1) 70%);
        }
      }
    }

    &__title {
      width: calc(100% - 7rem);
      @include breakpoint(tablet) {
        font-size: 2.8rem;
        line-height: 3.5rem;
      }
    }

    &__article-link {
      margin: 1rem 0;
      padding-bottom: 2.6rem;

      @include breakpoint(tablet) {
        margin: 0;

        a {
          padding-right: .5rem;
        }
      }

      .icon--arrow {
        position: relative;
        top: 1.4rem;

        @include breakpoint(tablet) {
          top: 1.2rem;
        }
      }
    }

    &__fill {
      margin-bottom: 1rem;
      margin-top: 1rem;
      text-align: center;

      @include breakpoint(tablet) {
        font-size: 1.5rem;
        margin-bottom: 0;
        margin-top: 0;
      }
    }

    &__arrow-down {
      fill: $color-pacific-blue;
      stroke: $color-pacific-blue;
      stroke-width: 1.5px;
      transform: rotate(90deg);
    }

    .avatar {
      height: 6rem;
      width: 6rem;
    }

    &__label-pro-contra {
      margin: 0;
      cursor: pointer;
    }

    input[name='toggle-button'] {
      display: none;
    }

    input[name='toggle-button']:checked {
      ~ .comment__gradient {
        overflow: visible;
        max-height: none;

        &:after {
          display: none;
        }
      }

      ~ .comment__label-pro-contra {
        display: none;
      }
    }

    &__read-more-text {
      font-size: 1.6rem;
      line-height: 2.2rem;
      position: absolute;
      right: 1.5rem;
      bottom: 1.5rem;
    }

    &__elements-margin > * {
      margin-bottom: 2.5rem;

      @include breakpoint(tablet) {
        margin-bottom: 3rem;
      }
    }
  }
}

