.doi-message {
  display: none;
  background-color: $color-red2;
  color: $color-white;
  font-size: 1.5rem;
  line-height: 2.2rem;
  margin: 0 auto;
  max-width: $max-width_desktop;
  padding: 1.3rem 3rem;
  text-align: center;

  a {
    color: $color-white;
    text-decoration: underline;
  }

  .loader--ellipsis-item {
    background-color: $color-white;
  }

  &.loading {
    position: relative;
  }

  &.loading:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.8);
    top: 0;
    left: 0;
  }
}