.article--type {
  &-event {
    @include breakpoint(tablet) {
      .grid-cell--t-12 & {
        .article__teaser__info {
          font-size: 1.6rem;
        }
      }
    }

    @include breakpoint(desktop) {
      .grid-cell--d-8 & {
        .article__teaser__info {
          font-size: 1.6rem;
        }
      }
    }
  }
}
