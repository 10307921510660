.media-slider-box {
  position: relative;

  .box-name {
    @extend .bg-color__nero-100;
    @extend .text-color__white;
    font-size: 1.8rem;
    line-height: 1.8rem;
    padding: 1.7rem 1rem 1.5rem 1rem;

    @include breakpoint(tablet) {
      background-color: $color-white;
      color: $color-nero;
      font-size: 3.4rem;
      line-height: 3.6rem;
      margin-bottom: .4rem;
      padding: 1.5rem 0 1.5rem 1rem;
    }
  }

  .slider {
    @extend .bg-color__nero-100;
    padding: 1.4rem 0 .5rem 0;

    @include breakpoint(tablet) {
      padding: 2.9rem 2rem .1rem 0;
    }

    &:before,
    &:after {
      @include gradient-nero-transparent-r_l();
    }

    &:before {
      left: 0;
      transform: scaleX(-1);
      z-index: 1;
    }

    &__elements {
      @include breakpoint(tablet) {
        margin-left: unset;
      }

      .prev-bubble,
      .next-bubble {
        top: calc(50% - 4rem);
      }
    }

    &__element {
      margin-right: 1rem;
      width: 26rem;
      scroll-margin: 1rem;

      &:first-of-type {
        @include breakpoint(maxTablet) {
          margin-left: 1rem;
          scroll-margin-left: 1rem;
        }

        @include breakpoint(tablet) {
          margin-left: 2rem;
          scroll-margin-left: 2rem;
        }
      }

      figure.picture__wrapper  {
        min-height: 14.6rem;

        @include breakpoint(tablet) {
          min-height: 16.9rem;
        }
      }

      &__headline {
        line-height: 2rem;

        a {
          color: inherit;
          text-decoration: none;
        }
      }

      @include breakpoint(tablet) {
        width: 30rem;
        margin-right: 3.3rem;

        &__headline {
          font-size: 1.9rem;
          line-height: 2.3rem;

          a {
            color: inherit;
            text-decoration: none;
          }
        }
      }

      &__interferer {
        border-radius: .3rem;
        font-size: 1.4rem;
        line-height: 2.8rem;
        right: .7rem;
        padding: 0 .9rem 0 1rem;
        position: absolute;
        top: 11.1rem;

        @include breakpoint(tablet) {
          font-size: 1.4rem;
          left: unset;
          line-height: 2.8rem;
          padding: 0 .9rem .1rem 1rem;
          right: .7rem;
          top: 13.2rem;
        }

        .icon {
          fill: $color-white;
          height: 1.6rem;
          margin-right: 1rem;
          position: relative;
          top: -.2rem;
          width: 1.8rem;

          &.play-icon {
            height: 1.1rem;
            position: relative;

            @include breakpoint(tablet) {
              top: 0;
            }
          }

          @include breakpoint(tablet) {
            height: 1.6rem;
            margin: 0 .6rem .3rem 0;
            position: unset;
            width: 1.8rem;
          }
        }
      }
    }

    .three-dots {
      margin-bottom: 1rem;

      @include breakpoint(tablet) {
        margin: 3rem auto;
      }
    }
  }

  &__type {
    @extend .text-color__pacific-blue-100;
    @extend .typeface-font;
    margin-top: 1rem;

    @include breakpoint(tablet) {
      font-size: 1.5rem;
      line-height: 1.8rem;
      margin-top: .7rem;
    }
  }

  .slider-box__more {
    padding-bottom: .9rem;
    @extend .bg-color__nero-100;
    @extend .text-color__white;

    @include breakpoint(maxtablet) {
      padding-right: 1rem;
    }

    @include breakpoint(tablet) {
      bottom: 2.6rem;
      padding: unset;
      position: absolute;
      right: 1.2rem;
    }
  }
}