.close-nav {
  &,
  &--desktop {
    background-color: $color-white;
    line-height: 1.8rem;
    text-transform: uppercase;
    padding: 1.9rem 0 1.7rem 1.5rem;
    border-bottom: 0.5px solid $color-silver;
    display: none;

    label {
      margin-bottom: 0;
    }

    &__text {
      margin-right: .7rem;
    }

    @include breakpoint(desktop) {
      line-height: 2rem;
      padding: 2.2rem 0 2.2rem 1.5rem;
      text-transform: none;
    }

    label {
      color: $color-pacific-blue;
      display: flex;
      font-weight: 400;
      cursor: pointer;

      @include breakpoint(desktop) {
        color: $color-white;
        left: 33rem;
        position: absolute;
      }
    }

    .mdiv {
      background-color: $color-link-water;
      display: block;
      height: 1.9rem;
      width: 2px;
      transform: rotate(45deg);
      Z-index: 1;

      @include breakpoint(desktop) {
        background-color: $color-white;
        height: 2rem;
        margin-left: 1.1rem;
      }
    }

    .md {
      display: block;
      height: 1.9rem;
      width: 2px;
      background-color: $color-link-water;
      transform: rotate(90deg);
      Z-index: 2;

      @include breakpoint(desktop) {
        background-color: $color-white;
        height: 2rem;
      }
    }

    label {
      font-size: 1.2rem;
      letter-spacing: .08rem;
      margin-left: .6rem;
      text-decoration: none;

      @include breakpoint(desktop) {
        font-size: inherit;
        letter-spacing: inherit;
      }

      .quick-menu & {
        justify-content: flex-end;
        margin-right: 1.5rem;
      }
    }

  }
}

.close-nav {
  display: inherit;

  @include breakpoint(desktop) {
    display: none;

    &--desktop {
      display: inherit;
      padding: 0;

      label {
        padding: 4rem 0 2.2rem 0;
      }
    }
  }

  .abo-plus-button {
    margin: 0 1.4rem 0 auto;
  }
}
