.pagination {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin: 0 0 1rem 0;
  padding-bottom: 2.6rem;
  text-align: center;

  @include breakpoint(tablet) {
    flex-direction: row;
    font-size: 2rem;
    line-height: 5rem;
    margin: 2rem 0 2rem 0;
    padding-bottom: 6rem;
  }

  @include breakpoint(desktop) {
    justify-content: center;
  }

  &__second-row {
    display: flex;
    flex-direction: row;
    margin: 1.5rem auto;

    @include breakpoint(tablet) {
      flex-grow: 2;
      margin: 0;
    }

    @include breakpoint(desktop) {
      flex-grow: 0;
      justify-content: normal;
    }
  }

  &__first-last,
  &__link {
    text-decoration: none;

    @include breakpoint(desktop) {
      margin: 0;
    }

    &:visited,
    &:hover,
    &:active {
      @extend .text-color__pacific-blue-100;
    }
  }

  &__link {
    margin: 0 1rem 0 0;

    @include breakpoint(tablet) {
      margin: 0 1.5rem 0 0;

      &:first-of-type {
        margin-left: 3rem;
      }

      &:last-of-type {
        margin-right: 3rem;
      }
    }
  }

  &__bubble,
  &__bubble--active {
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    width: 3rem;
    @extend .border-round;

    @include breakpoint(tablet) {
      font-size: 2rem;
      line-height: 5rem;
      height: 5rem;
      width: 5rem;
    }
  }

  &__bubble:not(.pagination__bubble--active) {
    @extend .bg-color__prussian-blue-10;
  }

  &__bubble--active {
    background-color: $color-pacific-blue;
    @extend .text-color__white;
  }
}