select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid $color-prussian-blue-20;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" transform="rotate(90)"  fill="%230088CC" stroke-linejoin="round" viewBox="0 0 17 32" role="img" aria-labelledby="title desc">  <path stroke="%230088CC" stroke-width="1" d="M17.029 14.971l-14.542-14.546c-0.263-0.263-0.627-0.426-1.029-0.426-0.803 0-1.455 0.651-1.455 1.455 0 0.402 0.163 0.765 0.426 1.029l13.513 13.518-13.513 13.513c-0.263 0.263-0.426 0.627-0.426 1.029 0 0.803 0.651 1.455 1.455 1.455 0.402 0 0.765-0.163 1.029-0.426l14.546-14.546c0.263-0.263 0.426-0.627 0.426-1.029s-0.163-0.765-0.426-1.029v0z" stroke-linecap="round"></path></svg>');
  background-repeat: no-repeat;
  background-color: $color-white;
  background-position: calc(100% - 1.5rem) 50%;
  background-size: 1.5rem 1.5rem;
  text-overflow: ellipsis;
  font-size: 1.4rem;
  line-height: 1.8rem;
}