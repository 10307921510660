@media all and (-ms-high-contrast: none) {
  //.beispiel { color: green } /* IE10 */
  //*::-ms-backdrop, .beispiel { color: red } /* IE11 */

  *::-ms-backdrop, .main-navigation nav .nav-item-childs {
    width: calc(100% - 4.9rem);
  }

  *::-ms-backdrop, .main-header .lower-part ul {
    margin: 0;
  }

  *::-ms-backdrop, .main-header .lower-part .navigation .no-relative li {
    background-color: transparent!important;
  }

  *::-ms-backdrop, .switch-box .switch-box__switch {
    width: 26.5rem;
  }

  *::-ms-backdrop, .section-heading__links {
    margin-bottom: auto;
    width: auto!important;
  }

  *::-ms-backdrop, .weather-box__content {
    display: flex;

  }

  *::-ms-backdrop, .weather-box__content .weather-box__content__details {
    width: 80%;
  }
}
