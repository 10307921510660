.author-overview-element {
    margin-bottom: 1.4rem;

    @include breakpoint(tablet) {
        margin-bottom: 1.9rem;
    }

    &__link {
        display: flex;
        align-items: center;

        &__img {
            &__atom {
                min-width: 7.8rem;
                min-height: 7.8rem;
            }
        }
        
        &__info {
            margin-left: 1.2rem;
            font-size: 1.6rem;

            @include breakpoint(tablet) {
                margin-left: 1.5rem;
            }

            &__name {
                line-height: 2rem;

                @include breakpoint(tablet) {
                    font-size: 2.2rem;
                    line-height: 2.6rem;
                }
            }

            &__function {
                line-height: 2.2rem;

                @include breakpoint(tablet) {
                    font-size: 1.8rem;
                    line-height: 2.8rem;
                }
            }
        }
    }
}