.ranking-table {
  border-collapse: collapse;
  font-size: 1.2rem;
  line-height: 1.8rem;
  width: calc(100% - 2rem);
  margin: 0 .95rem 0 1rem;

  @include breakpoint(tablet) {
    width: calc(100% - 2.2rem);
  }

  &__th {
    font-size: 1.2rem;
    text-align: center;

    @include breakpoint(tablet) {
      font-size: 1.4rem;
    }
  }

  &__header {
    font-size: 1.4rem;
    line-height: 3rem;

    @include breakpoint(tablet) {
      line-height: 2.9rem;
    }
  }

  &__team-th {
    text-align: left;
    padding-left: 2.3rem;

    @include breakpoint(tablet) {
      display: none;
    }

    &--desktop {
      display: none;

      @include breakpoint(tablet) {
        display: table-cell;
      }
    }
  }

  &__place-th {
    display: none;

    @include breakpoint(tablet) {
      display: table-cell;;
    }
  }
}