.loader {

  &--ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 15px;

    &-item {
      position: absolute;
      top: 3px;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: $color-nero;
      -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    &-item:nth-child(1) {
      left: 8px;
      -webkit-animation: loader-ellipsis1 .6s infinite;
      animation: loader-ellipsis1 .6s infinite;
    }

    &-item:nth-child(2) {
      left: 8px;
      -webkit-animation: loader-ellipsis2 .6s infinite;
      animation: loader-ellipsis2 .6s infinite;
    }

    &-item:nth-child(3) {
      left: 32px;
      -webkit-animation: loader-ellipsis2 .6s infinite;
      animation: loader-ellipsis2 .6s infinite;
    }

    &-item:nth-child(4) {
      left: 56px;
      -webkit-animation: loader-ellipsis3 .6s infinite;
      animation: loader-ellipsis3 .6s infinite;
    }
  }
}

@-webkit-keyframes loader-ellipsis1 {

  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes loader-ellipsis1 {

  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes loader-ellipsis3 {

  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes loader-ellipsis3 {

  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@-webkit-keyframes loader-ellipsis2 {

  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(24px, 0);
    transform: translate(24px, 0);
  }
}

@keyframes loader-ellipsis2 {

  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  100% {
    -webkit-transform: translate(24px, 0);
    transform: translate(24px, 0);
  }
}

.loading-layer {
  position: relative;

  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-bright-transparent;
    z-index: 5;
  }

  [class*="loader"] {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
  }

  .loader--ellipsis {
    margin-top: -10px;
    margin-left: -40px;

    &-item {
      background-color: $color-pacific-blue;
    }
  }
}

//.menu {
//  float: left;
//  margin: 1.6rem 0 1.5rem 1rem;
//  display: inline-block;
//
//  @include breakpoint(tablet) {
//    margin-top: 1.3rem;
//  }
//
//  .menu-strokes {
//    display: block;
//    width: 14px;
//    height: 2px;
//    margin: .4rem 0;
//    background-color: $color-silver;
//
//    &:nth-child(2) {
//      width: 10px;
//    }
//  }
//}