.article--type {
  &-comment {
    &:not(.article__teaser--opener) {
      flex-flow: row;
      flex-wrap: wrap;

      .article__teaser__image {
        width: 100%;
      }

      .article__teaser__headline {
        font-family: $base-font;
        font-style: italic;
        font-weight: normal;
        width: 100%;
      }

      .article__teaser__info {
        img {
          margin: .5rem .8rem 0 0;
          max-height: 3rem;
        }
      }

      &:not(.article__teaser--no-image) {
        .article__teaser__headline {
          width: calc(100% - 6.5rem);

          @include breakpoint(tablet) {
            width: calc(100% - 7.5rem);
          }
        }
      }

      .article__teaser__separator {
        width: 100%;
      }

      .article__teaser__wrapper {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-right: 0;
      }

      //&.with-logo {
      //  .article__teaser__wrapper {
      //    justify-content: space-around;
      //  }
      //}

      &.article__teaser--small {
        .article__teaser__subline {
          order: 1;
          padding-bottom: .1rem;
        }

        .article__teaser__headline {
          order: 2;
          width: 100%;
        }

        .article__teaser__info {
          display: flex;
          flex-flow: row;
          line-height: 1.8rem;
          margin-top: 1rem;
          order: 4;
          width: 100%;

          @include breakpoint(maxTablet) {
            margin-top: .2rem;
          }

          div:last-child {
            left: 3.8rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        &.article__teaser--no-image {
          .article__teaser__info {
            div:last-child {
              left: 0;
            }
          }
        }

        .article__teaser__image {
          height: 6.706rem;
          order: 2;
          width: 9rem;

          @include breakpoint(tablet) {
            width: 12rem;
          }
        }


        .article__teaser__author-img {
          display: inherit;
          height: 3rem;
          margin: 0 1rem 1rem 0;
          order: unset;
          width: 3rem;
        }

        .article__teaser__info--pros-cons {
          .article__teaser__author-img {
            width: 4rem;
            height: 4rem;
            margin-bottom: 0;

            .author-img {
              max-height: none;
              width: 25px !important;
              height: 25px !important;
              margin: 0;
            }
          }
          div:last-child {
            left: 5rem;
          }
        }
      }

      @include breakpoint(tablet) {
        &.open {
          &.article__teaser--no-image {
            & > .article__teaser__info {
              div:last-child {
                margin-left: 5rem;
              }
            }
          }
        }
      }

      &.article__teaser--no-image {
        & > .article__teaser__info {
          display: none;

          @include breakpoint(tablet) {
            display: inherit;

            div:last-child {
              margin-left: 3.8rem;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }

            .article__teaser__author-img {
              order: 0;
            }

            &.article__teaser__info--pros-cons div:last-child {
              margin-left: 4.5rem;
            }
          }
        }

        .article__teaser__wrapper {
          .article__teaser__headline {
            width: calc(100% - 7rem);
          }

          .article__teaser__author-img {
            display: inherit;
            height: 6rem;
            order: 4;
            width: 6rem;

            @include breakpoint(tablet) {
              display: none;
            }
          }

          .article__teaser__info {
            display: inherit;
            width: calc(100% - 7rem);

            @include breakpoint(tablet) {
              display: none;
            }
          }
        }

        &.with-logo {
          .article__teaser__wrapper {
            justify-content: space-around;
          }
        }

        .article__teaser__info {
          width: calc(100% - 4rem);

          div:last-child {
            line-height: 1.8rem;
            //padding: .7rem 0 0 .2rem;

            svg {
              margin-left: -1.5rem;
              position: relative;
              top: .2rem;
            }
          }

          .article__teaser__author-img {
            display: none;

            @include breakpoint(tablet) {
              display: inherit;
            }
          }
        }

        .article__teaser__author-img {
          float: left;
          height: unset;
          width: unset;
        }

        .article__teaser__info > span:not(.free-content) {
          display: inline-block;
          margin-top: 1rem;
          @include breakpoint(tablet) {
            margin-top: .6rem;
          }
        }
      }

      @include breakpoint(tablet) {
        .grid-cell--t-12 & {
          .article__teaser__headline {
            width: 100%;
          }

          .article__teaser__info {
            font-size: 1.6rem;
            width: calc(100% - 8rem);
          }

          .article__teaser__wrapper .article__teaser__author-img {
            margin-right: 1.6rem;
            margin-top: unset;
            top: 0;
          }
        }
      }

      @include breakpoint(maxTablet) {
        &:not(.article__teaser--no-image):not(.article__teaser--small) {
          .article__teaser__subline {
            margin-top: .3rem;
          }

          .article__teaser__headline {
            margin-top: .2rem;
          }

          .article__teaser__author-img {
            margin-top: .8rem;
          }
        }
      }

      @include breakpoint(desktop) {
        .grid-cell--d-4 & {
          .article__teaser__info {
            margin-top: 0;
          }

          &.article__teaser--small {
            & ~ .article__teaser__separator {
              padding-top: 1.3rem;
            }
          }

          &.article__teaser--no-image {
            .article__teaser__subline {
              margin-bottom: .6rem;
            }

            & ~ .article__teaser__separator {
              padding-top: 1.6rem;
            }

            .article__teaser__headline {
              margin-bottom: 5px;
              margin-top: -7px;
              padding-bottom: .2rem;
            }

            .article__teaser__info {
              img {
                margin: auto .8rem 0 0;
                max-height: 3rem;
              }
            }
          }
        }

        .grid-cell--d-6 & {
          .article__teaser__headline {
            padding: 0;
          }

          .article__teaser__info {
            padding: 1.3rem 0;
          }

          .article__teaser__author-img {
            height: 64px;
            margin-right: 1px;
            margin-top: -3px;
            width: 64px;
          }
        }

        .grid-cell--d-8 & {
          &:not(.article__teaser--opener) {
            .article__teaser__subline {
              margin-bottom: .4rem;
            }

            .article__teaser__headline {
              padding-bottom: 1.7rem;
              width: 100%;
            }

            .article__teaser__info {
              font-size: 1.6rem;
              left: -.4rem;
              position: relative;
            }

            .article__teaser__author-img {
              margin-right: 1.4rem;
              margin-top: unset;
            }

            & ~ .article__teaser__separator {
              padding-top: 1.6rem;
            }
          }
        }
      }

      .grid-cell--d-8 & + .article__teaser__separator {
        padding-top: 2rem;
      }
    }

    .article__teaser__author-img {
      position: relative;
      display: inherit;
      height: 6rem;
      width: 6rem;
      order: 5;

      .author-img {
        position: absolute;
        width: 40px !important;
        height: 40px !important;

        &--1 {
          top: 0;
          right: 0;
          z-index: 1;
        }
        &--2 {
          bottom: 0;
          left: 0;
          z-index: 2;
        }
      }
    }
  }
}

@include breakpoint(tablet) {
  .grid-cell--t-12,
  .grid-cell--d-8 {
    .article--type-comment:not(.article__teaser--opener) {
      &.article__teaser--small:not(.article__teaser--no-image) {
        .article__teaser__wrapper,
        .article__teaser__info {
          width: 100%;
        }
      }

      .article__teaser__info {
        div:last-child {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}