.horoscope-widget {
  position: relative;

  &__nav {
    font-size: 1.5rem;
    line-height: 2.6rem;
    padding: .95rem;
    position: relative;
    text-align: center;

    &--day {
      cursor: pointer;
      margin: 0 4.1rem 0 0;
      padding-bottom: 1rem;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    .border-decoration {
      border-bottom: solid .3rem $color-deep-sky-blue;
      bottom: 0;
      min-width: 4rem;
      position: absolute;
      transition: all .5s ease-in-out;
    }
  }

  &__day {
    display: none;
    padding: 0 1rem 6rem 1rem;
    position: relative;
    z-index: 0;

    svg {
      display: block;
    }

    @include breakpoint(tablet) {
      padding: 0 2rem 5.5rem;
    }

    &.active {
      display: block;
      z-index: 1;
    }

    .sign {
      margin-top: 3rem;
      text-align: center;

      @include breakpoint(tablet) {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-top: 4rem;
      }
    }

    &__sign__heading {
      font-size: 1.8rem;
      line-height: 2.4rem;
      margin: 2rem 0 3rem;

      @include breakpoint(tablet) {
        margin: 2rem 0 2.5rem 0;
      }
    }
  }
}