.dropdown {
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: $color-nero;

  &-content {
    display: none;
  }

  &-open {
    > .dropdown-content {
      display: block;
    }
  }

  &-header,
  &-item {
    padding: 1.2rem 4.5rem 1.2rem 1rem;
    cursor: pointer;
    background-color: $color-white;
  }

  &-level-1 {

    > .dropdown-header {
      border: 1px solid $color-prussian-blue-20;
      border-radius: 5px;
      background-color: $color-white;
    }

    &.dropdown-open > .dropdown-header {
      -webkit-border-top-left-radius: 5px;
      -webkit-border-top-right-radius: 5px;
      -moz-border-radius-topleft: 5px;
      -moz-border-radius-topright: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &-level-1 > &-content {
    position: absolute;
    transform: translate3d(0, 43px, 0);
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid $color-prussian-blue-20;
    border-left: 1px solid $color-prussian-blue-20;
    border-right: 1px solid $color-prussian-blue-20;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    z-index: 15;
  }

  &-level-2 {

    .dropdown {

      &-header {
        border-top: 1px solid $color-prussian-blue-20;
        padding-bottom: 1.3rem;
        font-weight: 600;
      }

      &-item {
        padding-left: 3rem;
      }
    }
  }

  &-item {
    padding-bottom: 1.3rem;
    border-top: 1px solid $color-prussian-blue-20;
    display: block;
    width: 100%;
    text-align: inherit;
    white-space: nowrap;

    &:hover {
      background-color: $color-prussian-blue-20;
    }
  }

  &-arrow {
    top: 1.4rem;
    right: 2rem;
    width: .8rem;
    -webkit-transition: all .2s ease-in-out .2s;
    transition: all .2s ease-in-out .2s;
    will-change: transform;
  }

  &-open > &-header > &-arrow {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }
}