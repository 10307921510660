.most-read,
.most-commented {
  .box-name {
    font-size: 1.6rem;
    margin-bottom: 0;
    padding: 1.7rem 1rem 1.5rem 0;

    @include breakpoint(tablet) {
      font-size: 2rem;
    }
  }

  .slider {
    @include breakpoint(maxTablet) {
      margin: 0 -1rem;
    }

    &:after,
    &:before {
      visibility: hidden;
    }
    @extend .bg-color__alice-blue-100;

    &__elements {
      counter-reset: section;
      margin: 1.4rem 0 0 0;

      @include breakpoint(tablet) {
        flex-flow: column;
      }
    }

    &__element {
      margin-left: 1rem;
      padding-right: 1.5rem;
      scroll-margin-left: 1.2rem;

      &:first-of-type {
        margin-left: 1rem;
        scroll-margin-left: 1rem;

        @include breakpoint(tablet) {
          margin-left: 1.5rem;
          scroll-margin-left: 1.5rem;
        }
      }

      @include breakpoint(tablet) {
        margin-left: 1.5rem;
        overflow: hidden;
      }

      &:before {
        counter-increment: section;
        content: counter(section);
        width: 3.4rem;
        height: 3.4rem;
        font-size: 2rem;
        vertical-align: middle;
        line-height: 34px;
        @extend .bg-color__prussian-blue-100;
        @extend .text-color__white;
        @extend .w-900;
        @extend .d-block;
        @extend .border-round;
        @extend .text--center;

        @include breakpoint(tablet) {
          position: absolute;
          top: 1.3rem;
          float: left;
        }
      }

      &:not(:last-of-type):after {
        content: '';
        top: 17px;
        left: 4rem;
        padding: 0 12.2rem;
        @extend .border-dotted-t;
        @extend .border-color__silver-100;
        @extend .d-block;
        @extend .position-absolute;

        @include breakpoint(tablet) {
          top: 50px;
          left: 15px;
          padding: 48px 0;
          width: 1px;
          height: 100%;
          margin-top: 8px;
          border-top: unset;
          border-left: thin dotted $color-silver;
        }
      }

      .kicker {
        font-size: 1.5rem;
        line-height: 1.8rem;
        margin-top: 1rem;

        @include breakpoint(tablet) {
          padding-left: 5rem;
        }
      }

      .headline {
        @include breakpoint(tablet) {
          padding-left: 5rem;
          padding-bottom: 1.8rem;
        }
      }
    }

    @include breakpoint(tablet) {
      .dot {
        display: none;
      }
    }
  }

  .slider-box__more {
    bottom: 0;
    position: inherit;
    right: 0;
  }

  .comment {
    @extend .d-none;
  }
}