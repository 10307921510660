.podcast-player {
  font-size: 1.6rem;
  line-height: 1.8rem;
  text-align: center;

  @include breakpoint(tablet) {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  &__links {
    margin: 3rem 0 0 0;

    @include breakpoint(tablet) {
      margin: 2rem 0 2rem 0;
    }
  }

  &__links__icons {
    margin-top: 1.5rem;

    .icon {
      height: 4rem;
      width: 4rem;
    }

    a:not(:last-child) .icon {
      margin-right: 1.7rem;
    }
  }
}