.banner {
  margin-bottom: 1.5rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;

  @include breakpoint(tablet) {
    margin-bottom: 0;
    margin-left: -6rem;
    margin-right: -6rem;
  }

  @include breakpoint(desktop) {
    margin-left: 0;
    margin-right: 0;
  }

  article &._3q-video {
    order: 2;
  }
}