.width {

  &--100 {
    width: 100%;
  }

  &--70 {
    width: 70%;
  }

  &--75 {
    width: 75%;
  }

  &--50 {
    width: 50%;
  }

  &--25 {
    width: 25%;
  }
}

.width {
  @include breakpoint(tablet) {
    &--t__100 {
      width: 100%;
    }
  }
}

.height {

  &--100 {
    height: 100%;
  }
}

.size {

  &--h {

    &--13 {
      height: 13rem;
    }
  }

  &--w-max--100 {
    max-width: 100%
  }

  @include breakpoint(desktop) {
    &-desktop {
      &-w {
        &--30 {
          width: 30rem;
        }
      }
    }
  }

  &--image {
    max-width: 4rem;
    max-height: 4rem;
  }
}