@mixin team-name {
  @extend .size--m-2;

  @include breakpoint(tablet) {
    font-size: 1.4rem;
  }
}

.ticker-encounter {
  background-color: $color-white;
  @include breakpoint(maxTablet) {
    border-radius: .5rem .5rem 0 0;
  }

  &__league {
    @extend .size--m-2;
    line-height: 1.6rem;
    padding: .5rem 0;

    @include breakpoint(tablet) {
      font-size: 1.4rem;
      margin: 0 auto;
      padding: .5rem 0 .7rem;
      width: 31.2rem;
    }
  }

  .encounter__details {
    margin-bottom: unset;
  }

  &__link {
    padding: .3rem 0 .7rem;

    a {
      text-decoration: none;
    }

    @include breakpoint(tablet) {
      margin: 0 auto;
      padding: .5rem 0 .9rem;
      width: 31.2rem;
    }

    @include team-name;
  }
}