.footer {

    &__logo {
        height: 7.25rem;
    }

    &__noz-logo {
        height: 3.936rem;
        width: 7.197rem;
        margin-top: 1.75rem;
    }

    &__content {
        padding: 3rem 1.5rem 2.6rem 1.5rem;
        position: relative;
        text-align: center;

        @include breakpoint(desktop) {
            font-size: 1.6rem;
            padding: 3rem 1.5rem 3.9rem 1.5rem;
        }
    }

    &__content__search {
        display: inline-block;
        margin: 0 auto;

        & & {
            margin-bottom: 2.5rem;

            @include breakpoint(tablet) {
                margin-bottom: 2rem;
                width: 29rem;
            }

            @include breakpoint(desktop) {
                margin-bottom: 2.5rem;
            }
        }
    }

    &__content__search-button {
        background-color: unset;
        border: none;
        position: absolute;
        top: 3.9rem;
        right: 2rem;

        @include breakpoint(desktop) {
            right: calc(50% + -140px);
        }

        svg {
            height: 2rem;
            width: 2rem;
            margin-top: 4px;
        }
    }

    &__content__icons {
        display: flex;
        flex-wrap: wrap;
        margin-top: 2.5rem;

        @include breakpoint(tablet) {
            margin-top: 4.4rem;
        }
    }

    &__content__icon {
        width: 50%;
        height: 14.5rem;

        @include breakpoint(tablet) {
            height: 19.5rem;
            width: 25%;
            flex-grow: 1;
            border-bottom: none;

            &:nth-child(2) {
                border-right: thin dotted;
            }
        }

        @include breakpoint(desktop) {
            height: 29rem;
            width: 25%;
            flex-grow: 1;
            border-bottom: none;

            &:nth-child(2) {
                border-right: thin dotted;
            }
        }
    }

    &__content__image {
        height: 6.7rem;
        width: 6.6rem;
        margin: 1.6rem calc(50% - 3.3rem);

        @include breakpoint(tablet) {
            height: 11.3rem;
            width: 11.3rem;
            margin: 2.5rem calc(50% - 5.65rem) .7rem;
        }

        @include breakpoint(desktop) {
            height: 16.8rem;
            width: 16.8rem;
            margin: 5.5rem calc(50% - 8.4rem) 1.9rem;
        }
    }

    &__content__icon:nth-child(3),
    &__content__icon:nth-child(4){

        .footer-content__image {
            margin-top: 2.4rem;
        }
    }

    &__content__links {
        margin: 2.6rem auto 0 auto;
        width: 16.1rem;

        @include breakpoint(tablet) {
            width: auto;
        }
    }

    &__content__link {
        color: $color-white;
        font-weight: normal;
        text-decoration: none;

        @include breakpoint(desktop) {
            font-size: 1.6rem;
        }

        &:hover,
        &:visited {
            color: $color-white;
        }
    }

    &__social-icons {
        width: 4rem;
        height: 4rem;

        &-tiktok {
            width: 3.5rem;
        }
    }

    &__social-links {
        margin-right: 3rem;
        height: 4rem;
    }

    &__social-links:last-child {
        margin-right: 0;
    }

    .hide-mobile {
        display: none;
        @include breakpoint(desktop) {
            display: unset;
        }
    }
}

.footer.sales-landingpage {
    .footer__content > * {
        display: none;
    }

    .footer__content__links {
        display: block;
    }
}