.contact-box {
  margin-bottom: 1.5rem;
  height: 100%;

  &--content {
    height: 100%;
    padding: 2.5rem 1rem;

    @include breakpoint(tablet) {
      padding: 2.5rem;
    }
  }
}

.content--group.content--group__section > .contact-box {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0;
}