.now-important-box {
  margin-left: -1rem;
  .article__teaser--opener-big & {
    @include breakpoint(desktop) {
      margin-top: -19px;
    }
  }

  .box-name {
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: unset;
    padding-top: .2rem;

    & span:empty {
      display: none;
    }
  }

  .slider {
    border-bottom: none;

    &:before,
    &:after,
    .prev-bubble,
    .next-bubble {
      visibility: hidden;
    }

    margin-bottom: unset;

    &__elements {
      margin-bottom: unset;

      @include breakpoint(desktop) {
        display: flex;
        flex-flow: column;
      }
    }

    .next-bubble {
      @include breakpoint(tablet) {
        display: none;
      }
    }

    .three-dots {
      margin: 0 auto 1.7rem auto;

      @include breakpoint(tablet) {
        display: none;
      }
    }
  }

  &__header {
    &__title {
      font-size: 1.6rem;
      font-weight: bold;
      padding-left: 1rem;

      @include breakpoint(tablet) {
        line-height: 2.3rem;
      }
    }
  }

  &__logo {
    margin-right: 1rem;
    max-width: 4rem;
  }

  .next-bubble {
    display: none;
  }

  .slider-element {
    flex-shrink: 0;
    margin-left: 1rem;
    padding: 0 .75rem 1.1rem 0;
    scroll-margin-left: 2rem;
    width: 26rem;

    a {
      text-decoration: none;
    }

    &:not(:nth-of-type(3)) {
      margin-right: .8rem;
      padding: 0 0 1.1rem 0;

      @include breakpoint(desktop) {
        margin-right: 1.7rem;
      }

      &:after {
        @include breakpoint(desktop) {
          content: "";
          width: .1rem;
          height: 100%;
          border-top: unset;
          border-left: thin dotted #bebebe;
          border-right: unset;
          margin: 3.7rem 1rem 3.6rem .8rem;
          position: absolute;
        }
      }
    }

    @include breakpoint(tablet) {
      width: 33.3%;
    }

    @include breakpoint(desktop) {
      display: flex;
      overflow: hidden;
      padding-bottom: .7rem;
      width: unset;
    }

    .date,
    .date-show {
      font-size: 1.4rem;
      line-height: 1.8rem;
      display: flex;
      flex-flow: row;

      @include breakpoint(desktop) {
        margin-right: 1.1rem;
      }

      &:before {
        background-color: $color-prussian-blue;
        content: "";
        height: 1.7rem;
        width: 1.7rem;
        margin: 1.3rem .4rem 0 0;
        @extend .d-block;
        @extend .border-round;

        @include breakpoint(desktop) {
          margin-top: 1.1rem;
        }
      }

      &:after {
        border-bottom: thin dotted $color-silver;
        content: "";
        display: block;
        height: 2.2rem;
        margin-left: .4rem;
        width: 90%;

        @include breakpoint(desktop) {
          display: none;
        }
      }
    }

    .date-show {
      display: block;
    }

    .kicker {
      font-size: 1.5rem;
      line-height: 1.8rem;
      margin-top: 1.1rem;

      @include breakpoint(desktop) {
        margin-top: .5rem;
      }
    }

    .headline {
      font-size: 1.6rem;
      line-height: 2rem;

      @extend .text-color__nero-100;
    }

    &.last-element {
      .date,
      .date-show {
        &:after {
          display: none;
        }
      }
    }
  }

  .slider-box__more {
    display: none;

    @include breakpoint(tablet) {
      display: inline;
    }

    .link {
      color: $color-comet;
    }
  }

  // Special handling if the box is directly included in a grid
  @include breakpoint(tablet) {
    [class*="grid-cell--d"] > & {
      .slider__elements {
        flex-flow: row;
        margin-bottom: 0;

        @include breakpoint(tablet) {
          overflow-x: hidden;
        }

        .slider-element {
          flex-flow: column;

          @include breakpoint(tablet) {
            width: unset;
          }

          &:after {
            display: none;
          }
        }
      }

      .date,
      .date-show {
        font-size: 1.4rem;
        line-height: 1.8rem;
        display: flex;
        flex-flow: row;

        @include breakpoint(desktop) {
          margin-right: 0;
        }

        &:before {
          background-color: $color-prussian-blue;
          border-radius: 50%;
          content: "";
          display: block;
          height: 1.7rem;
          width: 1.7rem;
          margin: 1.3rem .4rem 0 0;

          @include breakpoint(desktop) {
            margin-top: .7rem;
          }
        }

        &:after {
          border-bottom: thin dotted $color-silver;
          content: "";
          display: block;
          height: 1.6rem;
          margin-left: 1.4rem;
          width: 90%;

          @include breakpoint(tablet) {
            height: 1.6rem;
          }

          @include breakpoint(desktop) {
            display: inherit;
          }
        }
      }
    }

    .grid-cell--d-9 > &,
    .grid-cell--d-10 > &,
    .grid-cell--d-11 > &,
    .grid-cell--d-12 > & {
      .slider__elements {
        .slider-element {
          width: 31.5%;
        }
      }
    }
  }
}

