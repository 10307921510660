._3q-video{
  position: relative;

  .interferer {
    background-color: inherit;
    left: 1rem;
    position: absolute;
    top: 1rem;
    z-index: 1000;

    @include breakpoint(tablet) {
      top: 1.5rem;
      left: 1.5rem;
    }

    .icon {
      height: 3.5rem;
      width: 3.5rem;

      @include breakpoint(tablet) {
        height: 5.3rem;
        width: 5.3rem;
      }
    }
  }

  &__description {
    padding: 0 1.3rem;

    @include breakpoint(tablet) {
        padding: 0;
    }
  }
  // styling of the 3q sticky player container
  .sdn-player-sticky {
    top: 4.8rem!important;
  }
}