.podcast-box {

  .box-name {
    @extend .size--m-8;
    line-height: 1.8rem;
    padding: 1.7rem 0 1.5rem 1rem;

    @include breakpoint(tablet) {
      font-size: 3.4rem;
      line-height: 3.6rem;
      padding: 1.5rem 0 1.5rem 1.5rem;
    }
  }

  .slider {
    @include breakpoint(maxTablet) {
      margin: 0 -1rem;
    }

    &__elements {
      flex-direction: row;

      @include breakpoint(maxTablet) {
        margin: 0 1rem;
      }
    }

    &__element {
      height: 13.6rem;
      width: 13.6rem;
      margin: 2rem .5rem 0 0;
      overflow: hidden;

      @include breakpoint(tablet) {
        width: 25.9rem;
        height: 25.9rem;
        margin-right: .9rem;
      }

      .picture img {
        width: 100%
      }
    }
  }

  @include breakpoint(tablet) {
    .link {
      font-size: 1.4rem;
      font-weight: 600;
      margin-top: -4.8rem;
      position: relative;
    }
  }

  .slider-box__more {
    margin-right: -1rem;

    @include breakpoint(tablet) {
      margin-right: 0;
    }
  }
}