.piano_footer_layer {
    margin: 0;
    padding: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    z-index: 100000;
}

.piano_footer_content {
    width: 100%;
}