.autoComplete {
  &:focus {
    outline: 0;
  }

  &_highlighted {
    font-weight: normal;
  }

  &_selected {
    background-color: $color-pacific-blue-10;
  }

  &_list {
    position: absolute;
    width: 100%;
    top: 40px;
    left: 0;
    border-left: 1px solid $color-prussian-blue-20;
    border-right: 1px solid $color-prussian-blue-20;
    border-bottom: 1px solid $color-prussian-blue-20;
    padding: 0;
    margin: 0;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    list-style-type: none;
    background-color: #fff;
    z-index: 1;
  }

  &_no_result,
  &_result {
    padding: 1.1rem 1.1rem 1.5rem;
  }

  &_result {
    position: relative;
    cursor: pointer;

    &:after {
      position: absolute;
      bottom: 0;
      left: 1.1rem;
      content: "";
      width: calc(100% - 2.2rem);
      display: block;
      border-bottom: 1px solid $color-whisper-2;
    }

    &:last-child:after {
      border-bottom: none;
    }
  }
}