.theme-event-box {
  margin: 0 -1rem;

  .box-name {
    display: flex;
    padding: .5rem 0;
    margin-bottom: unset;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .slider {
    @extend .bg-color__alice-blue;
    border-bottom: none;
    margin-bottom: unset;

    @include breakpoint(desktop) {
      border-left: 1rem solid $color-white;
    }
  }

  &__header {
    &__title {
      flex-grow: 2;
      font-size: 1.6rem;
      line-height: 2.3rem;
      padding-left: 1rem;

      @include breakpoint(tablet) {
        font-size: 2rem;
      }
    }
  }

  &__logo {
    margin: .6rem 1rem .6rem .6rem;
    max-width: 4rem;
  }

  .slider__elements,
  .slider__elements:not(.weather-box__day-slider--wrapper) {
    margin-top: 1.7rem;
    margin-bottom: 1.25rem;
    border-top: none;
    @include breakpoint(tablet) {
      border: none;
      flex-flow: column;
      margin-top: 0;
    }
  }

  .slider-element,
  .slider-element-with-date {
    border-top: thin dotted $color-silver;
    border-bottom: thin dotted $color-silver;
    padding: 1.45rem 0 1.25rem 2.5rem;
    width: 25.4rem;
    flex-shrink: 0;

    @include breakpoint(tablet) {
      border-top: none;
      border-bottom: none;
    }

    &:first-child {
      margin-left: 1rem;
      scroll-margin-left: 1rem;
      padding: 1.45rem 0 1.25rem 0;

      @include breakpoint(tablet) {
        margin-left: 0;
        padding-bottom: 1.8rem;
        padding-top: 1.1rem;
      }
    }

    @include breakpoint(tablet) {
      padding: 0 1.25rem 2.7rem 0;
      overflow: hidden;
      width: 100%;

      &.last-element {
        padding-bottom: unset;
      }
    }

    &:before {
      content: "";
      height: 78%;
      width: .1rem;
      left:0;
      top: 0;
      margin: 2rem 1.15rem 2rem 0;
      @extend .d-block;
      @extend .position-absolute;
      @extend .border-dotted-r;
      @extend .border-color-silver;

      @include breakpoint(tablet) {
        top: 2rem;
        left: 3rem;
        width: .1rem;
        height: 100%;
        border-top: none;
        border-left: thin dotted $color-silver;
        border-right: none;
        margin: 2rem 1.15rem 2rem 1.2rem;
      }
    }

    &:first-child:before {
      display: none;

      @include breakpoint(tablet) {
        display: block;
      }
    }

    @include breakpoint(tablet) {
      &:first-child:not(.slider-element-with-date):before {
        top: 2rem;
      }

      &:first-child:before {
        top: 3.5rem
      }

      &:not(:first-child):not(.slider-element-with-date):before {
        top: .5rem;
      }
    }

    .date,
    .date-show {
      font-size: 1.4rem;
      line-height: 1.8rem;
      display: none;

      @include breakpoint(tablet) {
        margin-top: .3rem;
        margin-left: 1.4rem;
        float: left;
      }

      @include breakpoint(tablet) {
        display: block;
      }

      &:before {
        background-color: $color-prussian-blue;
        content: "";
        height: .9rem;
        width: .9rem;
        margin: .5rem .4rem 0 0;
        @extend .d-block;
        @extend .float--l;
        @extend .border-round;

        @include breakpoint(tablet) {
          margin-bottom: .3rem;
          margin-left: 2.4rem;
          float: none;
        }
      }
    }

    .date-show {
      display: block;
    }

    @include breakpoint(tablet) {
      .text-reset {
        padding-left: 9.3rem;
        display: block;
      }
    }

    .kicker {
      font-size: 1.5rem;
      line-height: 1.8rem;
    }

    .headline {
      font-size: 1.6rem;
      line-height: 2rem;

      @include breakpoint(tablet) {
        padding-left: 10rem;
      }

      @extend .text-color__nero-100;
    }
  }

  .slider-box__more {
    @extend .bg-color__white;

    @include breakpoint(tablet) {
      padding-right: 1.2rem;
    }

    .link {
      color: $color-comet;
    }
  }

  @include breakpoint(tablet) {
    .slider-element-with-date {
      &:not(.last-element):after {
        top: 2rem;
      }
    }

    .slider {
      .three-dots {
        display: none;
      }

      &-box__more {
        position: static;
      }
    }
  }
}

@include breakpoint(tablet) {

  .theme-event-box {
    .date {
      margin-right: 4.7rem;
    }

    .date-show {
      margin-right: 2.3rem;
    }

    [class*=" grid-cell--d"]:not(.grid-cell--d-12)>& {
      .slider:after {
        display: none!important;
      }

      .prev-bubble,
      .next-bubble {
        visibility: hidden;
      }
    }

    [class*=""]:not(.grid-cell--d-8):not(.grid-cell--t-8):not(.grid-cell--t-6):not(.grid-cell--t-4)>&,
    .content--group__section>&,
    .grid-cell--t-12 &,
    .grid-cell--d-12 & {
      &.slideable {
        .theme-event-box__ {
          &header__title {
            order: 2;
            padding-left: unset;
          }

          &__logo {
            order: 1;
          }
        }


        .prev-bubble,
        .next-bubble {
          top: calc(50% - 2rem);
        }

        .three-dots {
          display: block;
          margin: 1.6rem auto 1.6rem auto;
        }

        .slider__elements {
          border-bottom: thin dotted $color-silver;
          flex-direction: row;
          margin-bottom: 0;
          margin-left: 2rem;
          margin-top: 1.8rem;
          padding-bottom: unset;
          @include breakpoint(tablet) {
            margin-top: .25rem;
          }

          .slider-element,
          .slider-element-with-date {
            padding: 1.45rem 2.5rem 1.25rem 0;

            @include breakpoint(tablet) {
              padding-bottom: 1.75rem;
            }

            &:before {
              display: none;
            }
          }
        }
      }

      // not slideable
      .slider__elements {
        flex-direction: row;
        margin-left: 2rem;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      .slider-element,
      .slider-element-with-date {
        padding: 0 1.25rem 1.7rem 0;
        width: 35.9rem;

        .text-reset {
          padding-left: unset;
        }

        &:not(:first-child) {
          padding-left: .7rem;
        }

        .date,
        .date-show {
          float: none;
          margin-left: unset;

          &:before {
            float: left;
            margin-left: unset;
            margin-right: .6rem;
            margin-bottom: 1.3rem;
          }

          &:after {
            content: "";
            width: 130%;
            display: block;
            border-bottom: thin dotted $color-silver;
            position: relative;
            top: -.9rem;
            margin-left: 8.5rem;
          }
        }

        &:not(:first-child) {
          .date,
          .date-show {
            margin-left: .5rem;
          }
        }

        &.last-element {
          .date,
          .date-show {
            &:after {
              display: none;
            }
          }
        }

        .kicker {
          padding-left: unset;
          clear: left;
        }

        .headline {
          padding-left: unset;
        }
      }

      &:not(.slideable) {
        .slider-element,
        .slider-element-with-date {
          &:after,
          &:before {
            display: none;
          }
        }
      }

      .slider-element {
        .date {
          &:after {
            top: .9rem;
            margin-left: 1.5rem;
          }
        }
      }
    }
  }
}

.grid-cell--t-6,
.grid-cell--d-4 {
  .slider-box.theme-event-box {
     .slider.has-next {
       .prev-bubble,
       .next-bubble,
       &:after {
         display: none;
       }
     }
  }
}