a {
  color: $color-pacific-blue;

  &:hover,
  &:visited,
  &:focus {
    &:not(.ignore-hover) {
      //color: inherit;
    }
  }

  &:hover {
    &:not(.ignore-hover) {
      color: $color-prussian-blue-80;

      .bg-color & {
        color: $color-prussian-blue-20;
      }
    }
  }

  &.text-color__white:visited {
    color: $color-white;
  }
}

.inherit-color-underline {
  color: inherit;
  text-decoration: underline;
}

.inherit-color-underline-final-line {
  color: inherit;
  text-decoration: none;
  position: relative;

  &:after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid ;
    content: "";
  }
}

.no-underline {
  text-decoration: none;
}

$states: " ", ":hover", ":focus", ":visited";

@mixin a-pseudo-colors($selector, $color) {
  $class-list: null;

  @each $state in $states {
    $class-list: append($class-list, unquote("a#{$selector}#{$state}"), comma);
  }
  #{$class-list} {
    color: #{$color};
  }
}