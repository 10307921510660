.article-scroller {
  &__header {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: $color-white;
    z-index: 990;

    &__arrow {
      fill: #04426A;
    }

    &__dots {
      max-width: 50vw;

      &__dot {
        width: .5rem;
        height: .5rem;
        background-color: #d1cdc5;
        border-radius: 50%;
        margin: .5rem .2rem;
        display: inline-block;

        &.dot--active {
          background-color: #231f20;
        }
      }
    }
  }

  &__pagination {
    position: fixed;
    right: 1.5rem;
    bottom: 1.5rem;
    width: 5.2rem;
    height: 5.2rem;
    line-height: 5.2rem;
    font-size: 1.4rem;
    border-radius: 50%;
    color: $color-white;
    background-color: #04426A;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-align: center;
    z-index: 10;
  }
}