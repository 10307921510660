.author-page {
  padding-bottom: 1.7rem;

  &__blue-box {
    height: 16rem;
    margin-left: -1rem;
    margin-right: -1rem;

    @include breakpoint(tablet) {
      margin-bottom: 8.3rem;
      height: 25.5rem;
    }
  }
  .picture__wrapper {
    overflow: visible;
  }

  &__picture {
    width: 12rem;
    margin: 0 auto;
    display: block;
    position: relative;
    bottom: -2rem;

    @include breakpoint(tablet) {
      bottom: -5.8rem;
      width: 25.9rem;
    }
  }

  &__img {
    width: 12rem;

    @include breakpoint(tablet) {
      width: 25.9rem;
    }
  }

  &__headline {
    margin-bottom: .5rem;
    margin-top: 1.5rem;

    @include breakpoint(tablet) {
      font-size: 3.4rem;
      line-height: 4rem;
      margin-bottom: 1.5rem;
      margin-top: 8.3rem;
    }
  }

  &__contact-icons {
    text-align: center;

    .icon--size {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  &__description {
    line-height: 2.2rem;
    margin: 2rem .5rem 2rem;

    @include breakpoint(desktop) {
      margin: 2.1rem 25% 2rem;
    }
  }

  &__tag {
    line-height: 2.8rem;
  }

  &__line {
    border-bottom: .1rem dotted $color-silver;
    width: 15rem;
    margin: 1rem auto 0;

    @include breakpoint(tablet) {
      margin-bottom: 1rem;
    }
  }
}