.article-personalisation {
  &__local {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    z-index: 1200;
    padding: 6rem 1rem 1rem;
    margin-right: -100%;
    -webkit-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
    overflow: scroll;

    @include breakpoint(tablet) {
      width: 320px;
    }

    @include breakpoint(desktop) {
      z-index: 1700;
      padding-top: 1rem;
    }

    &--open {
      margin-right: 0;
    }

    .edit-locations {
      cursor: pointer;
    }

    .close-nav {
      margin-left: -1.5rem;
      padding: 2rem 1.5rem;
      border-bottom: 1px solid #d4d4d4;

      @include breakpoint(tablet) {
        margin-left: -1rem;
        padding: 2rem 1rem;
      }

      @include breakpoint(desktop) {
        display: block;
      }

      .md,
      .mdiv {
        background-color: #ccd7e1;
      }

      label {
        font-size: 1.2rem;
        text-transform: uppercase;
        letter-spacing: .08rem;
        position: unset;
        color: #08c;
        cursor: pointer;
      }
    }
  }

  &--location__button {
    position: relative;

    .icon-close {
      position: absolute;
      right: -6px;
      top: -6px;
    }
  }
}

body.dko {
  .article-personalisation {
    &__local {
      padding-top: 9rem;
    }
  }
}