.weather-box {
  padding-top: 1.8rem;

  @include breakpoint(tablet) {
    padding: 3rem 0 3.5rem;
  }

  &__header {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    font-size: 1.9rem;
    font-weight: 600;
    line-height: 2.6rem;
    justify-content: space-between;

    @include breakpoint(tablet) {
      font-size: 2rem;
      line-height: 4.4rem;
    }

    & > .input {
      margin-top: 2.4rem;

      .autoComplete {
        border: none;
      }
    }

    .input,
    .search {
      width: 100%;

      @include breakpoint(tablet) {
        margin-top: 0;
        width: 28.9rem;
      }
    }

    .autoComplete_list {
      z-index: 2;
    }
  }

  &__day-slider {
    position: relative;
    margin-left: -1rem;
    margin-right: -1rem;

    @include breakpoint(tablet) {
      margin-left: 0;
      margin-right: 0;
    }

    &--wrapper,
    .slider-box .slider__elements {
      display: flex;
      flex-flow: row;
      margin-top: 2.4rem;
      overflow-x: auto;
      scrollbar-width: none;
      scroll-snap-type: x mandatory;
      width: 100%;
      -ms-overflow-style: none;
      width: 100%;
    }

    .three-dots {
      @include breakpoint(desktop) {
        display: none;
      }
    }

    &.dist {
      justify-content: space-between;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &__tile {
      cursor: pointer;
      display: flex;
      border: .1rem solid $color-nobel;
      border-radius: .7rem;
      flex-flow: row;
      flex-shrink: 0;
      flex-grow: 1;
      height: 10.1rem;
      margin-right: 2rem;
      padding: 2rem 1.1rem;
      position: relative;
      scroll-snap-align: start;
      width: 16.6rem;

      &:first-of-type {
        margin-left: 1rem;
        scroll-margin-left: 1rem;

        @include breakpoint(desktop) {
          margin-left: 0;
        }
      }

      &:hover,
      &.active {
        border-color: $color-pacific-blue-40;
        border-width: .2rem;
      }

      :first-child {
        line-height: 3.1rem;
        width: 8rem;
      }

      & > :last-child {
        height: 5rem;
        position: absolute;
        right: 1.6rem;
        width: 5rem;
      }

      & :nth-child(n+2) {
        font-size: 1.6rem;
        line-height: 2.6rem;
      }

      &__headline {
        font-size: 1.7rem;

        @include breakpoint(tablet) {
          font-size: 2rem;
        }
      }
    }
  }

  &__content {
    font-size: 1.6rem;
    margin-top: 3.5rem;

    @include breakpoint(tablet) {
      display: grid;
      grid-template-areas: "warnings warnings" "details rainradar";
      grid-template-columns: auto 38rem;
      grid-template-rows: auto auto;
    }

    &__details {
      display: none;
      margin-top: 1.3rem;

      @include breakpoint(tablet) {
        line-height: 2.2rem;
        margin-top: 0;
        grid-area: details;
      }

      &--day {
        @include breakpoint(tablet) {
          padding-left: 1rem;
        }
      }

      .table {
        border-collapse: collapse;
        margin-bottom: 4rem;
        width: 100%;

        tr>td:first-of-type {
          width: 50%;

          @include breakpoint(tablet) {
            width: 16.2rem;
          }
        }
      }

      &.active {
        display: block;

        @include breakpoint(tablet) {
          display: flex;
        }
      }

      .day-sections {
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(tablet) {
          flex-flow: column;
          line-height: 1.8rem;
          margin-right: 2.3rem;
          padding-left: 1rem;
        }

        @include breakpoint(desktop) {
          padding-left: 0;
        }

        &__tile {
          display: flex;
          height: 6rem;
          justify-content: space-between;
          margin-bottom: 2.7rem;
          width: 50%;

          @include breakpoint(tablet) {
            width: 14.4rem;

            &:last-of-type {
              margin-bottom: 0;
            }
          }

          @include breakpoint(desktop) {
            width: 16.6rem;
          }

          :first-child {
            line-height: 3.1rem;
            width: 6.7rem;
          }

          & > :last-child {
            height: 5rem;
            margin-right: 1.8rem;
            width: 5rem;
          }

          & :nth-child(n+2) {
            align-self: flex-end;
            line-height: 2.6rem;
          }
        }
      }
    }

    &__rainradar {
      margin-top: 4rem;
      padding-bottom: 3rem;

      @include breakpoint(tablet) {
        border-left: .1rem solid $color-nobel;
        margin: .8rem 0 0 .7rem;
        padding-left: .8rem;
        width: 24.2rem;
        grid-area: rainradar;
      }

      @include breakpoint(desktop) {
        width: 35.2rem;
      }

      &__header {
        margin-bottom: 1.5rem;

        @include breakpoint(tablet) {
          line-height: 1.6rem;
          margin-top: -.2rem;
        }
      }
    }
  }

  &__warnings {
    display: none;
    grid-area: warnings;

    &.active {
      display: block;
    }
  }

  &__warning {
    margin-top: -1.5rem;

    margin-bottom: 2rem;
    padding: 1rem;
    background-color: #EBEB44;
  }

  &-warning {
    &__text {
      font-size: 1.3rem;
      line-height: 1.75;
    }

    &__subline {
      display: flex;
      align-items: center;

      svg {
        display: block;
        min-width: 3.2rem;
      }
    }

    &__subline-text {
      padding-left: .8rem;
      font-family: 'Source Sans Pro';
      font-size: 1.8rem;
      line-height: 2.2rem;
      font: normal normal 700 1.5rem/1.8rem 'Source Sans Pro';
    }

    @include breakpoint(tablet) {
      padding: 1.5rem 1.7rem 2.5rem;

      &__subline-text {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
    }
  }
}