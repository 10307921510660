.main-header {
    position: sticky;
    top: 0;
    left: 0;
    $g: &;
    border-bottom: 1px solid $color-nero-20;
    font-family: $typeface-font;
    background-color: $color-white;
    color: $color-nero-50;
    font-size: 2rem;
    height: 5.6rem;
    transition: transform .4s;
    width: 100%;
    z-index: 1500;

    @include breakpoint(desktop) {
        @at-root {
            #{$g}.main-header--large {
                //position: relative;
                height: 140px;
            }
        }
    }

    // Top Part
    .top-part {
        display: none;

        @include breakpoint(desktop) {
            @at-root {
                #{$g}.main-header--large .top-part {
                    justify-content: space-between;
                    margin: 0 auto;
                    max-width: $max-width_desktop;
                    padding: 1.6rem 1rem 0 0;
                    position: relative;
                    display: flex;
                    height: 9.4rem;

                    &__misc-nav--left,
                    &__misc-nav--right {
                        width: 25.6rem;

                        .link {
                            color: $color-comet;
                            text-decoration: none;

                            &:not(:first-of-type) {
                                padding-left: 2rem;
                            }
                        }
                    }

                    &__misc-nav--left {
                        padding-top: .2rem;
                    }

                    &__misc-nav--left a:last-child {
                        border: none;
                    }

                    &__misc-nav--right {
                        text-align: right;
                    }

                    button {
                        font-size: 1.2rem;
                        line-height: 1.8rem;
                    }
                }
            }
        }

        .logo {
            width: 124px;
            height: 68px;

            @include breakpoint(desktop) {
                @at-root {
                    #{$g}.main-header--large .top-part .logo {
                        margin-top: -.3rem;
                        height: 6.8rem;
                        width: 12.5rem;
                    }
                }
            }
        }

        .header-links {
            color: $color-comet;
            padding: 0 1.9rem 0 1rem;

            @include breakpoint(desktop) {
                @at-root {
                    #{$g}.main-header--large .top-part .header-links {
                        padding-left: 1rem;
                        padding-right: 1.9rem;
                    }
                }
            }
        }

        .header-border-right {
            border-right: .1rem solid;
        }
    }

    // Bottom Part
    .lower-part {
        position: relative;

        &__wrapper {
            display: flex;
            height: 4.5rem;
            justify-content: space-between;
            max-width: 114rem;
            top: 6.5rem;
            margin: 0 auto;

            label {
                cursor: pointer;
                width: 5.15rem;

                &[for="quick-menu"] {
                    padding-left: 1.1rem;
                    width: auto !important;
                }

                @include breakpoint(desktop) {
                    @at-root {
                        #{$g}.main-header--large .lower-part .lower-part__wrapper label {
                            margin-left: 1rem;
                            margin-right: 0;
                            width: 5.6rem;
                        }
                    }
                }
            }
        }

        @include breakpoint(desktop) {
            @at-root {
                #{$g}.main-header--large .lower-part {
                    border-bottom: thin solid $color-silver;
                    background-color: $color-white-smoke-2;
                    height: 45px;
                }
            }
        }

        input[type="checkbox"] {
            display: none;
        }

        &__icon-magnifying-glass {
            cursor: pointer;
            display: inline-block;
            margin: 1.8rem 0 0 .2rem;
            width: 2.05rem;
            height: 2.05rem;

            @include breakpoint(desktop) {
                @at-root {
                    #{$g}.main-header--large .lower-part__icon-magnifying-glass {
                        margin-top: 1.3rem;
                    }
                }
            }
        }

        .navigation {
            display: none;
        }

        &__logo {
            margin: .8rem auto;
            width: 72px;
            height: 39px;
        }

        ul {
            display: flex;
            flex-grow: 2;
            margin: unset;

            li {
                cursor: auto;
                flex-grow: 1;
                text-align: center;

                &:not(.no-relative) {
                    position: relative;
                }

                &.active {
                    a.active {
                        height: 6.7rem;
                    }
                }
            }
        }

        .navigation__sub-nav ul li {
            text-align: left;
        }

        .navigation__link--sub {
            cursor: pointer;
        }

        @include breakpoint(desktop) {
            @at-root {
                #{$g}.main-header--large .lower-part {

                    .navigation__link,
                    .navigation__link--sub {
                        color: inherit;
                        cursor: pointer;
                        display: inline-block;
                        height: 4.5rem;
                        position: relative;
                        text-decoration: none;

                        &.active,
                        &:hover:not(.navigation__link--sub),
                        &:active {
                            border-bottom: .3rem solid $color-pacific-blue;
                            height: 4.5rem;
                            &:after {
                                background-color: $color-pacific-blue;
                                content: "";
                                display: block;
                                height: 0.3rem;
                                position: relative;
                                top: -3px;
                                z-index: 1;
                            }
                        }

                        @media(hover) {
                            &:hover:not(.navigation__link--sub) {
                                border-bottom: .3rem solid $color-pacific-blue;
                                height: 4.5rem;
                            }
                        }

                        span.active {
                            &:after {
                                background-color: $color-pacific-blue;
                                content: "";
                                display: block;
                                height: 0.3rem;
                                position: relative;
                                top: -3px;
                                z-index: 1;
                            }
                        }
                    }

                    .quick-menu__pin {
                        margin-left: auto;
                        margin-right: auto;
                        opacity: 0;
                        position: absolute;
                        left: calc(50% - 10px);
                        top: 75%;
                        transition: all 0s;
                        visibility: hidden;
                        z-index: 1110;
                    }

                    @include breakpoint(desktop) {
                        @at-root {
                            #{$g}.main-header--large .quick-menu .quick-menu__pin {
                                right: 3rem;
                                display: inherit;
                                position: absolute;
                            }
                        }
                    }

                    & .full-width .quick-menu__pin {
                        left: 1rem;
                    }

                    .navigation__sub-nav {
                        opacity: 0;
                        position: absolute;
                        -webkit-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.14);
                        -moz-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.14);
                        box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.14);
                        visibility: hidden;
                        z-index: 1111;

                        @media(hover) {
                            li:hover {
                                background-color: $color-pacific-blue-20;
                            }
                        }
                    }

                    &__logo {
                        display: none;
                    }

                    .icon-magnifying-glass,
                    .menu {
                        cursor: pointer;
                        flex-grow: 0;
                    }

                    &.menu-open {
                        border-bottom: 1px solid $color-nero-20
                    }

                    .navigation {
                        font-size: 1.5rem;
                        line-height: 4.5rem;
                        font-family: $base-font;
                        font-weight: 700;
                        display: flex;
                        flex-grow: 2;
                        justify-content: space-around;
                        padding: 0;

                        @media(hover) {
                            ul li:hover .navigation__link--sub {
                                display: inline-block;
                                height: 4.5rem;
                                position: relative;
                            }
                        }

                        ul li.no-relative {
                            margin-left: -1rem;

                            & > a,
                            & > div {
                                cursor: pointer;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        // User
        .user-profile {
            margin: 1.1rem 0;
            position: relative;
            width: 5.15rem;
            line-height: 2.4rem;

            &:not(.logged-in) {
                padding-left: 1.5rem;
            }

            @include breakpoint(desktop) {
                @at-root {
                    #{$g}.main-header--large .lower-part .user-profile {
                        flex-grow: 0;
                        margin: 1.1rem 1rem 1.1rem 0;
                        width: 11rem;
                        line-height: inherit;
                    }
                }
            }

            &__login {
                color: $color-pacific-blue;
                cursor: pointer;
                display: block;
                font-size: .8rem;
                letter-spacing: .08rem;
                line-height: 10px;
                text-transform: uppercase;

                @include breakpoint(desktop) {
                    @at-root {
                        #{$g}.main-header--large .lower-part .user-profile .user-profile__login {
                            color: $color-comet;
                            font-size: 12px;
                            letter-spacing: 0;
                            line-height: 18px;
                            position: absolute;
                            right: 1rem;
                            top: 1px;
                            text-transform: unset;
                            width: 4.6rem;

                            &--logged-in {
                                right: 2.9rem;
                            }
                        }
                    }
                }

                &--logged-in:before {
                    content: "Profil";
                    position: relative;
                    left: -.2rem;
                    top: .3rem;
                }

                &--logged-off::before {
                    content: "Login";
                    font-weight: 600;
                    left: -.2rem;
                    position: relative;
                    top: .3rem;
                }
            }

            .icon-user {
                cursor: pointer;
                height: 2rem;
                width: 2rem;
                @include breakpoint(desktop) {
                    @at-root {
                        #{$g}.main-header--large .lower-part .user-profile .icon-user {
                            margin-top: .2rem;
                            position: absolute;
                            right: 0;
                        }
                    }
                }
            }

            &__bubble-correct {
                position: absolute;
                top: 1rem;
                right: 1.6rem;
                height: 1.18rem;
                width: 1.18rem;

                @include breakpoint(desktop) {
                    @at-root {
                        #{$g}.main-header--large .lower-part .user-profile .user-profile__bubble-correct {
                            right: -4px;
                        }
                    }
                }
            }
        }


        .navigation {
            li:not(.no-relative) {
                .navigation__sub-nav {
                    width: 32rem;
                    position: absolute;
                    top: 6.5rem;
                    right: 0;

                    li {
                        border-radius: .3rem;
                        width: 29rem;

                        a {
                            display: block;
                            padding: 1.3rem 1.4rem 1.3rem;
                        }
                    }
                }

                &:first-of-type {
                    .navigation__sub-nav {
                        left: 0;
                    }
                }
            }

            li.no-relative .navigation__sub-nav__container {
                right: unset;
            }

            &__sub-nav {
                padding: 2.4rem 1.5rem 2.7rem 1.5rem;

                ul {
                    display: flex;
                    flex-direction: column;
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                }
            }

            li.active {
                .navigation__sub-nav {
                    background-color: $color-white;
                    display: block;
                    margin-top: -2rem;
                    min-width: 26rem;
                    opacity: 1;
                    right: 0;
                    position: absolute;
                    visibility: visible;

                    &.full-width {
                        cursor: default;
                        left: 0;
                        letter-spacing: .02rem;
                        max-width: $max-width_desktop;
                        margin-left: auto;
                        margin-right: auto;
                        overflow-x: hidden;
                        position: absolute;

                        li a {
                            padding: unset;
                        }
                    }
                }

                a:active ~ .navigation__sub-nav__container > .navigation__sub-nav {
                    margin-top: 5px;
                }
            }

            .no-relative {
                .navigation__sub-nav {
                    left: 0;
                    overflow-x: auto;
                    padding-bottom: 2.5rem;
                }

                ul {
                    position: relative;
                    display: flex;
                    flex-flow: column wrap;
                    height: 43.4rem;
                    @include breakpoint(desktop) {
                        @at-root {
                            #{$g}.main-header--large .lower-part .navigation .no-relative ul {
                                height: 42.7rem;
                            }
                        }
                    }
                }

                li {
                    align-self: start;
                    background-color: unset !important;
                    border-radius: unset;
                    flex-grow: 0;
                    font-weight: 400;
                    line-height: 2rem;
                    margin: 0;
                    padding-right: 1.5rem;
                    padding-bottom: .5rem;

                    &.last {
                        padding-bottom: 1.5rem;
                        @include breakpoint(desktop) {
                            padding-bottom: 2rem;
                        }
                    }

                    &.headline,
                    & a.headline {
                        text-decoration: none;
                        text-transform: uppercase;
                    }
                }
            }
        }

        li.active .quick-menu__pin {
            visibility: visible;
            opacity: 1;
        }


        //@media (hover: hover) and (pointer: fine) {
            .navigation {
                li:not(.no-relative) {
                    .navigation__sub-nav {
                        //padding: 2.5rem 2rem;
                    }
                }

                .no-relative {
                    li {
                        width: 25%;
                    }
                }
            }

            li.active {
                .navigation__sub-nav {
                    background-color: $color-white;
                    display: block;
                    margin-top: -2rem;
                    min-width: 26rem;
                    opacity: 1;
                    right: 0;
                    position: absolute;
                    visibility: visible;

                    &.full-width {
                        top: 6.5rem;
                        left: 0;
                        letter-spacing: .02rem;
                        max-width: $max-width_desktop;
                        margin-left: auto;
                        margin-right: auto;
                        overflow-x: hidden;
                        position: absolute;

                        li a {
                            padding: unset;
                        }
                    }
                }

                a:active ~ .navigation__sub-nav__container > .navigation__sub-nav {
                    margin-top: 5px;
                }
            }

            @media(hover) {
                li:hover {
                    .navigation__sub-nav {
                        background-color: $color-white;
                        margin-top: -2rem;
                        min-width: 26rem;
                        opacity: 1;
                        right: 0;
                        position: absolute;
                        transition: visiblity 0s, opacity .5s ease-in-out;
                        visibility: visible;

                        &.full-width {
                            top: 6.5rem;
                            left: 0;
                            letter-spacing: .02rem;
                            max-width: $max-width_desktop;
                            margin-left: auto;
                            margin-right: auto;
                            overflow-x: hidden;
                            position: absolute;

                            li a {
                                padding: unset;
                            }
                        }
                    }

                    a:active ~ .navigation__sub-nav__container > .navigation__sub-nav {
                        margin-top: 5px;
                    }
                }
            }

            li.active .quick-menu__pin {
                display: block;
                opacity: 1;
                visibility: visible;
                z-index: 2;
            }

            @media(hover) {
                li:hover .quick-menu__pin {
                    opacity: 1;
                    transition: visiblity 0s, opacity .8s ease-in-out;
                    visibility: visible;
                }
            }
        //}
    }

    &__local {
        position: absolute;
        left: -20px;
        top: 0;
        padding: 4px 0;

        @include breakpoint(tablet) {
            display: none;
            left: -20px;
            width: 2rem;

            label {
                margin-top: .7rem;
                margin-right: 0;
            }
        }

        @include breakpoint(desktop) {
            top: -5px;
            left: 0;
        }
        
        &--close {
            position: absolute;
            top: -7px;
            right: -8px;
        }
    }

    .edit-mode .icon-close.hidden {
        display: inline-block;
        position: absolute;
        right: -8px;
        top: -7px;
    }

    &__icon-place {
        width: 21px;
        height: 100%;
        fill: #BEBEBE;
        cursor: pointer;
    }

    &__tooltip {
        position: absolute;
        top: 20px;
        right: -41px;
        z-index: 400;
    }

    input[type="checkbox"] {
        display: none;

        &:before {
            content: "";
            background-color: transparent;
            position: absolute;
            top: 0;
            left: 0;
            opacity: .5;
            width: 0;
            height: 0;
        }
    }

    .main-navigation__wrapper:before {
        background-color: $color-black;
        content: "";
        height: 150vh;
        left: 0;
        position: fixed;
        opacity: 0;
        width: 100vw;
        transition-property: opacity;
        transition-duration: $mainNavTiming;
        transition-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);
        top: 6rem;

        @include breakpoint(desktop) {
            @at-root {
                #{$g}.main-header--large .main-navigation__wrapper:before {
                    top: 0;
                }
            }
        }
    }

    @include breakpoint(desktop) {
        @at-root {
            #{$g}.main-header--large {
                .main-navigation {
                    top: 0;
                }
            }
        }
    }

    input[type="checkbox"]:not(:checked) + .main-navigation__wrapper {
        & .close-nav {
            & a {
                display: none;
            }
        }
    }

    input[type="checkbox"]:checked + .main-navigation__wrapper {
        left: 0;
        position: fixed;
        top: 0;
        z-index: 1100;

        & .close-nav {
            opacity: 1;
            position: relative;
            display: flex;

            @include breakpoint(desktop) {
                display: none;
            }
        }

        & .main-navigation {
            left: 0;
            overflow-y: scroll;
        }

        &:before {
            top: 0;
            left: 0;
            opacity: .8;
        }

        .main-nav {
            @for $i from 0 through $mainNavLiCount {
                & > ul > li:nth-child(#{$i}) {
                    transform: translateX(0);
                }
            }
        }
    }

   .main-navigation__wrapper--local {
       position: absolute;
       visibility: hidden;
       top: 45px;
       padding: 15px;
       z-index: 1200;
       background: #fff;
       width: 100%;
   }

    input[type="checkbox"]:checked + .main-navigation__wrapper--local {
        visibility: visible;
    }

    @include breakpoint(desktop) {
        @at-root {
            #{$g}.main-header--large label[for="quick-menu"] {
                margin: 0;
                position: relative;
                top: -.2rem;
            }
        }
    }

    input[type="checkbox"]:checked + .quick-menu {
        display: inherit;
        height: 100vh;
        opacity: 1;
        pointer-events: inherit;
        right: 0;

        .abo-plus-button {
            display: none;
        }

        .mdiv.m-r-14 {
            order: 2;
        }

        span {
            margin-right: 10px;
        }

        @for $i from 0 through $mainNavLiCount {
            & nav > ul > li:nth-child(#{$i}) {
                transform: translateX(0);
            }
        }

        @include breakpoint(desktop) {
            @at-root {
                #{$g}.main-header--large input[type="checkbox"]:checked + .quick-menu {
                    -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14);
                    -moz-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14);
                    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14);
                    display: inherit;
                    height: 100%;
                    opacity: 1;
                    pointer-events: inherit;
                    right: 20px;
                    transition: visiblity 0s, opacity .5s ease-in-out;
                    visibility: visible;

                    .quick-menu__pin {
                        opacity: 1;
                        right: -10rem;
                        top: -1.1rem;
                        visibility: visible;
                        z-index: 0;
                    }
                }
            }
        }
    }
}

.user-profile.logged-in {
    .main-header__local {
        padding-top: 1px;
    }
}

.menu {
    float: left;
    margin: 1.6rem 0 1.5rem 1.5rem;
    display: inline-block;

    @include breakpoint(desktop) {
        margin: 1.1rem 0 1.5rem 0;
    }

    .menu-strokes {
        display: block;
        width: 14px;
        height: 2px;
        margin: .4rem 0;
        background-color: $color-silver;

        &:nth-child(2) {
            width: 10px;
        }
    }
}


@include breakpoint(desktop) {
    .main-header:not(.main-header--large) .menu {
        margin: 1.6rem 0 1.5rem 1.5rem
    }

    .main-header.main-header--large .user-profile__login--logged-in:before {
        content: "Ihr Profil";
    }

    .main-header:not(.main-header--large) input[type=checkbox]:checked + .quick-menu {
        top: 60px;
        height: 100%;
    }

    .main-header:not(.main-header--large) .quick-menu__pin {
        right: 20px;
    }
}

.main-header.sales-landingpage {
    .lower-part {
        [for="main-nav"],
        .user-profile {
            visibility: hidden;
        }
    }
}

.main-header.main-header--large .lower-part .navigation .no-relative ul {
    position: relative;
}


body.noz .navigation__sub-nav.full-width:before,
.main-header.main-header--large .lower-part .navigation .no-relative ul:before,
.main-header.main-header--large .lower-part .navigation .no-relative ul:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 1px;
    background: #6687a6;
    z-index: 10;
}

.main-header.main-header--large .lower-part .navigation .no-relative ul:before,
.main-header.main-header--large .lower-part .navigation .no-relative ul:after {
    top: 0;
}


body.noz {
    .navigation__sub-nav.full-width:before {
        left: calc(50% - 1.5rem);
        height: calc(100% - 5rem);
        top: 24px;
    }

    .main-header .lower-part .navigation .no-relative ul {

        &:before {
            left: calc(25% - 1.5rem);
        }

        &:after {
            left: calc(75% - 1.5rem);
        }
    }
}

body.shz {
    .main-header .lower-part .navigation .no-relative ul {

        li {
            width: 33.33%;
        }

        &:before {
            left: calc(33.33% - 1.5rem);
        }

        &:after {
            left: calc(66.66% - 1.5rem);
        }
    }
}