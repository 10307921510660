.prev-bubble {
  cursor: pointer;
  display: none;
  position: absolute;
  left: 1.7rem;
  top: calc(50% - 1.6rem);
  z-index: 10;
  width: 3.2rem;
  height: 3.2rem;
  -webkit-box-shadow: 0 1rem 1rem 0 rgba(0,0,0,0.4);
  -moz-box-shadow: 0 .1rem 1rem 0 rgba(0,0,0,0.4);
  box-shadow: 0 .1rem 1rem 0 rgba(0, 0, 0, 0.4);

  &__arrow-left {
    stroke-width: .2rem;
    height: .7rem;
    width: 1rem;
    transform: scaleX(-1);
  }
}