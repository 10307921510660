a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll, a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection, a#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll {height:auto !important;white-space:break-spaces !important;}
#CybotCookiebotDialogBodyContentTitle{display: flex !important; justify-content: center; align-items: center;font-family: "Times New Roman", Times, serif !important;font-size:medium !important;background: url(https://www.boersen-zeitung.de/images/bz_logo_home.gif) no-repeat ;line-height:66px !important;background-position: center center !important;background-size: 70% !important;color:#FFFFFF !important;}
a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll{justify-content:center;}
a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {background-color:#FFFFFF !important;color:#000000 !important;font-weight:normal !important;border-color:#999999 !important;justify-content:center;}
a#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll{background-color:#FFFFFF !important;color:#000000 !important;font-weight:normal !important;border-color:#999999 !important;justify-content:center;word-wrap: break-word;}
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper{float:left !important;display:inline-flex !important; flex-direction:row !important;width:100%;justify-content: space-between;}
#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll{display:flex !important;}
#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll:nth-child(1){order:3;}
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection{display:flex !important;}
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection:nth-child(2){order:2;}
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll{display:flex !important;}
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll:nth-child(3){order:1;}
.img-responsive{ max-width: 100% !important;height: auto !important;}
.video-container {position: relative;padding-bottom: 56.25%;padding-top: 0;height: 0;overflow: hidden;}
.video-container iframe,.video-container object,.video-container embed {position: absolute;top: 0;left: 0;width: 100%;height: 100%;}
.cookiebot-opener {cursor: pointer;}

@media print {
    #CybotCookiebotDialog {display: none !important;}
}