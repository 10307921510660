.trademark {
  width: 112px;
  height: 18px;
  margin-left: auto;
  @include breakpoint(tablet) {
    margin-left: 2rem;
  }

  &-notice {
    background-color: $color-white;
    padding: .7rem 1.5rem .7rem 1.5rem;
    height: 4rem;

    @include breakpoint(tablet) {
      background-color: inherit;
      float: right;
      padding: 0 1rem 0 0;
    }
  }
}

.newsapp {

  .trademark-notice {
    padding: 0 1.5rem .5rem;
    margin: 0 -1.5rem;
    border-bottom: .3rem solid $color-white-smoke-2;
    order: 1;
    @include breakpoint(tablet) {
      order: 0;
    }
  }

  .content--stage .picture__wrapper + .trademark-notice {
    margin-top: .6rem;

    @include breakpoint(tablet) {
      margin-top: 0;
      margin-bottom: 3rem;
    }
  }
}

