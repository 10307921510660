$logoHeight: 12rem;

.theme-header {
  $self: &;

  @include breakpoint(tablet) {
    background-color: $color-white-smoke-2;
  }

  .no-list {
    .button {
      margin-top: 2.7rem;
      margin-bottom: 1.7rem;
    }
  }

  &-list {

    .theme-header {
      &__description {
        text-align: left;
        margin-bottom: .5rem;
        @include breakpoint(tablet) {
          margin-bottom: .9rem;
        }
      }

      &__headline {
        margin-top: 0;
        @include breakpoint(tablet) {
          margin-bottom: 1.2rem;
        }
      }

      &__content {
        padding-left: 3.3rem;
        padding-right: 3.3rem;
        padding-bottom: 2.7rem;
        @include breakpoint(tablet) {
          padding-left: 10%;
          padding-right: 10%;
          padding-bottom: 1.1rem;
        }
        @include breakpoint(desktop) {
          padding-left: 15%;
          padding-right: 15%;
        }
      }
    }

    .button {
      margin-bottom: 1.6rem;
    }
  }

  .picture__author--hint {
    &:after {
      background: -moz-linear-gradient(right, rgba(0, 0, 0, 0) 0%, rgba(128, 128, 128, 0.7) 100%);
      background: -webkit-linear-gradient(right, rgba(0, 0, 0, 0) 0%, rgba(128, 128, 128, 0.7) 100%);
      background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(128, 128, 128, 0.7) 100%);
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 1.5rem;
      height: 100%;
    }
  }

  &__hero-image {
    background-color: $color-white;

    & > figure {
      margin-left: -1rem;
      margin-right: -1rem;

      @include breakpoint(tablet) {
        border-top: 1rem solid $color-white;
      }
    }

    @include breakpoint(tablet) {
      background-color: inherit;
      display: flex;
      flex-direction: column;
      margin-left: -1rem;
      margin-right: -1rem;
      margin-top: 1rem;
      padding-bottom: calc(8% + 3rem);

      & > figure {
        border: 1rem solid $color-white;
        margin-left: 0;
        margin-right: 0;
      }
    }

    figcaption {
      right: 0;
      width: 100%;
      padding-right: 0;
      margin-top: 1.5rem;
      position: absolute;
      top: 0;
      transform: rotate(270deg);
      transform-origin: right;
      z-index: 1;

      .picture__author {
        background: unset;
        color: $color-white-smoke-2;
        font-size: .9rem;
        letter-spacing: .1rem;
        line-height: 1.1rem;
        padding: 0;

        @include breakpoint(desktop) {
          background: inherit;
          padding: inherit;
        }
      }

      @include breakpoint(tablet) {
        width: inherit;
        right: 7px;
      }

      @include breakpoint(desktop) {
        margin-top: 2.5rem;
        padding-right: inherit;
        right: 1.4rem;
        width: inherit;
      }
    }

    .picture__caption {
      padding: 0;
    }
  }

  &__headline {
    margin-top: 7rem;

    @include breakpoint(desktop) {
      margin-top: 0;
    }
  }

  &__theme-logo {
    position: absolute;
    width: 100%;
    text-align: center;
    top: -$logoHeight/2;

    @include breakpoint(tablet) {
      margin-left: -6%;
    }

    @include breakpoint(desktop) {
      width: auto;
      top: 8.2rem;
      left: 5.5rem;
      margin-left: 0;
    }

    img {
      height: $logoHeight;
      width: $logoHeight;

      @include breakpoint(desktop) {
        width: $logoHeight - 4rem;
        height: $logoHeight - 4rem;
      }
    }
  }

  &__content {
    font-size: 1.5rem;
    padding: 7.5rem 0 2.5rem;
    margin-left: -1rem;
    margin-right: -1rem;

    @include breakpoint(tablet) {
      font-size: 1.8rem;
      line-height: 2.4rem;
      margin: -8% 4rem;
      padding: 2rem 5.3% 1rem;
    } 

    @include breakpoint(desktop) {
      padding: 0 18rem 1rem 20rem;
      min-height: 130px;
    }
  }

  &__headline {
    margin-bottom: 1.9rem;

    @include breakpoint(tablet) {
      margin-bottom: 1.6rem;
    }

    @include breakpoint(desktop) {
      font-size: 3.4rem;
      line-height: 4.2rem;
      margin-bottom: 1.2rem;
      margin-top: 0;
    }
  }

  &__description {
    line-height: 1.9rem;
    text-align: center;

    @include breakpoint(tablet) {
      line-height: 2.4rem;
      text-align: inherit;
      margin-bottom: 1.3rem;
    }

    @include breakpoint(desktop) {
      font-size: 1.8rem;
    }
  }

  &__margin-top {
    margin-top: 0;

    @include breakpoint(desktop) {
      margin-top: -15%;
    }
  }

  &__padding-top {
    padding-top: 1.6rem;

    @include breakpoint(tablet) {
      padding-top: 2rem;
    }
  }

  &__special-text {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin: 2.3rem 0 1rem 0;
    text-align: center;

    @include breakpoint(desktop) {
      font-size: 1.8rem;
      margin: 2.2rem 0 1.8rem 0;
    }
  }

  &__list-items {
    margin-bottom: 1rem;

    @include breakpoint(tablet) {
      margin-bottom: 2rem;
    }
  }

  &-podcast {
    #{$self}__headline {
      margin-top: .9rem;
      margin-bottom: 1.5rem;

      @include breakpoint(desktop) {
        font-size: 3.4rem;
        margin-bottom: 2.2rem;
      }
    }

    #{$self}__description {
      font-family: $typeface-font;
      font-size: 1.5rem;

      @include breakpoint(desktop) {
        font-size: 1.8rem;
      }
    }
  }
}