/*
 * Old code, check if still needed
 */
$ci-font-path: "../font/custom-icons" !default;

// RPU 20200115 raised mobile design to mini tablets
$breakpoint-tablet: 48.063rem; // rem(769)
$breakpoint-tablet-minus-one: 48rem; // rem(768);
$breakpoint-desktop: 60.625rem; // rem(970)
$breakpoint-desktop-minus-one: 60.5625rem; // rem(969)
$breakpoint-socialbar: 67.5rem; // rem(1080)

$vertical-space-desktop: 2.5rem; // rem(40)

$content-max-width: 60.625rem; // rem(970)
$content-padding-mobile: 1.25rem; // rem(20)
$content-padding-desktop: .9375rem; // rem(15)

$z-index-dropdown: 1000;
$z-index-header: 2000;
$z-index-side-navigation: 3000;
$z-index-popup: 4000;

/**
 + NOZ part
 */

/**
 * Grid
 */
$content--group-padding: 1rem;
$content--group-margin-bottom: 3rem;

/**
 * Screen sizes
 */
$width_tablet: "481px";
$width_desktop: "769px";

$max-width_desktop: 114rem;

$content-width: 72.4rem;

/**
 * Animation Timing
 */
$mainNavTimingFast: .7s;
$mainNavTiming: 1s;
$mainNavLiOffset: -4rem;
$mainNavLiOffsetRight: 4rem;
$mainNavLiCount: 25;

$mainNavChildTiming: .7s;

// content spaces
$content-space-mobile: 1rem;
$content-space-tablet: 2.8rem;
$content-space-desktop: 2.2rem;

/**
 * Aspect Ratio
 */
$aspect-4-3: 75%;
$aspect-16-9: 56.25%;
$aspect-hero: 41.91%;

$aspect-jumbotron: 85%;
$aspect-jumbotron-tablet: 42%;
$aspect-jumbotron-desktop: 42.86%;