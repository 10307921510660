.d {

  &-block {
    display: block;
  }

  &-inline-block {
    display: inline-block;
  }

  &-none {
    display: none;
  }

  @include breakpoint(tablet) {
    &-t-inline-block {
      display: inline-block;
    }
  }
}