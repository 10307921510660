.google-map {
  $self: &;

  @include breakpoint(tablet) {
    font-size: 1.8rem;
  }

  label {
    line-height: inherit;
    margin-bottom: inherit;
    font-weight: inherit;
    cursor: pointer;
    
    @include breakpoint(tablet) {
      font-size: 1.8rem;
    }
  }

  &__headline {
    font-size: 1.6rem;
    min-height: 4.4rem;
    padding: 1.4rem 1.3rem 1.3rem 1.4rem;
    margin-bottom: .4rem;
    display: flex;
    justify-content: space-between;

    @include breakpoint(tablet) {
      height: 5.5rem;
      padding: 1.7rem 1.5rem 1.5rem 1.7rem;
    }

    svg {
      fill: $color-pacific-blue;
      position: relative;
      top: .2rem;
    }
  }

  input[type=checkbox]:not(:checked) {
    & ~ #{$self}__box {
      display: none;
    }

    & ~ .map-label {
      .google-map__headline {
        svg {
          transform: rotate(90deg);
        }
      }
    }
  }

  input[type=checkbox]:checked {
    & ~ #{$self}__box {
      display: block;
    }

    & ~ .map-label {
      .google-map__headline {
        svg {
          transform: rotate(-90deg);
        }
      }
    }
  }

  &__box {
    padding: 1rem 1rem 1.2rem 1rem;

    @include breakpoint(tablet) {
      padding: 1.6rem 1.7rem 1.7rem 1.7rem;
    }

  }

  &__img {
    border: 1px solid $color-nero-20;
    width: 100%;
  }
}