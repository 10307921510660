.u-image {
    img {
        display: block;
        height: auto;
        width: 100%;
    }

    &--cover img {
        height: 100%;
        object-fit: cover;
        max-width: none;
        width: 100%;
    }

    &--cover-tablet-and-larger img {
        @media (min-width: $breakpoint-tablet) {
            height: 100%;
            object-fit: cover;
            max-width: none;
            width: 100%;
        }
    }
}


/**
 * NOZ
 */

img {
    max-width: 100%;
    height: auto;
}

.round {
    border-radius: 50%;
}