.curved-corner {

  &-topleft,
  &-topright {
    position: absolute;
    bottom: -25px;
    width: 25px;
    height: 25px;
    overflow: hidden;
  }

  &-topleft {
    left: 50%
  }

  &-topright {
    right: 50%
  }

  &-topleft:before,
  &-topright:before {
    position: absolute;
    content: "";
    display: block;
    width: 200%;
    height: 200%;
    top: 0;
    border-radius: 50%;
  }

  &-topleft:before {
    left: 0;
    box-shadow: -25px -25px 0 0 $color-white;
  }

  &-topright:before {
    right: 0;
    box-shadow: 25px -25px 0 0 $color-white;
  }
}