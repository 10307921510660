.content-element {
  .fb-post {
    @include breakpoint(maxTablet) {
      width: 100vw!important;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      display: block!important;
      max-width: 100vw;
      overflow: hidden;
    }

    & > span {
      @include breakpoint(maxTablet) {
        width: 100vw !important;
      }

      @include breakpoint(tablet) {
        max-width: 100%;
      }
    }
  }
}