.job-box {
  .box-name {
    font-size: 1.8rem;
    line-height: 1.8rem;
    padding: 1.7rem 1.98rem 1.5rem 1.4rem;
    position: relative;

    @include breakpoint(tablet) {
      font-size: 3.4rem;
      line-height: 3.6rem;
      padding: 1.7rem 1.5rem 1.7rem 1.1rem;
    }

    .icon-jobs {
      max-width: 13rem;
      max-height: 4rem;
      position: absolute;
      right: 1.5rem;
      top: 50%;
      transform: translateY(-50%);

      @include breakpoint(tablet) {
        max-width: 10.6rem;
        max-height: 4.3rem;
        right: 1.5rem;
      }
    }
  }

  .slider {
    border: none;

    &__element {
      margin-left: 1rem;
      padding-top: 2.3rem;
      scroll-margin: 2rem;
      scroll-snap-align: start;
      width: 26rem;

      &:first-of-type {
        margin-left: 1.4rem;
        scroll-margin-left: 1.4rem;

        @include breakpoint(tablet) {
          margin-left: .1rem;
          scroll-margin-left: .1rem;
        }
      }

      @include breakpoint(tablet) {
        padding-top: 3.5rem;
      }

      &__img-wrapper {
        width: 100%;
        height: 6rem;

        img {
          max-height: 6rem;
        }
      }

      &__img-placeholder {
        height: 4.6rem;
        width: 10.2rem;
      }

      &__subline {
        font-size: 1.5rem;
        line-height: 1.8rem;
        margin-top: .5rem;

        @include breakpoint(tablet) {
          line-height: 1.8rem;
          margin-top: .6rem;
        }
      }

      &__headline {
        color: $color-nero;
        line-height: 2rem;
        margin-bottom: .6rem;
        margin-top: .2rem;
        word-break: break-word;
        @include breakpoint(tablet) {
          font-size: 1.9rem;
          line-height: 2.3rem;
          margin-top: unset;
        }
      }

      &__info {
        font-size: 1.4rem;
        line-height: 2.4rem;
        color: $color-comet;

        @include breakpoint(tablet) {
          font-size: 1.5rem;
          line-height: 1.8rem;
        }
      }
    }

    &-box__more {
      border-top: .5rem solid #f2f2f2;
      margin: 0 -1rem;

      @include breakpoint(tablet) {
        border: none;
        margin: 0;
      }
    }
  }
}