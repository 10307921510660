.author-overview {
    margin: 1rem;

    @include breakpoint(tablet) {
        margin: 4.4rem 1.2rem 1.2rem;
    }

    &__anchor {
        margin-bottom: 2rem;

        &__wrapper {
            overflow: scroll;

            &__element {
                font-size: 2.2rem;
                line-height: 2.6rem;
                margin-right: .5rem;
            }
        }
    }

    &__character {
        &__header {
            width: 100%;
            padding-bottom: 1.8rem;
            border-bottom: .1rem solid $color-dim-gray;
            margin-bottom: 1.3rem;
            font-size: 2.2rem;
            line-height: 2.6rem;

            @include breakpoint(tablet) {
                margin-bottom: 4rem;
            }
        }

        &__content {
            margin-bottom: 1.6rem;

            @include breakpoint(tablet) {
                margin-bottom: 2.8rem;
            }
        }
    }
}