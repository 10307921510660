.paywall-audio {
  background-color: $color-prussian-blue;
  margin-top: 1.5rem;
  text-align: center;
  position: relative;
  width: 100%;

  &.closeable {
    height: 100vh;
    left: 0;
    margin: 0;
    overflow: scroll;
    position: fixed;
    top: 0;
    width: 100vw;
  }

  &__wrapper {
    margin: 0 auto;
    width: 30rem;
    padding-bottom: 1.5rem;

    @include breakpoint(desktop) {
      width: 42rem;
    }
  }

  &__triangle {
    border-left: 1.75rem solid transparent;
    border-right: 1.75rem solid transparent;
    border-top: 1.8rem solid $color-white;
    height: 0;
    left: calc(50% - .8rem);
    position: absolute;
    width: 0;
  }

  &__close {
    cursor: pointer;
    height: 2rem;
    position: absolute;
    right: .5rem;
    top: .5rem;
    width: 2rem;

    @include breakpoint(tablet) {
      right: 1.5rem;
      top: 1.5rem;
    }

    @include breakpoint(desktop) {
      right: 4.5rem;
      top: 5.5rem;
    }

    &:before,
    &:after {
      position: absolute;
      content: ' ';
      height: 2rem;
      width: .2rem;
      background-color: $color-white;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &__no-subscriber {
    padding: 3.5rem 1rem 2rem 0;

    @include breakpoint(tablet) {
      font-size: 2.1rem;
      line-height: 2.5rem;
    }

    @include breakpoint(desktop) {
      font-size: 3.4rem;
      line-height: 4.2rem;
    }
  }

  &__abo-info {
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
  }

  &__login {
    font-size: 1.6rem;
    line-height: 1.8rem;
    margin: 1.5rem 0 2rem;
    padding: 1rem 2rem;

    @include breakpoint(desktop) {
      margin-bottom: 4.5rem;
    }
  }

  &__content {
    background-color: $color-white;
    border-radius: .4rem;
    overflow: hidden;
    padding-bottom: 1.8rem;
    text-align: left;
    width: 30rem;

    @include breakpoint(desktop) {
      margin: 0 auto;
    }
  }

  &__top-heading {
    background-color: $color-deep-sky-blue;
    padding: .75rem 0 .75rem 1.5rem;

    @include breakpoint(tablet) {
      font-size: 1.8rem;
      line-height: 2.4rem;
    }
  }

  &__hero {
    height: 34.7rem;
    padding: 19rem 2.8rem 0 2.5rem;

    &--title {
      font-size: 3rem;
      line-height: 3.1rem;
      text-transform: uppercase;
      color: $color-deep-sky-blue;
    }

    &--info {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }

  &__benefits {
    font-size: 1.6rem;
    line-height: 2.5rem;
    margin-bottom: 2rem;
  }

  &__button {
    &--test {
      display: block;
      line-height: 1.8rem;
      margin: 0 auto 1.5rem auto;
      padding: 1.3rem 4.8rem;
      width: 228px;
    }

    &--paypal {
      margin: 0 auto 2.2rem auto;
      width: 22.8rem;

      img {
        width: 22.8rem;
      }
    }
  }

  &__info {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}

.paywall-special--text-30{
  font-size: 3rem;
  line-height: 3rem;
  color: #00AFEA;
}

.paywall-special--content {
  position: absolute;
  bottom: 0;
  padding: 0 4.5rem 8.2rem 3rem;
  color: #fff;
  left: 0;
}