.slider-box {
  line-height: 2.8rem;

  @include breakpoint(tablet) {
    font-size: 2rem;
    line-height: 2.8rem;
    margin-top: .7rem;
  }

  p {
    margin: unset;
  }

  .box-name {
    margin-bottom: .2rem;
  }

  .slider {
    //margin-bottom: .2rem;
    border-bottom: .2rem solid $color-white-smoke-2;
    padding: .1rem 0;
    @extend .position-relative;

    @include breakpoint(desktop) {
      &:before,
      &:after {
        content: "";
        display: none;
        height: 100%;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 10%;
        @include gradient-white-transparent-r_l();
      }

      &:before {
        left: 0;
        transform: scaleX(-1);
        z-index: 1;
      }

      &:after {
        right: 0;
      }

      border-bottom: unset;

      .next-bubble,
      .prev-bubble {
        height: 4.2rem;
        width: 4.2rem;
      }

      .prev-bubble__arrow-left,
      .next-bubble__arrow-right {
        fill: $color-white;
        height: 1.242rem;
        position: absolute;
        stroke-width: .2rem;
        width: .621rem;
      }

      .prev-bubble__arrow-left {
        left: 40%;
        top: calc(50% - .6rem);
        transform: scaleX(-1);
      }

      .next-bubble__arrow-right {
        left: 45%;
        top: 50%;
        transform: translateY(-50%);
      }

      &.has-prev {
        .prev-bubble {
          display: block;
        }

        &:before {
          display: block;
        }
      }

      &.has-next {
        .next-bubble {
          display: block;
        }

        &:after {
          display: block;
        }
      }
    }

    &__elements:not(.weather-box__day-slider--wrapper) {
      display: flex;
      margin: 0 0 .2rem 0;
      overflow-x: auto;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
      scroll-snap-type: x mandatory;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      & > div {
        scroll-snap-align: start;
      }

      @include breakpoint(tablet) {
        margin: 0 0 2.7rem 0;
      }
    }

    &__element {
      width: 28rem;
      flex-shrink: 0;

      &:first-of-type {
        margin-left: 0;
        scroll-margin-left: 0;
      }

      @include breakpoint(tablet) {
        margin-left: 1rem;
        width: 100%;
      }
    }

    @include breakpoint(tablet) {
      margin-bottom: 0;
    }
  }

  .icon--arrow {
    margin: .8rem 0 .5rem .9rem;

    @include breakpoint(tablet) {
      margin-top: .8rem;
    }
  }

  .content-switch {
    font-size: 1.4rem;
    line-height: 2.1rem;
    margin: 1.2rem 1rem 1.4rem 0;
    @extend .text-color__comet;

    @include breakpoint(tablet) {
      margin: .8rem 1.5rem 0 0;
    }

    &:hover,
    &:visited {
      @extend .text-color__comet;
    }
  }

  .three-dots {
    display: inherit;
    margin-bottom: 1.5rem;

    @include breakpoint(tablet) {
      margin: 3.4rem auto 3.1rem;
    }
  }

  &__more {
    background-color: $color-white;
    font-size: 1.4rem;
    line-height: 1.8rem;
    padding: .8rem 1.28rem 1.18rem 0;
    text-align: right;

    @include breakpoint(maxTablet) {
      padding-right: 1rem;
    }

    @include breakpoint(desktop) {
      background-color: unset;
      padding-right: 0;
    }
  }

  @include breakpoint(tablet) {
    position: relative;

    .slider-box__more {
      bottom: 1.5rem;
      position: absolute;
      right: .2rem;
    }
  }
}

