.search-result {

  &__content--premium {
    padding-right: 1rem;
  }

  &__headline--premium {
    @include breakpoint(tablet) {
      h4 {
        font-size: 2.2rem;
        line-height: 2.6rem;
      }
    }
  }

  &--premium.grid {
    margin: 0 -1rem 1.5rem -1rem;

    @include breakpoint(tablet) {
      margin: 0 0 3rem;
      padding: 1.8rem 0 1.8rem 1rem;
    }
  }

  &__img {
    &--premium {
      margin-bottom: .6rem;
      width: 100%;

      img {
        width: 100%;
      }

      @include breakpoint(tablet) {
        margin-bottom: 0;
        order: 2;
        text-align: right;
        width: 50%;
      }

      @include breakpoint(desktop) {
        max-width: 50%;
        width: 35.8rem;
      }
    }
  }

  @include breakpoint(tablet) {
    order: 1;
  }

  &__subline{
    &--premium {
      font-size: 1.5rem;
      line-height: 1.8rem;

      @include breakpoint(tablet) {
        font-size: 1.8rem;
        line-height: 2.8rem;
      }
    }
  }

  &__text {
    font-size: 1.6rem;
    line-height: 2.2rem;

    &--premium {
      line-height: 2.2rem;

      @include breakpoint(tablet) {
        font-size: 1.6rem;
        line-height: 2.2rem;
      }
    }
  }
}