/*!
 * smartbanner.js v1.16.0 <https://github.com/ain/smartbanner.js>
 * Copyright © 2020 Ain Tohvri, contributors. Licensed under GPL-3.0.
 */
.smartbanner {
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
  font-family: Helvetica, sans, sans-serif;
  width: 100%;
  height: 70px;
  background: $color-white-smoke-1;
  z-index: 10000;

  &__exit {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    margin: 0 6px;
    height: 70px;
    border: 0;
    text-align: center;
  }

  &__exit:before,
  &__exit:after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 8px;
    width: 2px;
    height: 14px;
    margin-top: -5px;
    background: #777;
  }

  &__exit:active,
  &__exit:hover {
    color: #eee;
  }

  &__exit:before {
    transform: rotate(45deg);
  }

  &__exit:after {
    transform: rotate(-45deg);
  }

  &__icon {
    position: absolute;
    top: 10px;
    left: 30px;
    border-radius: 6px;
    width: 52px;
    height: 52px;
    background-size: 52px 52px;
  }

  &__info {
    position: absolute;
    top: 10px;
    left: 95px;
    display: flex;
    overflow-y: hidden;
    width: 50%;
    height: 52px;
    align-items: center;
    line-height: 19px;
    letter-spacing: 0.08px;
  }

  &__info__title {
    font-size: 14px;
    color: $color-nero;
  }

  &__info__author,
  &__info__price {
    font-size: 12px;
    color: $color-comet;
  }

  &__button {
    position: absolute;
    top: 22px;
    right: 15px;
    z-index: 1;
    display: block;
    min-width: 10%;
    border-radius: 5px;
    color: $color-pacific-blue;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
  }

  &__button:visited {
    color: $color-pacific-blue;
  }

  &__button__label {
    text-align: center;
  }

  // ANDROID STYLES
  &.smartbanner--android {

    .smartbanner {

      &__info {
        color: $color-nero;
      }

      &__button {
        top: 22px;
        font-size: 13px;
      }

      &__button:active,
      &__button:hover {
        background: none;
      }

      &__button:visited {
        color: #fff;
      }

      &__button__label {
        display: block;
        padding: 1px 9px;
        background: #689F39;
        text-align: center;
        border-radius: 2px;
        text-transform: uppercase;
        color: #fff;
      }

      &__button__label:active,
      &__button__label:hover {
        background: #689F39;
      }
    }
  }
}