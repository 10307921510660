.float {
  &--r {
    float: right;
  }

  &--l {
    float: left;
  }

  &--clear {
    clear: both;
  }

  &--clearfix::before,
  &--clearfix::after {
    content: " ";
    display: table;
  }

  &--clearfix::after {
    clear: both;
  }
}