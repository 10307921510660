.article--type- {
  &,
  &news,
  &gallery,
  &video,
  &podcast,
  &liveticker,
  &with-logo,
  &advertorial,
  &opener {
    &.article__teaser--opener {
      .article__teaser__subline {
        padding: 0;

        @include breakpoint(desktop) {
          margin-bottom: 0;
        }
      }

      .article__teaser__headline {
        padding-left: 0;
        padding-right: 0;

        @include breakpoint(desktop) {
          line-height: 3rem !important;
        }
      }

      .article__teaser__intro,
      .article__teaser__info {
        padding-left: 0;
        padding-bottom: 0;
      }

      .interferer {
        top: calc(100% - .9rem - 3rem - 1.2rem);
        right: 1.2rem;
      }

      .article__teaser__image {
        height: auto;

        @include breakpoint(maxTablet) {
          margin: 0 -1rem;
        }
      }

      .article__teaser__info {
        @include breakpoint(desktop) {
          font-size: 1.6rem;
          line-height: 2rem;
        }
      }

      & ~ .article__teaser__separator {
        padding-top: .5rem;
      }
    }
  }

  &comment:not(.article__teaser--no-image) {
    &.article__teaser--opener {

      .article__teaser__info {
        line-height: 1.8rem;
      }

      @include breakpoint(maxTablet) {
        .article__teaser__wrapper {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;

          .article__teaser__headline {
            font-size: 1.9rem;
            font-style: italic;
            font-weight: normal;
            order: 1;
            padding-right: .5rem;
            width: calc(100% - 6rem);

            @include breakpoint(tablet) {
              padding-right: 1rem;
            }
          }

          .article__teaser__subline {
            width: 100%;
          }

          .article__teaser__author-img {
            display: block;
            order: 2;
            position: relative;
            top: -15px;

            img {
              height: 6rem;
            }
          }
        }
      }

      @include breakpoint(desktop) {
        .article__teaser__headline {
          font-style: italic;
          font-weight: normal;
          line-height: 3rem;
          margin-bottom: 2rem;
          order: 1;
        }

        .article__teaser__intro {
          padding-bottom: 1.5rem;
        }

        .article__teaser__info {
          font-size: 1.6rem;
          line-height: 2rem;
        }

        .article__teaser__author-img {
          display: block;
          float: right;
          height: 6.4rem;
          width: 6.4rem;

          img {
            width: 6.4rem;
          }
        }
      }
    }
  }

  &column {
    &.article__teaser--opener {
      .article__teaser__headline {
        font-size: 1.9rem;
        font-style: italic;
        font-weight: normal;
        line-height: 3rem;
        order: 1;

        @include breakpoint(desktop) {
          font-size: 2.6rem;
        }
      }

      .article__teaser__column-logo {
        border-radius: .5rem;
        height: 6rem;
        width: 6rem;

        img {
          border-radius: .5rem;
        }
      }

      .column-logo--vfl {
        background-color: $color-titan-white;
        position: relative;
      }

      @include breakpoint(maxTablet) {
        .article__teaser__wrapper {
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;

          .article__teaser__headline {
            order: 1;
            width: calc(100% - 6rem);
          }

          .article__teaser__intro {
            order: 3;
          }

          .article__teaser__column-logo {
            order: 2;
            position: relative;
            top: .7rem;
          }

          .article__teaser__info {
            margin-top: .3rem;
          }

          .article__teaser__info--col-2-row--show {
            display: none;
          }
        }
      }

      @include breakpoint(desktop) {
        .article__teaser__wrapper {
          align-content: flex-start;
          display: flex;
          flex-flow: row;
          flex-wrap: wrap;

          .article__teaser__headline {
            font-style: italic;
            font-weight: normal;
            line-height: 3rem;
            margin-bottom: 2rem;
            order: 1;
          }

          .article__teaser__intro {
            margin-bottom: 2.5rem;
            order: 2;
          }

          .article__teaser__column-logo {
            order: 4;
          }

          .article__teaser__info--col-2-row--show {
            display: table;
            color: $color-comet;
            font-size: 1.6rem;
            line-height: 2rem;
            order: 3;
            width: calc(100% - 6rem);
            > div {
              vertical-align: middle;
              display: table-cell;
            }

            .commentaries {
              display: block;
            }
          }

          .article__teaser__info {
            order: 5;
            display: none;
          }
        }
      }
    }
  }
}

.with-logo.article__teaser--opener {
  .interferer-logo {
    height: 4rem;
    left: 2rem;
    top: 1rem;
    width: 4rem;
  }

  @include breakpoint(tablet) {
    .interferer-logo {
      height: 5.2rem;
      left: unset;
      right: 2.5rem;
      top: 3.5rem;
      width: 5.2rem;
    }
  }
}