.related-content {

  @include breakpoint(tablet) {
    padding-bottom: 1.5rem;
  }

  &--item {
    padding: .7rem 0 0;

    @include breakpoint(tablet) {
      padding: 2.5rem 0 0;
    }

    &:last-child {
      .border-dotted-b {
        border-bottom: none;
      }
    }

    @include breakpoint(desktop) {
      &:nth-child(odd):nth-last-child(-n+2) {
        .border-dotted-b {
          border-bottom: none;
        }
      }
    }
  }

  &--headline {
    letter-spacing: 0.3px;
    line-height: 1.8rem;
  }

  &--image {
    margin-top: .4rem;
    margin-left: auto;
    width: 9rem;
    padding-bottom: 1rem;
    @include  breakpoint(desktop) {
      padding-bottom: 0;
    }

    @include breakpoint(tablet) {
      width: 12rem;
    }
  }

  // overwrite only for this element in tablet view
  @include breakpoint(tablet) {
    .h5 {
      font-size: 1.6rem;
      line-height: 2rem;
    }

    .size--m-4 {
      font-size: 1.5rem;
    }
  }

  article figure:not(.banner) {
    margin: 0;
  }

  .italic {
    font-weight: 400;
  }
}