.gallery-teaser {
  margin: 0 1rem;
  max-width: 72.4rem;

  @include breakpoint(tablet) {
    margin: 0;
  }

  &__kicker {
    font-size: 1.6rem;
    letter-spacing: .028rem;
    line-height: 1.8rem;
    margin-bottom: 1.2rem;

    @include breakpoint(tablet) {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  figure,
  figure:not(.banner) {
    margin: 0;
  }

  &__img {
    width: 100%;
  }

  &__title {
    font-size: 1.9rem;
    line-height: 2.3rem;
    margin: .64rem 0 .85rem 0;

    @include breakpoint(tablet) {
      font-size: 2.6rem;
      line-height: 3.2rem;
      margin: 1.3rem 0 2rem 0;
    }
  }

  &__bubble {
    font-size: 1.4rem;
    height: 3rem;
    line-height: 1.8rem;
    padding: .3rem 1rem .6rem;
    margin: .7rem;

    @include breakpoint(tablet) {
      margin: 0 2rem 2rem 0;
    }
  }

  .interferer__icon {
    bottom: -.1rem;
    height: 1.7rem;
    margin-top: .15rem;
    margin-right: .96rem;
    position: relative;
    top: .2rem;
    width: 1.9rem;
  }
}