form[name="search"] {
  svg.icon {
    cursor: pointer;
  }
}

.content--group__search {
  .search {
    margin: 2.5rem 0;
    width: 100%;
  }

  form[name="search"] .input--svg {
    top: 3.6rem;
  }
}