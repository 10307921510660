//////////////////////////
// "LORA"
// "https://google-webfonts-helper.herokuapp.com/fonts/lora?subsets=latin"
//////////////////////////

@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local(''),
  url('/static/font/lora/regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/static/font/lora/regular.woff') format('woff'), /* Modern Browsers */
  url('/static/font/lora/regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/static/font/lora/regular.svg#Lora') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: local(''),
  url('/static/font/lora/700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/static/font/lora/700.woff') format('woff'), /* Modern Browsers */
  url('/static/font/lora/700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/static/font/lora/700.svg#Lora') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: local(''),
  url('/static/font/lora/italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/static/font/lora/italic.woff') format('woff'), /* Modern Browsers */
  url('/static/font/lora/italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/static/font/lora/italic.svg#Lora') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Lora';
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: local(''),
  url('/static/font/lora/italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('/static/font/lora/italic.woff') format('woff'), /* Modern Browsers */
  url('/static/font/lora/italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/static/font/lora/italic.svg#Lora') format('svg'); /* Legacy iOS */
}

//////////////////////////
// "Source Sans Pro"
//////////////////////////

@font-face {
  font-family: "Source Sans Pro";
  src: url("/static/font/SourceSansPro-Bold.woff2") format("woff2"),
  url("/static/font/SourceSansPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("/static/font/SourceSansPro-Regular.woff2") format("woff2"),
  url("/static/font/SourceSansPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("/static/font/SourceSansPro-SemiBold.woff2") format("woff2"),
  url("/static/font/SourceSansPro-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

$base-font: "Lora", serif;
$typeface-font: "Source Sans Pro";

.typeface-font {
  font-family: $typeface-font;
}

.base-font {
  font-family: $base-font;
}