$color-black: #000000;
$color-white: #FFFFFF;

$color-white-smoke-1: #F0F0F0;
$color-white-smoke-2: #F2F2F2;
$color-white-smoke-3: #C5C5C5;

$color-silver: #BEBEBE;
$color-nobel: #9D9D9D;
$color-comet: #69696C;
$color-whisper: #E6E6E6;
$color-whisper-2: #EDEDED;
$color-gainsboro: #DDDDDD;
$color-dim-gray: #707070;
$color-sonic-silver: #8E8E8E;

$color-red: #FF0000;
$color-danger: #F53434;
$color-free-speech-red: #CC0000;
$color-red2: #FF0A0A;

$color-warning: #FFF500;

$color-nero: #2A2A2A;
$color-nero-60: #808080;
$color-nero-50: #949494;
$color-nero-20: #D4D4D4;
$color-titan-white: #DCD1E3;
$color-very-light-grey: #CECECE;

$color-fb-blue: #3B5998;
$color-whatsapp-green: #67D449;
$color-twitter-blue: #41A1F2;

$color-dark-green: #006633;
$color-green: #00932D;

$color-xl-blue: #183B68;
$color-prussian-blue: #00386C;
$color-prussian-blue-80: #335F89;
$color-prussian-blue-60: #6687A6;
$color-prussian-blue-40: #99AFC4;
$color-prussian-blue-20: #CCD7E1;
$color-prussian-blue-10: #E5EAF0;

$color-alice-blue: #E5F3FA;
$color-link-water: #CCD7E1;
$color-endeavour: #335F89;
$color-deep-sky-blue: #00AFEA;

$color-pacific-blue: #0088CC;
$color-pacific-blue-80: #339FD6;
$color-pacific-blue-60: #66B7E0;
$color-pacific-blue-40: #99CFEB;
$color-pacific-blue-20: #CCE7F5;
$color-pacific-blue-10: #E5F3FA;
$color-pacific-blue-05: #F1F9FC;

$color-cream: #F2F7DD;

$color-transparent: rgba(255, 255, 255, 0);
$color-bright-transparent: rgba(255, 255, 255, 0.8);
$color-dark-transparent: rgba(0, 0, 0, .8);

