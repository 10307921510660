.team-schedule {

  &__header {
    border-width: .3rem;
    @extend .size--l-0;
    line-height: 2.6rem;
    padding: .8rem 0 1rem 1rem;

    @include breakpoint(tablet) {
      padding: 2.2rem 0 2.2rem 1rem;
    }

    &--part {
      @include breakpoint(tablet) {
        display: inline-block;
      }
    }
  }

  &__day {
    padding: 1.6rem 0 1rem;

    @include breakpoint(tablet) {
      font-size: 1.8rem;
      line-height: 1.6rem;
    }
  }

  &__encounters {
    padding: 0 .95rem 0 1.05rem;
  }

  &__ticker-link {
    color: $color-comet;
    @extend .size--m-2;
    margin-top: .3rem;
    text-align: right;

    @include breakpoint(tablet) {
      font-size: 1.4rem;
      line-height: 1.8rem;
      text-align: center;
    }
  }

  .encounter {

    &__details {
      border-top: unset;
    }
  }
}