.newsticker-box,
.newsticker-box--fixed {
  background-color: $color-white;

  &__header {
    font-size: 2.1rem !important;
    margin-left: 0 !important;
    max-width: 100%;
    @include breakpoint(tablet) {
      line-height: 2.3rem !important;
      padding: 1.8rem 1rem 1.8rem 0!important;
    }
  }

  @include breakpoint(tablet) {
    margin: 0;
  }

  .box-name {
    font-size: 1.6rem;
    margin-left: 1rem;
    padding: 1.5rem 1.5rem 1.5rem 0;
    display: flex;
    align-items:center;

    @include breakpoint(tablet) {
      font-size: 2rem;
      margin-left: 0;
    }
  }

  .slider__elements {
    padding: 1rem 0;
  }

  .slider {
    border-bottom: none;
    background-color: $color-pacific-blue-10;

    &__element {
      padding-right: 1.15rem;
      padding-left: 2rem;
      scroll-margin-left: 2rem;
      width: 100%;
      @include breakpoint(tablet) {
        padding-left: 0;
        margin-left: 0;
        scroll-margin-left: 1rem !important;
      }

      .kicker {
        font-size: 1.5rem;
        line-height: 1.8rem;
      }

      .headline {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }

    &-box__more {
      margin: 0 1rem 0 0;
      padding: 0;
      position: unset;

      @include breakpoint(tablet) {
        bottom: 0;
        margin: 0;
      }

      .newsticker-box__link {
        @extend .text-color__comet;
        margin: .5rem 0;
      }
    }
  }

  &__time {
    display: flex;
    font-size: 1.4rem;
    line-height: 1.8rem;

    span {
      margin-right: .95rem;
    }

    hr {
      flex-grow: 1;
      border: 0;
      border-bottom: thin dotted $color-silver;
      margin-top: 9px;
    }
  }

  &__link {
    display: block;
    text-align: right;
    padding-bottom: 2.7rem;
  }

  .three-dots {
    margin: 0 auto 2rem;
  }
}

.newsticker-box--fixed {
  .box-name {
    margin-bottom: 0;
  }

  .slider {
    &:before,
    &:after {
      visibility: hidden;
    }
  }

  .slider__elements {
    flex-direction: column;
    margin: 0 !important;
    padding: 2rem;
  }

  .slider-box__more {
    margin: 1rem 1.5rem 0 0;

    .newsticker-box__link {
      padding-bottom: 1rem;
    }
  }

  .slider__element {
    position: relative;
    padding: 0 0 2rem 3.8rem;
    margin-left: 0;

    &:after {
      position: absolute;
      top: 5px;
      left: -1px;
      content: '';
      border-left: 1px dotted $color-silver;
      height: 100%;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &:last-child:after {
      display: none;
    }

    &:before {
      position: absolute;
      content: '';
      display: block;
      top: 5px;
      left: -5px;
      width: 9px;
      height: 9px;
      background: $color-prussian-blue;
      border-radius: 50%;
      z-index: 1;
    }

    .kicker {
      font-size: 1.5rem;
      line-height: 1.8rem;
    }

    .headline {
      line-height: 2rem;
    }
  }

  .three-dots {
    display: none;
  }
}

.newsticker-box {

  .slider__elements {
    margin-bottom: 2rem !important;
  }

  .slider__element {
    position: relative;
    padding-top: 2rem;
    padding-left: 1rem;
    width: 26rem;

    &:before {
      position: absolute;
      content: '';
      display: block;
      top: 1px;
      left: 10px;
      width: 9px;
      height: 9px;
      background: $color-prussian-blue;
      border-radius: 50%;
      z-index: 1;
    }

    &:after {
      content: "";
      position: absolute;
      top: 5px;
      left: 30px;
      width: calc(100% - 30px);
      border-top: 1px dotted #bebebe;
    }

    &:nth-last-child(-n + 3):after {
      display: none;
    }
  }
}

.slider {
  &--chat {
    padding-bottom: 1rem;

    .icon--newsticker {
      position: absolute;
      right: 10px;
      top: 13px;
      width: 53px;
      height: 38px;
    }

    .newsticker-box__header {
      position: relative;
      padding-right: 8rem !important;
    }
  }

  &-box__more {
    margin: 0;
  }
}