.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  &__align-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
  }

  &__space-between {
    justify-content: space-between;
  }

  &__align-item-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  &__order {

    @for $i from 1 through 3 {
      &--#{$i} {
        order: $i;
      }
    }
  }
}

.flex-align-items-center-direction-column {
  display: flex;
  align-items: center;
  flex-direction: column;
}