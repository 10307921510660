.paywall-extended {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  display: block;
  z-index: 5000;

  &--placeholder {
    height: 130vh;
  }

  &--layer {
    position: relative;
    padding: 0 1.5rem 3rem;
    background-color: #fff;
    -webkit-box-shadow: 0 0 30px 0 rgba(0,0,0,0.75);
    -moz-box-shadow: 0 0 30px 0 rgba(0,0,0,0.75);
    box-shadow: 0 0 30px 0 rgba(0,0,0,0.75);
    z-index: 10;
  }

  &--layer:after {
    content: "";
    position: absolute;
    top: -35vh;
    left: 0;
    height: 35vh;
    width: 100%;
    background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 15%);
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 75%,rgba(255,255,255,1) 15%);
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 75%,rgba(255,255,255,1) 15%);
    z-index: 5500;
  }

  &--scala {
    position: relative;
    padding: 30px 15px;
    margin-top: -90px;
    background-color: $color-pacific-blue-10;
    z-index: 5;
  }

  &--wrap {
    height: 300px;
    overflow: hidden;
  }

  &--padding {
    padding: 90px 15px 50px;
  }

  &--headline {
    font-size: 1.9rem;
    line-height: 2.3rem;

    @include breakpoint(tablet) {
      font-size: 2.6rem;
      line-height: 3.2rem;
    }
  }

  .carousel-touch:after {
    background: transparent -webkit-linear-gradient(left, rgba(228, 243, 249, 0) 0%, rgb(228, 243, 249) 100%) 0 0 no-repeat padding-box;
    background: transparent linear-gradient(90deg, rgba(228, 243, 249, 0) 0%, rgb(228, 243, 249) 100%) 0 0 no-repeat padding-box;
  }

  // OVERWRITES
  .container {
    background-color: transparent;
  }
}