.three-dots {
  width: 2.7rem;
  bottom: .5rem;
  margin: 1.5rem auto 2rem auto;

  @include breakpoint(tablet) {
    display: none;
  }

  .dot {
    background-color: $color-comet;
    content: "";
    width: .5rem;
    height: .5rem;
    display: block;
    @extend .border-round;
    @extend .d-block;

    &:before {
      background-color: $color-silver;
      left: 1rem;
      @extend .dot;
      @extend .position-relative;
    }

    &:after {
      background-color: $color-silver;
      left: 2rem;
      top: -.5rem;
      @extend .dot;
      @extend .position-relative;
    }
  }

  .dot-mid {
    background-color: $color-silver;

    &:before {
      background-color: $color-comet;
    }

    &:after {
      background-color: $color-silver;
    }
  }

  .dot-end {
    background-color: $color-silver;

    &:before {
      background-color: $color-silver;

    }

    &:after {
      background-color: $color-comet;
    }
  }

  &--dark {

    .three-dots {

      .dot {

        background-color: $color-silver;

        &:before {
          background-color: $color-comet;
        }

        &:after {
          background-color: $color-comet;
        }
      }

      .dot-mid {
        background-color: $color-comet;

        &:before {
          background-color: $color-silver;
        }

        &:after {
          background-color: $color-comet;
        }
      }

      .dot-end {
        background-color: $color-comet;

        &:before {
          background-color: $color-comet;
        }

        &:after {
          background-color: $color-silver;
        }
      }
    }
  }
}