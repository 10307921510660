.article__teaser {
  $self: &;
  display: flex;
  flex-direction: column;

  & > a:first-of-type {
    order: 2;
    width: 100%;
    //@include breakpoint(tablet) {
    //  width: auto;
    //}
  }

  .content-element &__subline,
  &__subline {
    color: $color-pacific-blue;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.8rem;
    order: 2;
    text-decoration: none;

    @include breakpoint(desktop) {
      font-size: 1.8rem;
      line-height: 2.2rem;
      margin-bottom: .3rem;
    }
  }

  &__wrapper {
    order: 2;
  }

  &__headline {
    font-size: 1.6rem;
    line-height: 2rem;
    padding: 0 0 .4rem 0;
    order: 3;

    @include breakpoint(desktop) {
      font-size: 2.6rem;
      line-height: 3rem;
    }
  }

  &:not(.article__teaser--no-image):not(.article__teaser--small) {
    #{$self}__headline {
      line-height: 2.9rem;
    }
  }

  &__intro {
    display: none;
    font-size: 1.6rem;
    line-height: 2.2rem;
    order: 4;

    @include breakpoint(desktop) {
      font-size: 1.8rem;
      line-height: 2.4rem;
    }
  }

  &__author-img {
    display: none;
  }

  &__info {
    font-size: 1.4rem;
    line-height: 1.8rem;
    position: relative;
    order: 5;


    @include breakpoint(desktop) {
      font-size: 1.4rem;
    }

    div > span:not(:first-child):before {
      content: "|";
      display: inline;
      padding: 0 3px;
      font-weight: 400;
    }

    div > a.article__teaser__info:not(:first-child):before {
      content: "|";
      display: inline;
      padding: 0 3px;
      font-weight: 400;
    }
  }

  &__image {
    order: 1;
    margin: 0 0 .645rem 0;
    position: relative;

    picture {
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: $aspect-16-9;
      z-index: 1;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
      }
    }

    .interferer {
      background-color: $color-pacific-blue;
      border-radius: 4px;
      bottom: .955rem;
      display: none;
      font-size: 1.4rem;
      height: 3rem;
      line-height: 3rem;
      min-width: 6.2rem;
      padding: 0 .4rem 0 .4rem;
      position: absolute;
      right: .9rem;

      @include breakpoint(tablet) {
        bottom: 1rem;
        right: 1rem;
      }

      &__text.hidden {
        display: none;
      }
    }
  }

  &.article__teaser--small {
    #{$self}__image {
      picture {
        padding-top: $aspect-4-3;
      }
    }
  }

  .interferer-small,
  .interferer-reduced {
    border-radius: .4rem;
    height: 3rem;
    display: none;
    flex-shrink: 0;
    order: 3;
    position: relative;
    width: 3rem;
  }

  &__custom-box,
  &__special-interferer {
    display: none;
  }

  &__separator {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    margin: auto 0 1.5rem 0;
    order: 6;
    padding-top: .6rem;

    @include breakpoint(desktop) {
      margin-bottom: 1.5rem;
      padding-top: .7rem;
    }

    &.no-padding {
      padding-top: 0;
    }
  }

  // variants
  &--opener,
  &--opener-big {
    margin-top: 1rem;
    #{$self}__subline {
      line-height: 1.8rem;
      @include breakpoint(tablet) {
        padding: 0 1rem;
      }
    }

    #{$self}__headline {
      padding: 0 0 .9rem;

      @include breakpoint(tablet) {
        padding: 0 1rem .9rem 1rem;
      }
    }

    #{$self}__intro {
      display: block;
      padding: 0 0 1rem;
      width: 100%;

      @include breakpoint(tablet) {
        padding: 0 1rem .9rem 1rem;
      }
    }

    #{$self}__info {
      padding: 0 0 .7rem;

      @include breakpoint(tablet) {
        padding: 0 1rem .9rem;
      }
    }

    #{$self}__image {
      margin: 0 -1rem;
      padding: 0 0 .7rem 0;

      @include breakpoint(tablet) {
        margin: 0;
      }
    }

    #{$self}__separator {
      padding: 0 1rem 1.15rem 1rem;
    }
  }

  &--opener-big {
    #{$self}__custom-box {
      display: block;
      padding: 0 1rem;
      min-height: 45px;
      order: 7;
    }
  }


  /**
  only mobile
   */
  @include breakpoint(maxTablet) {
    &:not(.article__teaser--small):not(.article__teaser--no-image) {
      .article__teaser__headline {
        font-size: 1.9rem;
      }
    }
  }

  @include breakpoint(tablet) {
    .grid-cell--d-8 & {
      #{$self}__image {
        order: 3;
        margin-top: -2rem;
        width: 48%;
      }
    }

    .grid-cell--t-12 & {
      &:not(.article__teaser--opener) {
        flex-flow: row;
        flex-wrap: wrap;

        #{$self}__subline,
        #{$self}__wrapper {
          padding-right: 1rem;
        }

        #{$self}__subline {
          line-height: 2.8rem;
          order: 1;
        }

        #{$self}__wrapper {
          order: 2;
        }

        #{$self}__headline {
          font-size: 2.2rem;
          line-height: 2.6rem;
        }

        #{$self}__info {
          padding: 1rem 0 0 0;
        }

        #{$self}__image {
          order: 3;
          width: 48%;
        }

        #{$self}__separator {
          margin-top: 2rem;
          padding: 0;
          width: 100%;
        }
      }
    }
  }

  @include breakpoint(desktop) {
    &--opener {
      flex-direction: column;
      flex-wrap: wrap;
      min-height: 47rem;
      position: relative;

      &.box-name-hidden {
        margin-bottom: 0;
      }

      #{$self}__subline {
        display: inline-block;
        margin-top: 3rem;
        order: 1;
        padding: 0;
        width: 26%;
        z-index: 2;
      }

      #{$self}__wrapper {
        background-color: $color-white;
        flex-grow: 2;
        order: 2;
        min-height: calc(44rem - 3.1rem);
        padding-right: 1.6rem;
        position: relative;
        width: 32%;
        z-index: 1;


        #{$self}__headline {
          margin-bottom: 2.1rem;
          padding: 1.7rem 1.6rem 0 0;
        }

        #{$self}__intro {
          padding: 0;
        }
      }

      #{$self}__image {
        height: 46rem;
        position: absolute;
        right: 0;
        top: 0;
        width: 73%;
        padding-top: 1rem;

        img {
          max-height: 46rem;
          max-width: 81.7rem;
        }
      }

      #{$self}__info {
        font-size: 1.4rem;
        line-height: 2rem;
        margin-top: 2rem;
        padding: 0;
      }

      #{$self}__separator {
        margin-bottom: 3rem;
      }
    }

    &--opener-big {
      flex-direction: row;
      flex-wrap: wrap;

      #{$self}__image {
        flex-grow: 2;
        margin: 0 0 1.6rem 0;
        max-width: 100%;
        max-height: 46rem;

        img {
          max-height: 46rem;
        }
      }

      #{$self}__subline,
      #{$self}__wrapper,
      #{$self}__headline,
      #{$self}__intro,
      #{$self}__info {
        padding: 0;
      }

      #{$self}__subline,
      #{$self}__wrapper {
        padding-right: 1rem;
        width: 67%;
      }

      #{$self}__subline {
        margin-bottom: .3rem;
      }

      #{$self}__headline {
        font-size: 3.2rem;
        line-height: 4rem;
        margin-bottom: 1.6rem;
      }

      #{$self}__intro {
        margin-bottom: 1rem;
      }

      #{$self}__custom-box {
        border-left: thin dotted $color-comet;
        height: 26rem;
        margin-top: -1.8rem;
        order: 3;
        width: 33%;
      }

      #{$self}__separator {
        margin-top: 1.05rem;
        width: 100%;
      }
    }

    .grid-cell--d-4 & {
      #{$self}__subline {
        line-height: 2.2rem;
      }

      #{$self}__headline {
        font-size: 2.2rem;
        line-height: 2.6rem;
      }

      #{$self}__image {
        margin-bottom: 1.1rem;
      }

      #{$self}__info {
        padding: 0;
      }

      #{$self}__separator {
        padding-top: 1.1rem;
      }
    }

    .grid-cell--d-6 & {
      #{$self}__subline {
        line-height: 2.2rem;
        margin-bottom: .5rem;
      }

      &:not(.article__teaser--no-image):not(.article__teaser--small) {
        #{$self}__headline {
          font-size: 2.6rem;
          line-height: 3rem;
        }
      }

      #{$self}__image {
        margin-bottom: 1.2rem;
      }

      #{$self}__info {
        font-size: 1.6rem;
        line-height: 2.17rem;
        padding: 0.8rem 0 .8rem 0;
      }
    }

    .grid-cell--d-6 & + .article__teaser__separator {
      padding-top: 1.1rem;
    }

    .grid-cell--d-8 & {
      &:not(.article__teaser--opener) {
        flex-flow: row;
        flex-wrap: wrap;

        #{$self}__subline,
        #{$self}__wrapper {
          padding-right: 1rem;
        }

        #{$self}__subline {
          line-height: 2.2rem;
          margin-bottom: .4rem;
          order: 1;
        }

        #{$self}__wrapper {
          order: 2;
        }

        #{$self}__headline {
          font-size: 2.2rem;
          line-height: 2.6rem;
        }

        #{$self}__info {
          line-height: 2.17rem;
          padding: .7rem 0 0 0;
        }

        #{$self}__image {
          order: 3;
          margin-top: -2rem;
          width: 48%;
        }

        #{$self}__separator {
          margin-top: 2rem;
          padding: 0;
          width: 100%;
        }
      }
    }
    .grid-cell--d-8 & + .article__teaser__separator {
      padding-top: 2rem;
    }
  }

  &--small:not(.article__teaser--no-image) {
    flex-direction: row;
    flex-wrap: wrap;

    #{$self}__subline,
    #{$self}__info {
      padding: 0;

      @include breakpoint(maxTablet) {
        margin-top: .5rem;
      }
    }

    #{$self}__subline,
    #{$self}__info,
    #{$self}__separator {
      width: 100%;
    }

    & > a:first-of-type {
      width: calc(100% - 9rem);

      @include breakpoint(tablet) {
        width: calc(100% - 12rem);
      }
    }

    #{$self}__wrapper {
      padding-right: 1rem;
    }

    #{$self}__subline {
      order: 1;
    }

    #{$self}__headline {
      font-size: 1.6rem;
      line-height: 2rem;
      order: 2;
    }

    #{$self}__image {
      min-height: unset;
      order: 3;
      width: 9rem;
      height: 6.8rem;

      @include breakpoint(tablet) {
        height: 9rem;
      }

      @include breakpoint(maxTablet) {
        margin: .5rem 0 0 0;
      }

      .interferer {
        bottom: .5rem;
        display: none;
        min-width: 2rem;
        order: 3;
        position: absolute;
        right: .5rem;
        width: 2rem;
      }
    }

    &#{$self}--no-image {
      #{$self}__image {
        display: none;
      }
    }

    @include breakpoint(tablet) {
      .grid-cell--t-6 &,
      .grid-cell--d-4 & {
        position: relative;

        #{$self}:not(.article__teaser--no-image) {
          min-height: 9.4rem;
        }

        #{$self}__subline {
          font-size: 1.5rem;
        }

        #{$self}__headline {
          font-size: 1.6rem;
          line-height: 2rem;
          padding-bottom: .6rem;
        }

        #{$self}:not(.article__teaser--no-image):not(.article__teaser--small) {
          #{$self}__headline {
            line-height: 3rem;
          }
        }

        //#{$self}__info {
        //  div > span:not(:last-of-type):after {
        //    content: "|";
        //    display: inline;
        //    padding: 0 3px;
        //  }
        //}

        #{$self}__subline {
          line-height: 1.8rem;
          margin-bottom: 0;
        }

        #{$self}__subline,
        #{$self}__wrapper,
        #{$self}__info {
          padding-right: 1rem;
          //width: calc(100% - 12rem);
        }

        #{$self}__image {
          position: relative;
          // top: -1.4rem;
          // position: absolute;
          // right: 0;
          // top: 0;
          width: 12rem;
        }
      }
    }
  }

  &--no-image {
    flex-flow: column;
    flex-wrap: wrap;

    #{$self}__subline {
      width: 100%
    }

    #{$self}__wrapper {
      display: flex;
      flex-flow: row;
    }

    #{$self}__headline {
      order: 4;
      width: 100%;
    }

    #{$self}__image {
      width: 100%;
    }

    .interferer-small,
    .interferer-reduced {
      display: inherit;
      margin-top: 5px;
    }

    .article__teaser__separator {
      width: 100%;
    }

    @include breakpoint(desktop) {
      .grid-cell--d-4 & {
        padding: 0;

        #{$self}__subline,
        #{$self}__wrapper {
          width: 100%;
        }

        #{$self}__subline {
          font-size: 1.5rem;
          margin-bottom: 0;
        }

        #{$self}__headline {
          font-size: 1.6rem;
          line-height: 2rem;
          padding-bottom: .6rem;
        }

        #{$self}__info .pipe {
          color: inherit;

          &:after {
            content: unset;
          }
        }
      }
    }
  }

  &--no-author-image #{$self}__headline {
    width: auto !important;
  }

  @include breakpoint(tablet) {
    // 2 Rows
    .grid-cell--t-12 &,
    .grid-cell--d-8 & {
      &:not(.article__teaser--opener) {
        &.article__teaser,
        &.article__teaser--small {
          display: grid;
          grid-template-columns: 51% 49%;
          grid-template-rows: auto auto 1fr;
        }

        .article__teaser__subline {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 1;
          grid-row-end: 2;
        }

        .article__teaser__wrapper {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 2;
          grid-row-end: 3;
        }

        .article__teaser__image {
          display: grid;
          grid-template-columns: auto;
          grid-template-rows: auto 1fr;
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 4;
          height: auto;
          margin: 0;
          margin-left: .5rem;
          width: 100%;

          picture {
            grid-row-start: 1;
            grid-row-end: 2;
          }

          .interferer {
            display: block;
            grid-row-start: 1;
            grid-row-end: 2;
          }
        }

        .article__teaser__separator {
          grid-row-start: 4;
          grid-row-end: 4;
          grid-column-start: 1;
          grid-column-end: 3;
        }

        &.article__teaser--no-image {
          .article__teaser__subline,
          .article__teaser__wrapper {
            width: 100%;
          }

          .article__teaser__image {
            display: none;
          }
        }
      }
    }
  }

  @include breakpoint(desktop) {
    // 1.5 Rows
    .grid-cell--d-6 & {
      &.article__teaser--small {
        &.article__teaser--small {
          .article__teaser__subline {
            order: 2;
            width: 100%;
          }

          .article__teaser__image {
            height: auto;
            order: 1;
            position: relative;
            top: unset;
            width: 100%;

            .interferer {
              .hidden {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }

}

.article__teaser--small .article__teaser__image .interferer {
  height: 2rem;
  min-width: 0;
  padding: 0;
  width: 2rem;

  &__icon {
    left: .7rem;
    top: .6rem;
  }
}

// Article-type modifier
.article--type {
  &-video,
  &-gallery,
  &-podcast,
  &-with-logo,
  &-liveticker,
  &-graphical-story,
  &.article__teaser--small {
    .article__teaser__image {
      .interferer {
        display: inherit;
      }
    }

    &.article__teaser--no-image {
      .article__teaser__headline {
        padding-left: 1rem;
      }

      // .article__teaser__info {
      //   padding-left: 4rem;
      // }
    }
  }
}