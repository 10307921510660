.newsticker {
  padding: 0;
  transition: max-height .4s ease-in-out;
  max-height: 4.8rem;

  &__container {
    width: 100%;
    overflow: hidden;
    height: 4.8rem;
    line-height: 4.8rem;
    background-color: $color-warning;
    box-sizing: content-box;
    color: $color-nero;
    font-family:  $typeface-font;

    &__items {
      display: inline-block;
      white-space: nowrap;
      box-sizing: content-box;

      &--item {
        position: relative;
        top: -2px;
        display: inline-block;
        padding: 0 2rem;
        font-size: 1.6rem;
        @include breakpoint(tablet) {
          font-size: 1.8rem;
        }

        &:before {
          position: absolute;
          content: "+++";
          top: 0;
          left: -10px;
        }

        a {
          display: block;
          height: 100%;
        }
      }
    }

    &__status {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      left: 0;
      z-index: 5;
      height: 100%;
    }
  }

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    border-left: 1px solid $color-white-smoke-3;
    background-color: $color-warning;
    z-index: 5;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -41px;
      height: 100%;
      width: 40px;
      background: linear-gradient(to right,  rgba(0,0,0,0) 0%,rgba(255,245,0,1) 100%);
      z-index: 5;
    }

    &--button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  &__icon {
    fill: $color-comet;
    width: 15px;
    height: 15px;
  }

  &__hide {
    max-height: 0;
  }

  &__marquee {
    overflow: hidden;
    display: flex;
  }
}