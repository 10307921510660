.content-element {

  .max-height {
    max-height: 100%;
  }

  .cell-th {
    letter-spacing: .014rem;
    text-align: left;

    @include breakpoint(tablet) {
      font-size: 1.4rem;
    }
  }

  .table {
    line-height: 2rem;
    position: relative;
    table-layout: fixed;
    max-width: none;
    width: auto;
    min-width: 100%;
  }

  .row,
  .cell {
    padding: 1.65rem .95rem 1.45rem 1rem;
    position: relative;
  }

  .row-first {

    .cell-l:after {
      left: 0;
      @extend .border-table;
      @extend .border-dotted-b;
    }

    .cell-r:after {
      right: 0;
      bottom: 0;
      @extend .border-dotted-b;
      @extend .border-table;
    }
  }

  .row,
  .row-second {

    .cell-l:before {
      right: 0;
      bottom: 0;
      @extend .border-dotted-r;
      @extend .border-table;
    }

    .cell-l:after {
      left: 10px;
      bottom: 0;
      @extend .border-dotted-b;
      @extend .border-table;
      width: calc(100% - 20px);
    }

    .cell-l:first-child:after {
      left: 0;
      bottom: 0;
      @extend .border-dotted-b;
      @extend .border-table;
      width: calc(100% - 10px);
    }

    .cell-r:after {
      right: 0;
      bottom: 0;
      @extend .border-dotted-b;
      @extend .border-table;
      width: calc(100% - 10px);
    }
  }

  .row-second {
    .cell-l:before {
      right: 0;
      bottom: 0;
      @extend .border-dotted-r;
      @extend .border-table;
      height: calc(100% - 10px);
    }
  }

  .row-last {

    .cell-l:before {
      right: 0;
      top: 0;
      @extend .border-dotted-r;
      @extend .border-table;
      height: calc(100% - 10px);
    }

    .cell-l:after {
      left: 0;
      bottom: 0;
      @extend .border-dotted-b;
      @extend .border-table;
    }

    .cell-r:after {
      right: 0;
      bottom: 0;
      @extend .border-dotted-b;
      @extend .border-table;
    }
  }

  tr td:first-of-type {
    padding: 1.65rem .95rem 1.45rem 0;
  }

  .cell-r {
    padding-left: 1.05rem;
    width: calc(100% - 10.5px);
  }
}

.table-responsive {
  overflow-y: scroll;

  .cell {
    min-width: 110px;
  }

  .row-first .cell-th:first-child {
    padding-left: 0;
  }

  .row-second .cell-r:after,
  .row .cell-r:after {
    right: 10px;
    width: calc(100% - 15px);
  }

  .row-second .cell-r:before,
  .row .cell-r:before,
  .row-last .cell-r:before{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border-left: 1px dotted #BEBEBE;
  }

  .row-last .cell-r:before {
    top: 5px;
    height: calc(100% - 10px);
  }


}

.table-blur:before {
  position: absolute;
  content: "";
  width: 4.4rem;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 1;
  @include breakpoint(tablet) {

  }
  background: transparent linear-gradient(90deg, $color-transparent 0%, $color-white 100%) 0 0 no-repeat padding-box;
}
