// ** Background colors **
.bg-color {

  &__white {
    background-color: $color-white;
  }

  &__white-smoke-2 {
    background-color: $color-white-smoke-2;
  }

  &__alice-blue {
    background-color: $color-alice-blue;
  }

  &__black {
    background-color: $color-black;
  }

  &__nero-100 {
    background-color: $color-nero;
  }

  &__nero-50 {
    background-color: $color-nero-50;
  }

  &__nero-20 {
    background-color: $color-nero-20;
  }

  &__prussian-blue-100 {
    background-color: $color-prussian-blue;
  }

  &__prussian-blue-80 {
    background-color: $color-prussian-blue-80;
  }

  &__prussian-blue-60 {
    background-color: $color-prussian-blue-60;
  }

  &__prussian-blue-40 {
    background-color: $color-prussian-blue-40;
  }

  &__prussian-blue-20 {
    background-color: $color-prussian-blue-20;
  }

  &__prussian-blue-10 {
    background-color: $color-prussian-blue-10;
  }

  &__xl-blue {
    background-color: $color-xl-blue;
  }

  &__pacific-blue-100 {
    background-color: $color-pacific-blue;
  }

  &__pacific-blue-80 {
    background-color: $color-pacific-blue-80;
  }

  &__pacific-blue-60 {
    background-color: $color-pacific-blue-60;
  }

  &__pacific-blue-40 {
    background-color: $color-pacific-blue-40;
  }

  &__pacific-blue-20 {
    background-color: $color-pacific-blue-20;
  }

  &__pacific-blue-10 {
    background-color: $color-pacific-blue-10;
  }

  &__pacific-blue-05 {
    background-color: $color-pacific-blue-05;
  }

  &__alice-blue-100 {
    background-color: $color-alice-blue;
  }

  &__whisper-2 {
    background-color: $color-whisper-2;
  }

  &__silver-100 {
    background-color: $color-silver;
  }

  &__comet-100 {
    background-color: $color-comet;
  }

  &__deep-sky-blue-100 {
    background-color: $color-deep-sky-blue;
  }

  &__white {
    background-color: $color-white;
  }

  &__red {
    background-color: $color-red;
  }

  &__dark-transparent {
    background-color: $color-dark-transparent;
  }

  &__signal {

    &--green {
      background-color: $color-green;
    }

    &--red {
      background-color: $color-red;
    }
  }

  &__cream {
    background-color: $color-cream;
  }

  &__green {
    background-color: $color-green;
  }

  &__dark-green {
    background-color: $color-dark-green;
  }

  &__titan-white {
    background-color: $color-titan-white;
  }

  &__inherit {
    background-color: inherit;
  }
}