.article--type {
  &-video {

    &.article__teaser--small:not(.article__teaser--no-image) {
      .interferer {
        &__icon {
          width: 7px;
          top: .3rem;
        }
      }
    }

    .interferer {
      font-weight: 600;

      &__icon {
        height: 1.4rem;
        left: 1rem;
        position: absolute;
        top: .8rem;
        width: 1.2rem;
      }

      &__text {
        margin-left: 2rem;
        padding-left: .6rem;
      }
    }

    &.article__teaser--no-image {
      .article__teaser__headline {
        width: calc(100% - 4rem);
      }
    }

    @include breakpoint(tablet) {
      .grid-cell--t-12 & {
        .interferer {
          bottom: 1rem;
          right: 1rem;
        }
      }
    }

    @include breakpoint(desktop) {
      .grid-cell--d-4 & {
        .interferer {
          bottom: 1rem;
          right: 1rem;
        }

        .article__teaser__headline {
          font-size: 2.2rem;
          line-height: 2.6rem;
        }

        &.article__teaser--no-image {
          .article__teaser__subline {
            line-height: 1.8rem;
          }

          .article__teaser__headline {
            font-size: 1.6rem;
            line-height: 2rem;
          }
        }

        .article__teaser__info {
          font-size: 1.4rem;
          line-height: 1.8rem;
        }

        &.article__teaser--small {
          padding: 0;

          .article__teaser__headline {
            font-size: 1.6rem;
            line-height: 2rem;
          }

          .article__teaser__info {
            font-size: 1.4rem;
            line-height: 1.8rem;
          }

          .interferer {
            bottom: .5rem;
            display: inline-block;
            height: 2rem;
            min-width: 2rem;
            right: .5rem;
            width: 2rem;

            svg {
              left: .749rem;
              top: .3rem;
              width: .643rem;
            }
          }

          .article__teaser__separator {
            padding: 1rem 0 0 0;
          }

          &.article__teaser--small.article__teaser--no-image {
            .interferer-reduced {
              border-radius: 3px;
              height: 3rem;
              margin-top: .4rem;
              position: relative;
              width: 3rem;
            }

            &:not(.article__teaser--no-image) {
              .article__teaser__info {
                margin-left: 4rem;
              }
            }
          }
        }
      }

      .grid-cell--d-8 & {
        .interferer {
          bottom: 1rem;
          right: 1rem;
        }
      }
    }

    @include breakpoint(desktop) {
      // 1.5 Rows
      .grid-cell--d-6 & {
        .interferer {
          bottom: 1.5rem;
          right: 1.5rem;
        }

        &.article__teaser--small {
          &.article__teaser--small {
            .article__teaser__image {
              .interferer {
                bottom: 1.5rem;
                height: 3rem;
                right: 1.5rem;
                min-width: 7.2rem;

                &__icon {
                  height: 1.3rem;
                  left: .8rem;
                  position: absolute;
                  top: .9rem;
                  width: 1rem;
                }

                .hidden {
                  display: block;
                }
              }
            }
          }
        }
      }
    }

    @include breakpoint(tablet) {
      &.article__teaser:not(.article__teaser--small) {
        .interferer {
          &__icon {

          }
        }
      }

      .grid-cell--t-6 &,
      .grid-cell--t-12 &,
      .grid-cell--d-4 &,
      .grid-cell--d-6 &,
      .grid-cell--d-8 & {
        .article__teaser__image {
          .interferer {
            padding-right: 1rem;
          }
        }
      }
    }
  }
}