.container {
  background-color: $color-white-smoke-2;
  margin: 0 auto;
  max-width: 1140px;
  padding:  0 0 1rem;
  position: relative;

  @include breakpoint(tablet) {
    padding: 1rem;
  }

  &--space {
    padding-right: $content-space-mobile;
    padding-left: $content-space-mobile;

    @include breakpoint(tablet) {
      padding-right: $content-space-tablet;
      padding-left: $content-space-tablet;
    }

    @include breakpoint(desktop) {
      padding-right: $content-space-desktop;
      padding-left: $content-space-desktop;
    }
  }

  &--medium {
    max-width: 673px;
  }
}

.container-ob {
  background-color: $color-white;
}