.jumbotron {
  margin-bottom: 1rem;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 80%);
    z-index: 1;
  }

  &__headline {
    font-size: 2.5rem;
    margin-bottom: .5rem;
    @include breakpoint(tablet) {
      font-size: 4.2rem;
      margin-bottom: 1rem;
    }
    @include breakpoint(desktop) {
      font-size: 5.8rem;
      margin-bottom: 2rem;
    }
  }

  &__subheadline {
    margin-bottom: 1.5rem;
    line-height: 2.3rem;
    @include breakpoint(tablet) {
      font-size: 2.2rem;
      line-height: 3.2rem;
      margin-bottom: 1.5rem;
    }
    @include breakpoint(desktop) {
      margin-bottom: 2.3rem;
    }
  }

  &__content {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2rem 3rem 2rem;
    width: 100%;
    margin: 0 auto;
    z-index: 10;

    @include breakpoint(tablet) {
      left: 50%;
      max-width: calc(550px + 6rem);
      transform: translateX(-50%);
    }

    @include breakpoint(desktop) {
      padding-bottom: 2.5rem;
      max-width: calc(805px + 6rem);
    }
  }

  .picture__type img {
    height: 100%;
    object-fit: cover;
  }
}