.xl-carousel-box {
  @extend .size--m-8;
  line-height: 2.6rem;

  @include breakpoint(tablet) {
    font-size: 3.4rem;
    line-height: 3.5rem;
  }

  .box-name {
    display: none;
  }

  .slider {
    .slider__elements {
      margin: 0;

      @include breakpoint(tablet) {
        flex-direction: row;
      }

      .slider__element {
        height: 15.2rem;
        margin-left: 0;
        margin-right: .5rem;
        position: relative;
        width: 9.2rem;

        &:first-of-type {
          margin-left: 1rem;
          scroll-margin-left: 1rem;
        }

        img {
          height: 15.2rem;
          width: auto;
        }
      }
    }

    @include breakpoint(tablet) {
      &:before,
      &:after {
        content: "";
        display: none;
        height: 100%;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 10%;
        @include gradient-white-transparent-r_l();
      }

      &:before {
        left: 0;
        transform: scaleX(-1);
        z-index: 1;
      }

      &:after {
        right: 0;
      }

      border-bottom: unset;

      .next-bubble,
      .prev-bubble {
        height: 4.2rem;
        width: 4.2rem;
      }

      .prev-bubble__arrow-left,
      .next-bubble__arrow-right {
        fill: $color-white;
        height: 1.242rem;
        position: absolute;
        stroke-width: .2rem;
        width: .621rem;
      }

      .prev-bubble__arrow-left {
        left: 40%;
        top: calc(50% - .6rem);
        transform: scaleX(-1);
      }

      .next-bubble__arrow-right {
        left: 45%;
        top: 50%;
        transform: translateY(-50%);
      }

      &.has-prev {
        .prev-bubble {
          display: block;
        }

        &:before {
          display: block;
        }
      }

      &.has-next {
        .next-bubble {
          display: block;
        }

        &:after {
          display: block;
        }
      }
    }
  }

  .slider-box__more {
    display: none;
  }

  .three-dots {
    @include breakpoint(tablet) {
      display: none;
    }
  }
}

.amp-story-layer {
  background: #000c;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 9999;

  .amp-story-frame {
    border: 0;
    display: block;
    height: 85vh;
    width: 75vh;
    position: absolute;
    margin-top: 2rem;
    left: 50%;
    margin-left: -37.5vh;
  }

  .icon-close-thin {
    height: 32px;
    cursor: pointer;;
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;

    &:before,
    &:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 33px;
      width: 2px;
      background-color: $color-white;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}

