.paid-content {
  svg {
    margin-right: .4rem;
    vertical-align: bottom;
  }
}
.container .content--group.bg-color .bg-color__white .paid-content {
  svg {
    #Rechteck_1627,
    #Pfad_2311 {
      fill: #fff;
    }
    #Pfad_2312,
    #Pfad_2313,
    #Pfad_2314,
    #Pfad_2315 {
      fill: #00386c;
    }
  }
}
.article__teaser--small,
.article__teaser--no-image,
.related-article,
.related-content,
.meta-box,
.kicker {
  .paid-content {
    svg {
      margin-right: .4rem;
      position: relative;
      top: -.1rem;
      height: 16px;
      width: auto;
    }
  }
}
.meta-box .paid-content {
  svg {
    top: -.7rem;
  }
}
.article__teaser--small.article__teaser--column {
  .paid-content {
    svg {
      position: relative;
      top: -.1rem;
    }
  }
}
.article__teaser--no-image.article__teaser--column {
  .paid-content {
    svg {
      margin: 0 .4rem 0 0;
      position: relative;
      top: -.1rem;
    }
  }
}
.paid-content + .kicker {
  display: inline-block;
}