.paywall {
  position: relative;
  padding: 4rem 1rem ;

  &__text {
    line-height: 2.2rem;
  }

  &__info-text {
    padding: 0 2.5rem;
  }

  &:not(.paywalled-content):after {
    position: absolute;
    content: "";
    width: 100%;
    left: 0;
    top: -63px;
    height: 63px;
    @include breakpoint(tablet) {
      top: -224px;
      height: 224px;
    }
    background: transparent linear-gradient(180deg, $color-transparent 0%, $color-white 100%) 0 0 no-repeat padding-box;
  }

  &.paywalled-content {
    position: inherit;
    padding: inherit;
  }

  &--circle {
    right: 5px;
    top: 50px;
  }

  &--logo {
    width: auto;
    height: 30px;
    vertical-align: top;
    margin-right: 2px;

    &__plus {
      width: 70px;
    }

    &__s {
      height: 20px;
    }

    &__m {
      height: 24px;
    }
  }

  .tp-container-inner iframe {
    border-bottom: 2px solid $color-prussian-blue !important;
  }
}

.paywall--classic {
  position: relative;
  width: calc(100% + 3rem);
  margin-left: -1.5rem;
  margin-bottom: -2.5rem !important;
  @include breakpoint(tablet) {
    width: calc(100% + 12rem);
    margin-left: -6rem;
    margin-bottom: -3.5rem !important;
  }
  @include breakpoint(desktop) {
    width: calc(100% + 21rem);
    margin-left: -10.5rem !important;
  }

  &--arrow-button__white:before {
    content: '';
    border-style: solid;
    border-width: 1.7rem 1.7rem 0 1.7rem;
    border-color: $color-white transparent transparent transparent;
    position: absolute;
    left: 50%;
    top: -1px;
    margin-left: -17px;
  }

  &--question {
    font-size: 1.6rem;
    line-height: 1.4rem;

    @include breakpoint(desktop) {
      font-size: 1.8rem;
      line-height: 1.6rem;
    }
  }

  &--container {
    max-width: 300px;
    margin: 0 auto;
    border-radius: 5px;
    padding-bottom: 2rem;

    &__l {
      width: 305px;
    }
  }

  &__content,
  &__headline {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &__headline {
    padding: 1rem 1.5rem;
    margin-bottom: 1.5rem;

    &--type1 {
      margin-bottom: -1rem;
      padding-bottom: 2rem;
    }
  }

  &--btn-width {
    width: 228px;
  }

  &--button-log-in {
    padding: 0.6rem 1.5rem;
  }

  &--list-item::marker {
    color: $color-comet;
  }

  &--text__regular {
    font-weight: 400;
  }

  &--rad-05{
    border-radius: .5rem;
  }

  &--p-l-30 {
    padding-left: 3.0rem;
  }

  &--p-t-07 {
    padding-top: 0.7rem;
  }

  &--p-b-07 {
    padding-bottom: 0.7rem;
  }

  .loader--ellipsis-item {
    background-color: $color-pacific-blue;
  }

  &.paywall--nnn .paywall--classic__content {
    border-radius: 5px;
  }

  // Newsletter overwrites
  .newsletter-widget {
    &-image,
    &-title,
    &-text {
      display: none;
    }

    &-form {
      text-align: left;
      margin-bottom: 2rem;
    }

    &-form label {
      margin-bottom: .5rem;
    }

    &-button {
      padding: 0 2rem;
      margin-bottom: 2rem;
    }

    &-holder {
      border-radius: 5px;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      background-color: #fff;
    }

    &-disclaimer {
      padding: 0 2rem
    }

    &-holder input,
    &-holder button {
      width: 100%
    }

    &-holder button {
      padding: 1.1rem;
    }

    &-holder input {
      padding-right: 1rem;
    }

    &-content-wrapper {
      padding: 0 1rem 2rem;

      &:before {
        display: none;
      }
    }

    &-success {
      line-height: 2rem;
    }

    &-button #btn_submit_newsletter {
      width: 100%;
    }
  }
}
#newsletter-widget-email::placeholder {
  font-weight: bold;
  color: #000;
  font-style: normal;
}

.field-newsletter-widget-email label {
  display:none;
}


// for ab-test only
.arrow-button--white:before {
  content: '';
  border-style: solid;
  border-width: 1.7rem 1.7rem 0 1.7rem;
  border-color: $color-white transparent transparent transparent;
  position: absolute;
  left: 50%;
  top: -1px;
  margin-left: -17px;
}

// for Paywall Journey
.paywall-journey {
  position: relative;
  width: calc(100% + 3rem);
  margin-left: -1.5rem;
  margin-bottom: -2.5rem !important;
  @include breakpoint(tablet) {
    width: calc(100% + 12rem);
    margin-left: -6rem;
    margin-bottom: -3.5rem !important;
  }
  @include breakpoint(desktop) {
    width: calc(100% + 21rem);
    margin-left: -10.5rem !important;
  }

  &__button-log-in {
    padding: 1rem 1.9rem;
    line-height: 2.2rem;
  }

  &__container {
    max-width: 32rem;
    padding: 0 1rem;
    margin: 0 auto;
  }

  &__container--inner {
    border-radius: .5rem;
  }

  &__container-headline {
    padding: 1.1rem 0 1.1rem 1.5rem;
  }

  &__button-standard-width {
    width: 22.8rem;
  }

  &__button-standard-padding {
    padding: 0.7rem 0;
  }

  &__p-l-30 {
    padding-left: 3rem;
  }

  &__p-t-35 {
    padding-top: 3.5rem;
  }

  &__list-item::marker {
    color: $color-dim-gray;
  }

  &__placeholder-mail::placeholder {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: bold;
    color: $color-black;
  }

  &__input-radio {
    height: 1.3rem;
    left: 0;
    opacity: 0;
    padding: 0;
    overflow: visible;
    position: absolute;
    width: 1.3rem;
  }

  &__icon-play {
    border: 0.1rem solid #FFFFFF;
    border-radius: 100%;

    &-width{
      border-radius: 50%;
      padding: 3px;
      height: 7.6rem;
      width: 7.6rem;
      margin-bottom: calc(-50% - 2rem);
      background-color: $color-white;
    }
  }

  &__text-margin {
    margin-bottom: 5rem;
  }

  &__button-line-h-30 {
    line-height: 3rem;
  }

  &__text-small {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  &__control-label {
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0;
    position: relative;
    vertical-align: top;
  }

  &__control-label:before {
    background-color: $color-white;
    border: 1px solid $color-deep-sky-blue;
    content: "";
    display: block;
    height: 1rem;
    left: -1.5rem;
    pointer-events: none;
    position: absolute;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 1rem;
  }

  &__control-label:after {
    background: no-repeat 50%/50% 50%;
    content: "";
    display: block;
    height: 1rem;
    left: -1.5rem;
    position: absolute;
    top: 0;
    width: 1rem;
  }

  &__position--relative {
    position: relative;
    z-index: 2;
  }
}


.paywall-special {
  position: relative;
  width: calc(100% + 3rem);
  margin-left: -1.5rem;
  margin-bottom: -2.5rem !important;
  @include breakpoint(tablet) {
    width: calc(100% + 12rem);
    margin-left: -6rem;
    margin-bottom: -3.5rem !important;
  }
  @include breakpoint(desktop) {
    width: calc(100% + 21rem);
    margin-left: -10.5rem !important;
  }

  &--play--icon{
    height: 6.6rem;
    width: 6.6rem;
  }

  &--container {
    max-width: 300px;
    margin: 0 auto;
    border-radius: 5px;
  }

  &--items-width {
    width: 228px;
  }

  &--question {
    font-size: 1.6rem;

    @include breakpoint(desktop) {
      font-size: 1.8rem;
    }
  }

  &--button-log-in {
    padding: 0.6rem 2.2rem;
    margin-bottom: 2.5rem;
  }

  &--video-description {
    font-weight: 600;
  }

  &--content-headline {
    border-radius: 5px 5px 0 0;
  }

  &--list-item::marker {
    color: $color-dim-gray;
  }

  &--play--icon-blue {
    transform: translateY(-50%);
  }

  &--info-text {
    padding: 1rem 2.5rem 5rem;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &--t {
    &--22 {
      margin-top: 2.2rem;
    }
  }

  &--t {
    &-15 {
      margin-top: 1.5rem;
    }
  }

  &--t {
    &-14 {
      margin-top: 1.4rem;
    }
  }

  &--t {
    &-21 {
      padding-top: 2.1rem;
    }
  }

  &--b {
    &-p14 {
      padding-bottom: 1.4rem;
    }
  }

  &--b {
    &-17 {
      margin-bottom: 1.7rem;
    }
  }

  &--b {
    &-14{
      margin-bottom: 1.4rem;
    }
  }

  &--l {
    &-47 {
      margin-left: 4.7rem;
    }
  }

  &--r {
    &-47 {
      margin-right: 4.7rem;
    }
  }

  &--l {
    &-15 {
      padding-left: 1.5rem;
    }
  }

  &--l {
    &-30 {
      padding-left: 3.0rem;
    }
  }

  &--t {
    &-07 {
      padding-top: .7rem;
    }
  }

  &--t {
    &-32 {
      padding-top: 3.2rem;
    }
  }

  &--b {
    &-32 {
      padding-bottom: 3.2rem;
    }
  }

  &--b {
    &-07 {
      padding-bottom: .7rem;
    }
  }

  &--b {
    &-p17 {
      padding-bottom: 1.7rem;
    }
  }

  &--b {
    &-11 {
      padding-bottom: 1.1rem;
    }
  }
  &--t {
    &-11 {
      padding-top: 1.1rem;
    }
  }
}

.paywall-special-2 {
  display: none;
}

.doi-action {
  .paywall__info-text {
    padding: 0;
  }

  &.loading {
    position: relative;
  }

  &.loading:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.8);
    top: 0;
    left: 0;
  }
}
.paywall-special--circle {
  position: relative;

  &:after {
    position: absolute;
    content: "";
    top: -20px;
    left: 50%;
    margin-left: -50px;
    width: 100px;
    height: 50px;
    background-color: #00AFEA;
    border-radius: 0 0 50px 50px;
    z-index: 1;
  }
}