.circle {
  border-radius: 50%;
  width: 55px;
  height: 55px;
  font-size: 1.8rem;
  line-height: 4.5rem;
  padding: 5px;
  text-align: center;
  font-weight: 700;
  color: $color-white;
}