@mixin team-name {
  @extend .size--m-2;

  @include breakpoint(tablet) {
    font-size: 1.4rem;
  }
}

.encounter {

  &__wrapper {
    width: 312px;
    display: flex;
    margin: 0 auto;
  }

  &__details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: .5rem 0 .8rem;

    @include breakpoint(tablet) {
      padding-bottom: .7rem;
    }
  }

  &__home {
    flex-basis: 0;
    flex-grow: 1;

    @include team-name;
  }

  &__logo {
    display: block;
    margin: 0 auto .2rem;
    max-width: 3.5rem;
    height: 3.5rem;
  }

  &__start-date,
  &__start-time {
    font-size: 1rem;
    line-height: 1.4rem;

    @include breakpoint(tablet) {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }

  &__start-date {
    margin-top: 1.2rem;
  }


  &__score {
    flex-basis: 0;
    flex-grow: 1;
    line-height: 1.8rem;

    &--terminated {
      margin: .4rem 0 .6rem;
      @extend .size--l-4;
    }

    .encounter__score {
      font-size: 2.4rem;
    }
  }

  &__signal {
    display: inline-block;
    height: .4rem;
    margin-bottom: .1rem;
    margin-right: .4rem;
    width: .4rem;
  }

  &__game-state {
    @extend .size--m-0;
    line-height: 1.6rem;

    @include breakpoint(tablet) {
      font-size: 1.2rem;
    }
  }

  &__away {
    flex-basis: 0;
    flex-grow: 1;

    @include team-name;
  }
}