.advertorial-notice {
  display: flex;
  width: auto;
  padding: .5rem 1.9rem .5rem 1rem;
  height: 4rem;
  @include breakpoint(tablet) {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__text {
    align-self: center;
    flex-grow: 2;
    margin-right: 2rem;
  }

  &__img {
    max-width: 110px;

    img {
      align-self: center;
      max-height: 2.2rem;
    }
  }
}