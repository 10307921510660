.sticky {
  //position top - have to change to height of sticky header
  top: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;

  @include breakpoint(tablet) {
    position: static;
  }
}

.section-heading.sticky {
  top: 5.6rem;
}