@media print {

  @page {
    margin: 2cm 3cm;
    height: auto!important;
  }

  .nozmhn_ad,
  .ivw.flex,
  .top-part__misc-nav--left,
  .top-part__misc-nav--right,
  .lower-part,
  .content-elements.social-share,
  .footer,
  .outer-wrapper>.inner-wrapper,
  .cmp-consent,
  .meta-box__player,
  .ob-smartfeed-wrapper,
  .trademark-notice,
  .doi-message,
  .newsticker,
  #comments,
  .link.intern {
    display: none !important;
  }

  .main-header {
    height: 0 !important;

    .top-part {
      svg.logo {
        position: absolute;
        right: 10rem;
        top: 2rem;
        width: 7.5rem;
      }
    }
  }

  .container {
    padding: 0 !important;
    display: block;
  }

  .banner {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .breadcrumb {
    padding-left: 0 !important;
  }

  .container .content--stage {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    margin-top: 0;

    .picture__wrapper {
      order: 2;
      margin-bottom: 0;
      overflow: visible !important;
    }

    .meta-box {
      order: 1;
      margin-bottom: 3rem;
    }

    & > .content-element:first-child {
      max-width: unset;
      order: 2;
    }

    .picture__type--topbanner,
    .picture__type--videobanner {
      padding-top: $aspect-16-9 !important;
    }
  }

  .picture__caption {
    position: relative !important;
    padding: 1rem 0 .5rem 0 !important;
    transition: none;
  }

  .picture__text {
    display: inline-block;
    position: static;
    width: auto;
  }

  .picture__author {
    position: static !important;
    top: auto;
    bottom: auto;
    padding: 0 0 0 0.3rem;
    font-size: 1.4rem;
    line-height: 1.7rem;
    letter-spacing: .28px;
    color: #69696c;
    text-align: left;
    background: rgba(0,0,0,0);
  }

  article figure:not(.banner),
  .container .content-element {
    max-width: unset !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .container .content--group__article p {
    max-width: 100% !important;
  }

  .content--group {
    &__section {
      padding-top: 20px !important;
    }
  }

  .container .content--group__article .content--group__section {
    padding: 0 !important;
  }

  .container .content--group--fix {
    margin: 0 !important;
  }

  .content-element {
    .comment {
      p:nth-of-type(1n+2) {
        display: block !important;
      }

      &__fill {
        display: none;
      }

      &__gradient:after {
        display: none;
      }
    }

    .info-box__content.bg-color__alice-blue {
      animation: none!important;
    }

    .info-box__content-wrapper {
      overflow-y: visible;
    }
  }

  .container .content--group__article .content--group__section:last-child{
    break-after: auto;
  }

}
