.picture {
  display: block;

  &__wrapper {
    overflow: hidden;
    position: relative;

    .interferer-logo {
      background-color: inherit;
      left: 1rem;
      position: absolute;
      top: 1rem;

      .icon {
        height: 3.5rem;
        width: 3.5rem;

        @include breakpoint(tablet) {
          height: 5.3rem;
          width: 5.3rem;
        }
      }

      @include breakpoint(tablet) {
        left: 1.5rem;
        top: 1.5rem;
      }

      &:nth-of-type(2) {
        background-color: $color-pacific-blue;
        border-radius: 4px;
        font-size: 1.4rem;
        height: 3rem;
        left: initial;
        line-height: 3rem;
        min-width: 6.2rem;
        padding: 0 0.4rem;
        position: absolute;
        right: 1.5rem;
        top: calc(100% - 4.5rem);

        @include breakpoint(tablet) {
          margin-top: calc(56.25% - 4.5rem);
          top: 0;
        }

        .interferer__icon{
          height: 1.4rem;
          left: 1rem;
          position: absolute;
          top: 0.8rem;
          width: 1.2rem;
        }

        .interferer__text {
          line-height: 3rem;
          margin-left: 2rem;
          padding-left: 0.6rem;
        }
      }
    }

    .interferer:not(.interferer-logo) {
      background-color: #08c;
      border-radius: 4px;
      bottom: 0.955rem;
      display: block;
      font-size: 1.4rem;
      height: 3rem;
      line-height: 3rem;
      min-width: 6.2rem;
      padding: 0 0.4rem;
      position: absolute;
      top: calc(100% - 4rem);
      left: calc(100% - 7.3rem);

      .interferer__icon {
        height: 1.4rem;
        left: 1rem;
        position: absolute;
        top: 0.8rem;
        width: 1.2rem;
      }

      .interferer__text {
        margin-left: 2rem;
        padding-left: 0.6rem;
      }

      &.hasCaption {
        top: calc(100% - 7.3rem);
      }
    }

  }

  img {
    width: 100%;
  }

  &__type {
    display: block;
    height: 0;
    overflow: hidden;
    z-index: 1;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }

    &--videobanner,
    &--topbanner {
      padding-top: $aspect-4-3;

      @include breakpoint(tablet) {
        padding-top: $aspect-16-9;
      }

      @include breakpoint(desktop) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &--16-9 {
      padding-top: $aspect-16-9;
    }

    &--4-3 {
      padding-top: $aspect-4-3;
    }

    &--hero {
      padding-top: $aspect-4-3;

      @include breakpoint(tablet) {
        padding-top: $aspect-hero;
      }
    }

    &--jumbotron {
      padding-top: $aspect-jumbotron;

      @include breakpoint(tablet) {
        padding-top: $aspect-jumbotron-tablet;
      }

      @include breakpoint(desktop) {
        padding-top: $aspect-jumbotron-desktop;
      }
    }
  }

  &__caption {
    transform: translateY(-3.6rem);
    color: $color-white;
    display: flex;
    flex-flow: column;
    position: absolute;
    transition: 1s ease-in-out;
    width: 100%;

    @include breakpoint(tablet) {
      color: $color-comet;
      display: inherit;
      padding: 1rem 1.5rem .5rem;
      position: inherit;
      transform: none;
    }
    box-sizing: border-box;
    font-size: .9rem;
    line-height: 1.1rem;
    font-family: $typeface-font;
    letter-spacing: 0.09px;

    .up-down {
      margin-left: .5rem;
      transition: 1s ease-in-out;
      transform: rotate(180deg);

      @include breakpoint(tablet) {
        display: none;
      }
    }

    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: -moz-linear-gradient(top,rgba(0,0,0,0) 0,hsla(0,0%,50.2%,.9) 100%);
      background: -webkit-linear-gradient(top,rgba(0,0,0,0),hsla(0,0%,50.2%,.9));
      background: -webkit-gradient(linear,left top, left bottom,color-stop(0, rgba(0,0,0,0)),to(hsla(0,0%,50.2%,.9)));
      background: -o-linear-gradient(top,rgba(0,0,0,0) 0,hsla(0,0%,50.2%,.9));
      background: linear-gradient(180deg,rgba(0,0,0,0) 0,hsla(0,0%,50.2%,.9));
      z-index: 2;
      @include breakpoint(tablet) {
        display: none;
      }
    }
  }

  &__author {
    color: $color-white;
    display: block;
    order: 1;
    right: 0;
    padding: 2rem 1.5rem .5rem;
    text-align: left;
    text-transform: uppercase;
    z-index: 5;

    @include breakpoint(tablet) {
      display: inline;
      padding: 0 0 0 .3rem;
      font-size: 1.4rem;
      line-height: 1.7rem;
      letter-spacing: 0.28px;
      color: $color-comet;
      text-align: left;
      background: transparent;
    }
  }

  &__text {
    display: block;
    font-size: 1.4rem;
    line-height: 1.9rem;
    letter-spacing: .28px;
    order: 2;
    padding: 0 1.5rem .5rem 1.5rem;
    z-index: 5;

    @include breakpoint(tablet) {
      background-color: inherit;
      display: inline;
      padding-left: 0;
      padding-right: .3rem;
      position: static;
      width: auto;
    }
  }

  &__max-height img {
    max-height: 700px;

    @include breakpoint(tablet) {
      width: auto;
    }
  }

  .caption-toggle:checked ~ label &__wrapper &__caption {
    transform: translateY(-100%);

    .up-down {
      transform: rotate(0deg);
    }

    @include breakpoint(tablet) {
      transform: none;
    }
  }
}

.caption-toggle {
  display: none;

  &--label {
    @include breakpoint(tablet) {
      order: 2;
    }
  }
}

article figure:not(.banner) {
  margin: 0 -1.5rem;

  @include breakpoint(tablet) {
    margin: 0 auto;
    max-width: $content-width;
  }
}

article .lightbox.gallery figure {
  max-width: 100vw;

  @include breakpoint(tablet) {
    max-width: unset;
  }
}

.author-isolated .picture__type img {
  height: 100%;
}