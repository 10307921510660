.text-color {

  &__nero-50 {
    color: $color-nero-50;
  }

  &__nero-20 {
    color: $color-nero-20;
  }

  &__nero-100 {
    color: $color-nero;
  }

  &__prussian-blue-100 {
    color: $color-prussian-blue;
  }

  &__prussian-blue-80 {
    color: $color-prussian-blue-80;
  }

  &__prussian-blue-60 {
    color: $color-prussian-blue-60;
  }

  &__prussian-blue-40 {
    color: $color-prussian-blue-40;
  }

  &__prussian-blue-20 {
    color: $color-prussian-blue-20;
  }

  &__pacific-blue-100 {
    color: $color-pacific-blue;
  }

  &__pacific-blue-80 {
    color: $color-pacific-blue-80;
  }

  &__pacific-blue-60 {
    color: $color-pacific-blue-60;
  }

  &__pacific-blue-40 {
    color: $color-pacific-blue-40;
  }

  &__pacific-blue-20 {
    color: $color-pacific-blue-20;
  }

  &__comet {
    color: $color-comet;
  }

  &__light-grey {
    color: $color-dim-gray;
  }

  &__white {
    color: $color-white;
  }

  &__endeavour-100 {
    color: $color-endeavour;
  }

  &__nobel {
    color: $color-nobel;
  }

  &__green {
    color: $color-green;
  }

  &__dark-green {
    color: $color-dark-green;
  }

  &__danger {
    color: $color-danger;
  }

  &__silver {
    color: $color-silver;
  }

  &__gainsboro {
    color: $color-gainsboro;
  }

  &__dim-gray {
    color: $color-dim-gray;
  }
}