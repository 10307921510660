.epaper-slider-box {
  .box-name {
    font-size: 1.8rem;
    line-height: 1.8rem;
    padding: 1.8rem 0 1.9rem 1rem;

    @include breakpoint(desktop) {
      font-size: 3.4rem;
      line-height: 3.6rem;
      margin-bottom: .4rem;
      padding: 1.7rem 1rem;
    }
  }

  .slider {
    padding: 2rem 1.1rem .1rem 0;

    &__elements {
      margin-left: 0;
    }

    &__element {
      border: 1px solid $color-very-light-grey;
      margin-right: .5rem;
      width: 14.6rem;

      @include breakpoint(tablet) {
        width: 25.6rem;
      }
    }

    &.min-slider-3-p,
    &.min-slider-4-p {
      .slider__element {
        //width: 13.6rem;

        @include breakpoint(tablet) {
          //width: 25.6rem;
        }
      }
    }

    .next-bubble {
      @include breakpoint(desktop) {
        right: 1.65rem;
      }
    }

    @include breakpoint(desktop) {
      &:before,
      &:after {
        content: "";
        display: none;
        height: 100%;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 30%;
        @include gradient-white-transparent-r_l();
      }

      &:before {
        left: 0;
        transform: scaleX(-1);
        z-index: 1;
      }

      &.has-next {
        &:after {
          display: block !important;
          right: 0;
        }
      }

      &.has-prev {
        &:before {
          display: block !important;
          left: 0;
        }
      }
    }

    &-box__more {
      display: none;
    }

    &.min-slider {
      .slider__elements {
        justify-content: center;
      }
    }

    .three-dots {
      visibility: hidden;
    }

    //@include breakpoint(tablet) {
    //  &.min-slider-2-p,
    //  &.min-slider-3-p,
    //  &.min-slider-4-p {
    //    .three-dots {
    //      visibility: visible;
    //    }
    //  }
    //}

    //@include breakpoint(tablet) {
    //  &.min-slider-4-p {
    //    .three-dots {
    //      visibility: visible;
    //    }
    //  }
    //}
  }
}

@include breakpoint(tablet) {
  .grid-cell--t-6 {
    .epaper-slider-box {
      .slider {
        &.min-slider-2-p,
        &.min-slider-3-p,
        &.min-slider-4-p {
          .three-dots {
            visibility: visible;
          }
        }
      }
    }
  }

  .grid-cell--t-12 {
    .epaper-slider-box {
      .slider {
        &.min-slider-4-p {
          .three-dots {
            visibility: visible;
          }
        }
      }
    }
  }
}

@include breakpoint(desktop) {
  .grid-cell--d-6 {
    .epaper-slider-box {
      .slider {
        &.min-slider-2-p,
        &.min-slider-3-p,
        &.min-slider-4-p {
          .three-dots {
            visibility: visible;
          }
        }
      }
    }
  }

  .grid-cell--d-12 {
    .epaper-slider-box {
      .slider {
        &.min-slider-4-p {
          .three-dots {
            visibility: visible;
          }
        }
      }
    }
  }
}