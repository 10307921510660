.button-reload,
.button-reload-author {
  margin: 1.5rem auto 3rem auto;

  @include breakpoint(tablet) {
    margin-top: 0;

    &.button--wide {
      width: auto;
    }
  }

  &[disabled="disabled"],
  &[disabled] {
    background-color: $color-link-water;
  }

  .loader--ellipsis-item {
    background-color: $color-pacific-blue;
  }
}