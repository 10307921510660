.article--type {
  &-podcast {
    .interferer {
      &__icon {
        height: 1.8rem;
        left: 1rem;
        position: absolute;
        top: .6rem;
        width: 1.2rem;
      }

      &__text {
        font-weight: 600;
        margin-left: 2.1rem;
        padding-left: .6rem;
      }
    }

    &.article__teaser--small:not(.article__teaser--no-image) {
      .article__teaser__image {
        .interferer {
          display: inline-block;

          &__icon {
            height: 1.2rem;
            left: .5rem;
            top: .3rem;
            width: 1rem;
          }
        }
      }
    }

    &.article__teaser--no-image {
      .interferer-reduced .interferer__icon {
        left: calc((100% - 1.2rem) / 2);
        top: calc((3rem - 1.839rem) / 2);
      }

      .article__teaser__headline {
        width: calc(100% - 4rem);
      }
    }

    @include breakpoint(tablet) {
      .grid-cell--t-12 & {
        .interferer {
          bottom: 1rem;
          right: 1rem;
        }
      }
    }

    @include breakpoint(desktop) {
      .grid-cell--d-4 &,
      .grid-cell--d-8 & {
        .interferer {
          bottom: 1rem;
          right: 1rem;
        }
      }

      .grid-cell--d-4 & {
        .article__teaser__subline {
          margin: 0;
        }
      }

      .grid-cell--d-6 & {
        .interferer {
          bottom: 1.5rem;
          right: 1.5rem;
        }

        .article__teaser__separator {
          padding-top: 2rem;
        }
      }
    }

    @include breakpoint(tablet) {
      .grid-cell--t-6 &,
      .grid-cell--d-6 & {
        .article__teaser__info {
          padding-bottom: 0;
        }
      }

      &.article__teaser--small,
      &.article__teaser--no-image {
        .interferer {
          bottom: .5rem;
          right: .5rem;

          &__icon {
            height: 1.4rem;
            left: .4rem;
            width: 1.2rem;
            top: .4rem;
          }
        }
      }

      &.article__teaser--no-image {
        .interferer__icon {
          height: 1.8rem;
          width: 1.2rem;
        }
      }
    }

    @include breakpoint(desktop) {
      // 1.5 Rows
      .grid-cell--d-6 & {
        &.article__teaser--small {
          &.article__teaser--small {
            .article__teaser__image {
              .interferer {
                bottom: 1.5rem;
                height: 3rem;
                right: 1.5rem;
                min-width: 7.3rem;

                &__icon {
                  left: .8rem;
                  position: absolute;
                  top: .6rem;
                }

                &__text {
                  margin-left: 3.1rem;
                }
              }
            }
          }
        }
      }
    }

    @include breakpoint(desktop) {
      .grid-cell--t-6 &,
      .grid-cell--t-12 &,
      .grid-cell--d-4 &,
      .grid-cell--d-6 &,
      .grid-cell--d-8 & {
        .article__teaser__image {
          .interferer {
            display: inline-block;
            padding-right: 1rem;
          }
        }
      }
    }

    .grid-cell--d-4 &.article__teaser--small {
      .article__teaser__image {
        .interferer {
          width: 2rem !important;
          min-width: 2rem !important;
        }
      }
    }
  }
}