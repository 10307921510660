.league-schedule-box {

  .encounter__details {
    border-top: unset;
  }

  .encounter__start-date {
    color: $color-comet;
    display: none;

    @include breakpoint(tablet) {
      display: block;
    }
  }

  .encounter__start-time {
    color: $color-comet;
  }

  &__encounter-date {
    line-height: 1.6rem;
    margin: 3.4rem 0 1rem;

    @include breakpoint(tablet) {
      font-size: 1.8rem;
      line-height: 1.6rem;
    }
  }

  &__header {
    line-height: 2.6rem;
    padding: 1.9rem 1rem 1.8rem 1rem;
    border-width: .3rem;
    width: 100%;

    @include breakpoint(tablet) {
      flex-grow: 1;
      width: auto;
    }

    &--season {
      display: none;

      @include breakpoint(tablet) {
        display: inline;
      }
    }

    &__wrapper {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__select {
    border-width: .3rem;
    padding: .9rem 1rem .9rem 0;
    width: 100%;

    .dropdown {
      margin-left: auto;
      width: 14.5rem;
    }

    @include breakpoint(tablet) {
      margin-right: 1rem;
      width: 14.5rem;
    }
  }
}