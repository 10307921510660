.hide,
.hidden {
  display: none;

  &__mobile {
    display: none;
  }

  &__tablet {
    @include breakpoint(tablet) {
      display: none;
    }
  }

  &__desktop {
    @include breakpoint(desktop) {
      display: none;
    }
  }
}

.show {

  &__mobile {
    display: inherit;
  }

  &__tablet {
    @include breakpoint(tablet) {
      display: inherit;
    }
  }

  &__desktop {
    display: none;

    @include breakpoint(desktop) {
      display: inherit;
    }
  }
}