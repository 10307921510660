.content-element {
  iframe.instagram-media {
    max-width: 100% !important;

    @include breakpoint(maxTablet) {
      max-width: 100vw!important;
      margin-left: -1.5rem !important;
      margin-right: -1.5rem !important;
      width: 100vw !important;
    }
  }
}