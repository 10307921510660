
// SVG
.fill-color {

  &__nero {
    fill: $color-nero;
  }

  &__black {
    fill: $color-black;
  }

  &__silver {
    fill: $color-silver;
  }

  &__comet {
    fill: $color-comet;
  }

  &__white {
    fill: $color-white;
  }

  &__fb-blue {
    fill: $color-fb-blue;
  }

  &__twitter-blue {
    fill: $color-twitter-blue;
  }

  &__pacific-blue {
    fill: $color-pacific-blue;
  }

  &__whatsapp-green {
    fill: $color-whatsapp-green;
  }
}

.stroke-color {

  &__silver {
    stroke: $color-silver;
  }
}