.ai-summery {
  font-size: 1.8rem;
  line-height: 2.4rem;
  padding: 1.5rem 1.5rem 8rem 1.5rem;
  margin: 0 -1.5rem 2.5rem -1.5rem !important;
  width: calc(100% + 3rem) !important;

  @include breakpoint(tablet) {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  @include breakpoint(desktop) {
    margin: 0 auto 2.5rem !important;
    width: auto !important;
  }

  li {
    margin-bottom: 1.5rem;
    margin-left: 1rem;
  }

  &__info-text {
    font-size: 1.6rem;
    line-height: 2.1rem;
  }

  &__headline {
    font-size: 2rem;

    @include breakpoint(tablet) {
      font-size: 1.6rem;
    }
  }
}

.foldable-content {
  position: relative;
  max-height: 155px;
  overflow: hidden;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;

  .icon--arrow-bottom {
    transition: all .5s ease-in-out;
    width: 12px;
    height: 12px;
  }

  &__read {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }

  &--overlay {
    position: absolute;
    content: "";
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    bottom: 0;
    height: 60px;
    font-size: 1.8rem;
    font-weight: bold;
    color: $color-comet;
    cursor: pointer;

    &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 20px;
      top: -20px;
      left: 0;
      background: linear-gradient(to bottom,  rgba(255,255,255,0) 1%,rgba(229,243,250,0.54) 39%,rgba(229,243,250,1) 71%);
    }
  }

  &--open {
    max-height: 1000px;

    .icon--arrow-bottom {
      rotate: 180deg;
    }
  }
}