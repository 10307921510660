.table-entry {
  font-size: 1.2rem;
  height: 4.6rem;
  line-height: 2.5rem;
  margin: 0 1rem;

  @include breakpoint(tablet) {
    font-size: 1.6rem;
    height: 6.9rem;
    line-height: 1.8rem;
  }

  &__position {
    font-size: 1.4rem;
    text-align: left;

    @include breakpoint(tablet) {
      font-size: 2.8rem;
      line-height: 1.8rem;
      width: 9rem;
    }
  }

  &__logo {
    @include breakpoint(tablet) {
      width: 4.5rem;
    }

    img {
      max-height: 2.5rem;
      max-width: 2.5rem;

      @include breakpoint(tablet) {
        margin-right: .8rem;
        max-height: 4.5rem;
        max-width: 4.5rem;
      }
    }
  }

  &__name {
    @include breakpoint(tablet) {
      font-weight: 600;
    }
  }

  &__encounters,
  &__goals,
  &__points {
    @include breakpoint(tablet) {
      text-align: center
    }
  }
}