@-webkit-keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0.5;
  }
}

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0.5;
  }
}

.flash {
  -webkit-animation: flash 1s;
  animation: flash 1s;
  //-webkit-animation-duration: 1s;
  //animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


@-moz-keyframes pulseBefore {
  0% { -moz-transform: scale(0.6); opacity: 1; }
  33% { -moz-transform: scale(1.5); opacity: 0; }
  65.99999% { -moz-transform: scale(1.5); opacity: 0; }
  66% { -moz-transform: scale(0.6); opacity: 1; }
  100% { -moz-transform: scale(1.5); opacity: 0; }
}@-webkit-keyframes pulseBefore {
   0% { -webkit-transform: scale(0.6); opacity: 1; }
   33% { -webkit-transform: scale(1.5); opacity: 0; }
   65.99999% { -webkit-transform: scale(1.5); opacity: 0; }
   66% { -webkit-transform: scale(0.6); opacity: 1; }
   100% { -webkit-transform: scale(1.5); opacity: 0; }
 }
@keyframes pulseBefore {
  0% { transform: scale(0.6); opacity: 1; }
  33% { transform: scale(1.5); opacity: 0; }
  65.99999% { transform: scale(1.5); opacity: 0; }
  66% { transform: scale(0.6); opacity: 1; }
  100% { transform: scale(1.5); opacity: 0; }
}
@-moz-keyframes pulseAfter {
  0% { -moz-transform: scale(0.7); }
  50% { -moz-transform: scale(0.9); }
  100% { -moz-transform: scale(0.7); }
}@-webkit-keyframes pulseAfter {
   0% { -webkit-transform: scale(0.7); }
   50% { -webkit-transform: scale(0.9); }
   100% { -webkit-transform: scale(0.7); }
 }
@keyframes pulseAfter {
  0% { transform: scale(0.7); }
  50% { transform: scale(0.9); }
  100% { transform: scale(0.7); }
}