.divider {
  &--content-group {
    border: none;
    height: .5rem;
    margin-top: .2rem;
    margin-bottom: 0;
    background-color: $color-white-smoke-2;
  }
}

