.device-limit-modal {
  width: calc(100% - 2rem);
  margin: 0 auto;
  padding-bottom: 3.3rem;
  padding-top: 1.7rem;

  @include breakpoint(desktop) {
    padding-top: 13.9rem;
    max-width: 68rem;
    text-align: center;
  }

  &__close-button {
    cursor: pointer;
    line-height: 2rem;
    margin-bottom: 1.5rem;
    position: relative;
    right: 30px;
    text-align: right;

    @include breakpoint(desktop) {
      margin-bottom: 1.3rem;
    }

    &:before,
    &:after {
      position: absolute;
      right: -2rem;
      content: " ";
      height: 2rem;
      width: .2rem;
      background-color: #FFF;
      top: -.1rem;

      @include breakpoint(desktop) {
        height: 2.2rem;
      }
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &__headline {
    font-size: 1.8rem;
    line-height: 1.8rem;

    @include breakpoint(desktop) {
      font-size: 2rem;
      line-height: 1.8rem;
    }
  }

  &__content {
    padding: 1.6rem 1rem 4.6rem 1rem;
    width: 100%;

    @include breakpoint(desktop) {
      padding: 1.6rem 5rem 3.7rem 5rem;
    }

    .icon-check {
      display: block;
      fill: $color-green;
      height: 5rem;
      margin: 3.1rem auto 3.3rem auto;
      width: 7rem;
    }
  }

  &__intro {
    margin: 1.4rem 0 1.8rem 0;

    @include breakpoint(desktop) {
      margin-bottom: 1.4rem;
      max-width: 540px;
    }
  }

  &__devices {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__device {
    margin-top: 2.1rem;
    text-align: center;
    width: 100%;

    @include breakpoint(desktop) {
      width: inherit;
    }

    &:first-of-type {
      margin-top: 1.7rem;

      @include breakpoint(desktop) {
        margin-top: 1.8rem;
      }
    }

    &:nth-of-type(2) {
      margin-top: 1.8rem;
    }

    &__remove-btn {
      display: block;
      margin: 1rem auto .2rem auto;
      width: 100%;

      @include breakpoint(desktop) {
        width: 28rem;
      }
    }
  }

  &__removed-text {
    @include breakpoint(desktop) {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }

  &__info {
    &__headline {
      margin: 1.8rem 0 1.5rem 0;

      @include breakpoint(desktop) {
        margin-top: 3rem;
      }
    }

    &__text {
      @include breakpoint(desktop) {
        font-size: 1.4rem;
        line-height: 1.8rem;
        max-width: 54rem;
      }
    }

    &__btn {
      display: block;
      font-size: 1.6rem;
      line-height: 1.8rem;
      margin: 1.5rem auto 0 auto;
      padding: 1.3rem 1.9rem;
      width: 28rem;
    }
  }
}