input,
input[type="text"],
select,
textarea {
  background-color: $color-white-smoke-1;
  border-color: transparent;
  border-radius: 5px;
  font-family: $typeface-font;
  padding: 1.2rem 3.6rem 1.2rem 1rem;

  &.input--border {
    border-color: #CCD7E1;
    border-style: solid;
    border-width: 1px;
  }

  &.full-width {
    width: 100%;
  };
}

input::-webkit-input-placeholder {
  font-style: italic;
  color: $color-nero-50;
}
input::-moz-placeholder {
  font-style: italic;
  color: $color-nero-50;
}
input:-ms-input-placeholder {
  font-style: italic;
  color: $color-nero-50;
}
input::placeholder {
  font-style: italic;
  color: $color-nero-50;
}

.input {
  position: relative;
  width: auto;
  font-family: $typeface-font;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 400;

  &--svg {
    height: 1.9rem;
    width: 1.9rem;
    position: absolute;
    top: 1.2rem;
    right: 1.4rem;
  }
}