.supplements-slider-box {

  .box-name {
    font-size: 1.8rem;
    line-height: 2.3rem;
    padding: 1.7rem 1.4rem 1.5rem 1.4rem;

    @include breakpoint(tablet) {
      font-size: 3.4rem;
      line-height: 3.6rem;
      padding: 1.7rem 1.1rem;
    }
  }

  .slider {
    &__elements {
      padding-top: 1.2rem;
    }

    &__element {
      height: 24.2rem;
      width: 17.1rem;
      margin-right: 1rem;

      @include breakpoint(tablet) {
        height: 26.4rem;
        width: 18.7rem;
      }
    }
  }

  .slider-box__more {
    background-color: $color-white;
    color: $color-comet;
  }
}