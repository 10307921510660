.article--type {
  &-gallery {
    .interferer {
      font-weight: 600;

      &__icon {
        height: 1.607rem;
        left: .6rem;
        position: absolute;
        top: .6rem;
        width: 1.837rem;
      }

      &__text {
        margin-left: 2.8rem;
      }
    }

    &.article__teaser--no-image {
      .interferer-small .interferer__icon {
        left: calc((100% - 1.864rem) / 2);
        top: calc((3rem - 1.606rem) / 2);
      }
    }

    &.article__teaser--small {
      .article__teaser__image {
        .interferer {
          &__icon {
            height: 1rem;
            left: .4rem;
            position: absolute;
            top: .4rem;
            width: 1.2rem;
          }
        }
      }
    }

    &:not(.article__teaser--no-image):not(.article__teaser--small) {
      .article__teaser__headline {
        @include breakpoint(maxTablet) {
          line-height: 2.4rem;
        }
      }
    }

    &.article__teaser--no-image {
      .article__teaser__headline {
        width: calc(100% - 4rem);
      }
    }

    &.article__teaser--small:not(.article__teaser--no-image) {
      .article__teaser__image {
        .interferer {
          display: inline-block;
          min-width: 2rem;
          width: 2rem;
        }
      }
    }

    .grid-cell--d-4 &:not(.article__teaser--no-image):not(.article__teaser--small),
    .grid-cell--t-6 &:not(.article__teaser--no-image):not(.article__teaser--small) {
      .interferer {
        padding: 0 1rem;

        &__icon {
          left: 1rem;
        }
      }
    }
  }
}