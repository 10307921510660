.tooltip {
  width: 150px;
  padding: 1rem;
  margin-top: 2rem;
  border-radius: 5px;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.8rem;
  letter-spacing: .12px;
  color: $color-white;
  background-color: $color-deep-sky-blue;

  &--agree {
    letter-spacing: .96px;
    cursor: pointer;
  }

  &--text {
    margin-bottom: .7rem;
  }

  &--bottom {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: -5px;
      left: 66.6666%;
      transform: translateX(-5px);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent $color-deep-sky-blue transparent;
    }
  }
}